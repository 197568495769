<script>
  import clsx from "clsx";

  import { Tooltip } from "components";

  import styles from "styleguide/Avatar.json";

  // size options
  const SIZES = {
    normal: "normal",
    small: "small",
  };

  export let name = "";
  export let avatar = "";
  export let size = SIZES.normal;
  export let isHighlighted = false;
  export let isActive = false;
  export let isTooltip = true;

  $: initials = getInitials(name);

  function getInitials(name) {
    if (!name) return "";

    const words = name.split(" ");
    const firstLetter = words[0] ? words[0][0].toUpperCase() : "";
    const secondLetter = words[1] ? words[1][0].toUpperCase() : "";

    return firstLetter + secondLetter;
  }
</script>

<div data-component="Avatar" class={styles.wrapper}>
  <div
    class={clsx(
      styles.avatar,
      styles[size],
      { [styles["active"]]: isActive },
      { [styles["highlighted"]]: isHighlighted },
    )}
    style={avatar ? `background-image: url(${avatar})` : "none"}
  >
    {#if !avatar}
      <div class={styles.initials}>{initials}</div>
    {/if}
  </div>

  {#if isTooltip}
    <div class={styles.tooltip}>
      <Tooltip showArrow={true}>
        <div slot="content" class={styles[size]}></div>

        <svelte:fragment slot="tooltip">
          <slot>
            <span class={styles.name}>{name}</span>
          </slot>
        </svelte:fragment>
      </Tooltip>
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    position: relative;
    width: fit-content;

    // SIZE--start
    .normal {
      width: 40px;
      height: 40px;

      .initials {
        @include typography-body-medium;
      }
    }

    .small {
      width: 32px;
      height: 32px;

      .initials {
        @include typography-footnote-medium;
      }
    }
    // SIZE--end
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 2px var(--white);
    background-color: var(--primary-025);
    overflow: hidden;
    object-fit: fill;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.highlighted {
      background-color: var(--primary-025);
    }

    &.active {
      border-color: var(--brand-100);
    }
  }

  .name {
    white-space: nowrap;
  }

  .tooltip {
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
