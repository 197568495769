<script>
  import { isAccountFeature } from "stores/account";
  import { t } from "stores/i18n.js";
  import { audit, auditComments, allowedFileTypes } from "stores/pbc.js";

  import CommentsApi from "apis/comments/comments.js";
  import AuditApi from "apis/pbc/audit.js";

  import { Boundary, Comment, TextEditor } from "components";
  import styles from "styleguide/AuditSidebarComments.json";

  function onPostComment(content, attachments, mentions) {
    if (content) {
      CommentsApi.create({
        params: {
          parent_type: "audit",
          parent_id: $audit.permalink,
          content,
          attachments,
          mentions,
        },
        success: getComments,
      });
    }
  }

  function getComments() {
    AuditApi.getComments();
  }
</script>

<Boundary>
  <div data-component="AuditSidebarComments" class={styles.wrapper}>
    <div class={styles.block}>
      <div class={styles.note}>
        <span>{$t("audit_sidebar_comments.note")}</span>
        {$t("audit_sidebar_comments.comments_visible_no")}
      </div>
    </div>
    <div class={styles.block}>
      <TextEditor
        allowMentions={$isAccountFeature("mentions")}
        sidebar={true}
        under={true}
        border={false}
        style="margin toolbar-full toolbar-top"
        parent_type="audit"
        parent_id={$audit.permalink}
        {onPostComment}
        allowedFileTypes={$allowedFileTypes}
      />
    </div>
    <div class={styles.comments}>
      {#each $auditComments as comment}
        {#if !comment.is_deleted}
          <div class={styles.item}>
            <Comment
              {...comment}
              sidebar={true}
              picture={false}
              parent_type="audit"
              parent_id={$audit.permalink}
              onDeleteSuccess={getComments}
            />
          </div>
        {/if}
      {:else}
        <div class={styles.noyet}>
          {$t("audit_sidebar_comments.no_comments")}
        </div>
      {/each}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .comments {
    height: 380px;
    overflow-y: auto;
  }

  .noyet {
    margin-top: 30px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: var(--primary-400);
  }

  .item {
    padding: 0 20px;
    border-bottom: solid 1px var(--primary-050);

    &:last-child {
      border-bottom: none;
    }
  }

  .block {
    padding: 4px 10px;
    border-bottom: solid 1px var(--primary-050);
  }

  .note {
    margin: 10px 8px;

    span {
      font-weight: 600;
      color: var(--orange);
    }
  }
</style>
