<script>
  import Button from "components/shared/Button/Button.svelte";
  import { t } from "stores/i18n.js";
  import { successResponse } from "stores/responder.js";
  import { iconPath, dateTimeLogFormat } from "lib/helpers.js";
  import styles from "styleguide/RespondSuccess.json";

  const printPage = () => {
    window.print();
  };
</script>

<div data-component="RespondSuccess">
  <div class={styles.block}>
    <!-- Checkmark and Icon -->
    <div class={styles.checkmark}>
      <img
        src={iconPath("checkmark_big_green")}
        class={styles.logo}
        alt="Auditi"
      />
    </div>
    <div>
      <h2 class={styles.title}>{$t("responder_success.title")}</h2>
      <p class={styles.subtitle}>{$t("responder_success.subtitle")}</p>
    </div>
    {#if $successResponse.record}
      <div class={styles.details}>
        <div>
          <span>{$t("responder_success.reference")}</span>
          <p>
            {$successResponse.record}
          </p>
        </div>
        <div>
          <span>{$t("responder_success.created_at")}</span>
          <p>
            {dateTimeLogFormat($successResponse.created_at)}
          </p>
        </div>
        <div>
          <span>
            {$t("responder_success.files", {
              postProcess: "interval",
              count: $successResponse.submitted_files.length,
            })}
          </span>
          <p>
            {#each $successResponse.submitted_files as file}
              {file.filename}<br />
            {/each}
          </p>
        </div>
        <div>
          <span>
            <Button click={printPage}>
              {$t("responder_success.print")}
            </Button>
          </span>
        </div>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .block {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0 20px;
    text-align: center;
  }

  .title {
    max-width: 330px;
    margin: 0 auto 16px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
    color: var(--primary-500);
  }

  .subtitle {
    margin: 0;
    line-height: 1.6;
    color: var(--primary-500);
  }

  .details {
    display: flex;
    padding: 32px 0px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    flex-direction: column;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    border-top: 1px solid var(--primary-050);
    margin-top: 40px;
    div {
      display: flex;
      gap: 30px;
    }
    span {
      width: 120px;
      color: var(--primary-300);
      flex-shrink: 0;
    }
    p {
      color: var(--primary-500);
      margin: 0;
    }
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    margin-bottom: 24px;
    background: var(--green-010);

    img {
      width: 24px;
      height: 24px;
    }
  }
</style>
