<script>
  import { t } from "stores/i18n.js";

  import { sanitizeRelaxed } from "lib/sanitize.js";

  import styles from "styleguide/InputWrapper.json";

  export let label;
  export let rightLabel;
  export let optional;
  export let errors;
  export let footnotes;
  export let reset;
</script>

<div data-component="InputWrapper" class={styles.wrapper}>
  <div class={styles.wrapper}>
    <div class={styles.top}>
      {#if label}
        <span class={styles.label}>
          <span class={styles["label-text"]}>
            {@html sanitizeRelaxed(label)}
          </span>
          {#if optional}
            <span class={styles.optional}>({$t("text_input.optional")})</span>
          {/if}
        </span>
      {/if}

      {#if rightLabel}
        <span class={styles["right-label"]}>
          {@html sanitizeRelaxed(rightLabel)}
        </span>
      {/if}

      {#if reset}
        <div class={styles.reset} on:click={reset}>
          {$t("text_input.reset")}
        </div>
      {/if}
    </div>

    <slot />
  </div>

  {#if errors?.length}
    <div class={styles.errors}>
      {#each errors as error}
        <div class={styles.error}>{error}</div>
      {/each}
    </div>
  {/if}

  {#if footnotes}
    <div class={styles.footnotes}>
      {#each footnotes as footnote}
        <div class={styles.footnote}>{@html footnote}</div>
      {/each}
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .label {
    @include typography-body-medium;

    display: inline-block;
    margin: 0 5px 5px 0;
    color: var(--primary-500);
  }

  .label-text {
    .bold & {
      @include typography-body-semibold;
    }
  }

  .error {
    color: var(--red-200);
  }

  .optional {
    @include typography-body-regular;

    color: var(--primary-300);
  }

  .footnote {
    color: var(--primary-300);
  }

  .error,
  .footnote {
    @include typography-footnote-regular;
  }

  .errors,
  .footnotes {
    margin-top: 6px;
  }

  .reset {
    display: inline-block;
    color: var(--blue-200);
    cursor: pointer;
  }

  .right-label {
    @include typography-footnote-regular;

    color: var(--primary-300);
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
</style>
