<script>
  import { onMount } from "svelte";

  import { t } from "stores/i18n.js";

  import AccountApi from "apis/admin/accounts.js";
  import { isDateAfterDate } from "lib/helpers.js";

  import { Tag } from "components";
  import { compliancePackage } from "../stores";
  import styles from "styleguide/EmailSignature.json";

  let formattedDate = "";
  $: if ($compliancePackage.certificate_valid_until) {
    let dateParts = $compliancePackage.certificate_valid_until.split("-");
    formattedDate = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
  }

  $: certificateIsValid = isDateAfterDate(
    $compliancePackage.certificate_valid_until,
    new Date(),
  );

  onMount(async () => {
    getAccountPasswordRules();
  });

  function getAccountPasswordRules() {
    AccountApi.getCustomPasswordRules();
  }
</script>

<div data-component="EmailSignature">
  <div class={styles["section"]}>
    <div class={styles["section-header"]}>
      {$t("email_signature.header")}
      <div class={styles["section-header-certificate"]}>
        {#if $compliancePackage.certificate_valid_until == null}
          <Tag
            label={$t("email_signature.inactive")}
            color="grey"
            size="small"
          />
        {:else if certificateIsValid}
          <Tag
            label={$t("email_signature.active")}
            color="green"
            size="small"
          />
          {$t("email_signature.certificate_valid")}
          {formattedDate}
        {:else}
          <Tag label={$t("email_signature.expired")} color="red" size="small" />
          {$t("email_signature.certificate_expired")}
          {formattedDate}
        {/if}
      </div>
    </div>
    <div class={styles["section-description"]}>
      {$t("email_signature.description")}
    </div>

    {@html $t("email_signature.mail")}
  </div>
</div>

<style scoped lang="scss">
  .section {
    padding-bottom: 24px;
    &-header {
      display: flex;
      gap: 8px;
      align-items: center;
      padding-top: 24px;

      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: var(--primary-500, #052861);

      &-certificate {
        color: var(--brand-300, #58749b);
        font-size: 12px;
        line-height: 18px;
      }
    }

    &-description {
      padding-top: 8px;
      padding-bottom: 24px;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-300, #052861);
    }
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 0;

    &-title {
      display: flex;
      align-items: center;

      color: var(--primary-500, #052861);

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }

    &-adjuster {
      display: flex;
      align-items: center;
      max-width: 172px;
      gap: 4px;
    }
  }
</style>
