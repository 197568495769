<script>
  import { onMount } from "svelte";
  import { NotificationList } from "components/notification";
  import { t } from "stores/i18n.js";

  import { showWidget } from "stores/notification.js";
  import { user, isUserTeamUser } from "stores/user.js";

  import NotificationApi from "apis/notification/notification.js";
  import TeamUsersApi from "apis/users/team_users.js";

  import { Box, Button, Options } from "components";
  import styles from "styleguide/NotificationWidget.json";

  $: toggleStatus = $user.notificationsToggle ? "deactivate" : "on";

  export function hide() {
    showWidget.set(false);
  }

  onMount(async () => {
    NotificationApi.getData();
  });

  function clearAll() {
    NotificationApi.clearAll();
  }

  function notificationsToggle() {
    let formData = new FormData();

    formData.append("notifications_toggle", !$user.notificationsToggle);

    TeamUsersApi.settings({
      body: formData,
      success: () => {
        let newUser = {
          ...$user,
          notificationsToggle: !$user.notificationsToggle,
        };
        user.set(newUser);
      },
    });
  }
</script>

<div data-component="NotificationWidget">
  <div class={styles.widget}>
    <Box>
      <div class={styles.header} slot="header">
        <span>{$t("notification_widget.title")}</span>
        <Options
          style={"medium no-border acts-as-button left"}
          title={$t("notification_widget.settings")}
        >
          <Button style={"blue-text option"} click={clearAll}
            >{$t("notification_widget.clear_all")}</Button
          >
          {#if $isUserTeamUser}
            <Button style={"blue-text option"} click={notificationsToggle}>
              {$t(`notification_widget.${toggleStatus}`)}
            </Button>
          {/if}
        </Options>
      </div>

      <NotificationList />
    </Box>
  </div>
</div>

<style lang="scss">
  .widget {
    width: 440px;
    height: 550px;
    position: absolute;
    right: 3rem;
    text-align: left;
    z-index: 5;
    box-shadow:
      0px 6px 12px -6px rgba(24, 39, 75, 0.12),
      0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    border-bottom: 1px solid var(--primary-050);
    font-weight: 600;
    padding: 10px 20px;
  }
</style>
