<script>
  import { ListFilterNameOptions } from "apps/quickshare/constants";
  import { t } from "stores/i18n";

  import { Boundary, Button, Popover, RadioGroup } from "components";

  import {
    getFilterTypes,
    getFilterPeriods,
    getFilterDownloadStatuses,
  } from "../../helpers";

  import styles from "styleguide/QuickshareFilterDropdown.json";

  export let filters;
  export let onEnter;

  $: isFilterActive = false;
  $: types = getFilterTypes(filters.link_type);
  $: periods = getFilterPeriods(filters.time_period);
  $: downloads = getFilterDownloadStatuses(filters.download_status);
  $: isOpen = false;

  function handleApplyFilters() {
    isFilterActive = Object.values(filters).some((value) => value !== null);
    isOpen = false;
    onEnter();
  }

  function handleResetFilters() {
    isFilterActive = false;
    isOpen = false;

    filters = {
      name: filters.name,
      link_type: null,
      time_period: null,
      download_status: null,
    };

    onEnter({ link_type: null, time_period: null, download_status: null });
  }

  function handleChangeRadio(value, key) {
    filters = {
      ...(filters || {}),
      [key]: value,
    };
  }
</script>

<Boundary>
  <div data-component="QuickshareFilterDropdown" class={styles.wrapper}>
    <Popover
      placement="bottom-end"
      showCloseButton={false}
      showArrow={false}
      isMaxWidth={true}
      offsetPosition={[0, 16]}
      preventOverflowPadding={40}
      isFlip={false}
      style={"no-padding"}
      bind:open={isOpen}
    >
      <Button
        style={`${isFilterActive ? "error-text small" : "primary-text small"}`}
      >
        {$t("quickshare_filter_dropdown.filter")}
      </Button>

      <div slot="content" class={styles["content-wrapper"]}>
        <div class={styles.content}>
          <div class={styles.column}>
            <div class={styles.name}>
              {$t("quickshare_filter_dropdown.transfer_type")}
            </div>

            <RadioGroup
              items={types}
              toggle={(value) =>
                handleChangeRadio(value, ListFilterNameOptions.LINK_TYPE)}
            />
          </div>

          <div class={styles.column}>
            <div class={styles.name}>
              {$t("quickshare_filter_dropdown.time_period")}
            </div>

            <RadioGroup
              items={periods}
              toggle={(value) =>
                handleChangeRadio(value, ListFilterNameOptions.TIME_PERIOD)}
            />
          </div>

          <div class={styles.column}>
            <div class={styles.name}>
              {$t("quickshare_filter_dropdown.download_status")}
            </div>

            <RadioGroup
              items={downloads}
              toggle={(value) =>
                handleChangeRadio(value, ListFilterNameOptions.DOWNLOAD_STATUS)}
            />
          </div>
        </div>

        <div class={styles.actions}>
          <Button style={"primary"} click={handleApplyFilters}>
            {$t("quickshare_filter_dropdown.apply_filters")}
          </Button>

          <Button style={"primary-text"} click={handleResetFilters}>
            {$t("quickshare_filter_dropdown.reset_all_filters")}
          </Button>
        </div>
      </div>
    </Popover>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    position: relative;
  }

  .content-wrapper {
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
  }

  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    min-width: 590px;
    width: max-content;
  }

  .column {
    padding: 16px;

    &:not(:first-of-type) {
      border-left: 1px solid var(--primary-050);
      padding-left: 16px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 1px solid var(--primary-050);
    padding: 12px 16px;
  }

  .name {
    @include typography-body-medium;

    white-space: nowrap;
    margin-bottom: 12px;
  }
</style>
