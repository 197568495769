import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import Rails from "rails-ujs";
import { Controller } from "stimulus";

import Helper from "../general/helper";

export default class extends Controller {
  connect() {
    this.setupUppy();
  }

  setupUppy() {
    var form = this.element,
      label = $(this.element).data("label") || "Dokumente hochladen",
      self = this;
    this.uppy = this.initializeUppy();
    this.setFileInput(form, label);
    this.setAws();
    this.uppy.on("file-added", (file) => {
      var domID = file.id.replace(/[\W_]+/g, ""); // replaces the wacky id with something css can use
      $(form).find(".upload-form--files").append(`
        <li id="${domID}">
          <span class="file--meta">0%</span>
          <span class="file--name">${Helper.escapeHtml(file.name)}</span>
        </li>
      `);
      $(form).find(".uppy-FileInput-btn").addClass("loading");
    });

    this.uppy.on("upload-progress", (file, progress) => {
      var domID = file.id.replace(/[\W_]+/g, "");
      var percent = Math.floor(
        (progress.bytesUploaded / progress.bytesTotal) * 100,
      );
      $("#" + domID)
        .find(".file--meta")
        .html(percent + "%");
    });

    this.uppy.on("upload-success", function (file, data) {
      var uploadedFileData = JSON.stringify({
        id: file.meta["key"].match(/^cache\/(.+)/)[1], // remove the Shrine storage prefix
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      });

      $(form).append(`
        <input type="hidden" name="files[]" value='${uploadedFileData}' />
      `);
    });

    this.uppy.on("complete", (result) => {
      Rails.fire(form, "submit");
    });
  }

  initializeUppy() {
    return new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 100 * 1024 * 1024, // # 100 MB
        maxNumberOfFiles: 3,
        minNumberOfFiles: 1,
      },
    });
  }

  setFileInput(form, label) {
    this.uppy.use(FileInput, {
      target: form,
      pretty: true,
      inputName: "filesInput[]",
      locale: {
        strings: {
          chooseFiles: label,
        },
      },
    });
  }

  setAws() {
    this.uppy.use(AwsS3, {
      limit: 2,
      timeout: 30 * 1000,
      endpoint: "/",
    });
  }
}
