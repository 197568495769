<script>
  import { t } from "stores/i18n";

  import { Boundary, Toast } from "components";

  import styles from "styleguide/QuickshareAdminSidebar.json";
</script>

<Boundary>
  <div data-component="QuickshareAdminSidebar">
    <Toast
      close={false}
      type={"info"}
      title={$t("quickshare_admin_sidebar.title")}
    >
      <span class={styles.text}>
        <span>{@html $t("quickshare_admin_sidebar.message")}</span>
      </span>
    </Toast>
  </div>
</Boundary>

<style lang="scss">
  .text {
    @include typography-body-regular;
  }
</style>
