import { ALLOWED_FILE_TYPES_DEFAULT } from "shared/constants";

/**
 * Checks if the given filename has an acceptable file type based on a predefined list of allowed file extensions.
 *
 * @param {string} filename - The name of the file, including the file extension.
 * @param {array} allowedFileTypes - The array of the allowed file extensions.
 * @returns {boolean} - Returns "true" if the file type is acceptable, otherwise "false".
 */
export const isFileTypeAcceptable = (
  filename,
  allowedFileTypes = ALLOWED_FILE_TYPES_DEFAULT,
) => {
  if (!filename) return false;

  const extension = filename.split(".").pop().toLowerCase();
  return allowedFileTypes.includes(extension);
};
