<script>
  import clsx from "clsx";
  import { t } from "stores/i18n.js";
  import { Tooltip } from "components";
  import styles from "styleguide/AuditTemplateFilterGroup.json";

  export let filters = [];
  export let titleKey = null;
  export let activeFilter;
  export let onFilterChange;
</script>

<div data-component="AuditTemplateFilterGroup">
  {#if titleKey}
    <Tooltip
      showArrow
      overflowingShow
      placement="bottom"
      nowrap={false}
      message={titleKey}
    >
      <div class={styles["sidebar-title"]}>
        {titleKey}
      </div>
    </Tooltip>
  {/if}

  {#each filters as filter}
    <div
      on:click={() => onFilterChange(filter)}
      class={clsx(styles["sidebar-item"], {
        [styles["active"]]: activeFilter === filter,
      })}
    >
      {$t(`audit_template.${filter}`)}
    </div>
  {/each}
</div>

<style lang="scss">
  .sidebar-title {
    @include typography-footnote-regular;
    @include overflow-protection;
    color: var(--primary-300);
  }

  .sidebar-item {
    @include typography-body-medium;
    color: var(--blue-200);
    margin: 4px -24px 0px -24px;
    padding: 4px 25px;
    position: relative;
    cursor: pointer;

    &.active {
      color: var(--primary-500);
      &:after {
        content: "";
        width: 3px;
        height: 22px;
        background: var(--primary-500);
        border-radius: 3px 0 0 3px;
        position: absolute;
        right: 0;
      }
    }
  }
</style>
