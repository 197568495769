<script>
  import { quickshareFrontendService } from "apps/quickshareFrontend/services";
  import { link } from "apps/quickshareFrontend/stores";
  import { DownloadFilesVirusModal } from "components/modals";
  import { t } from "stores/i18n";

  import { Button, Tag, SkeletonLegacy, Boundary } from "components";

  import styles from "styleguide/QuickshareLandingFilesTitle.json";

  export let isFilesLoading;

  const { title, note, downloadLimit } = $link;

  $: files = $link.files;
  $: isDownloaded = $link.isDownloaded;
  $: isLoading = false;

  $: isVirus = files.some((file) => file.virus);
  $: isAllInfected = files.every((file) => file.virus);
  $: isShowVirusModal = false;

  function handleDownloadFiles() {
    if (isVirus) {
      isShowVirusModal = true;
    } else {
      submitDownloadFiles();
    }
  }

  function submitDownloadFiles(includeInfected = true) {
    isLoading = true;
    isShowVirusModal = false;

    const params = { excludeSuspicious: !includeInfected };

    quickshareFrontendService.downloadFiles({
      params,
      onSuccess: () => (isLoading = false),
      onError: () => (isLoading = false),
    });
  }

  function handleHideModal() {
    isShowVirusModal = false;
  }
</script>

<Boundary>
  <div data-component="QuickshareLandingFilesTitle" class={styles.container}>
    <div>
      <div class={styles["upper-content"]}>
        <div class={styles.title}>
          {title}
        </div>
        <div class={styles.count}>
          <SkeletonLegacy loading={isFilesLoading}>
            <Tag
              label={`${files.length} ${$t(
                "quickshare_frontend_landing_files_title.files",
                {
                  postProcess: "interval",
                  count: files.length,
                },
              )}`}
              color="grey"
              size="small"
            />
          </SkeletonLegacy>
        </div>
      </div>
      {#if note}
        <div class={styles.note}>
          {note}
        </div>
      {/if}
    </div>

    <div class={styles["actions-wrapper"]}>
      <SkeletonLegacy loading={isFilesLoading}>
        {#if downloadLimit && isDownloaded}
          <Button style={"primary-tonal small"} disabled="true">
            {$t(
              "quickshare_frontend_landing_files_title.download_limit_reached",
            )}
          </Button>
        {:else}
          <Button
            style={"primary-tonal small"}
            click={handleDownloadFiles}
            disabled={isLoading || (!isLoading && !files.length)}
            >{$t("quickshare_frontend_landing_files_title.download_files", {
              postProcess: "interval",
              count: files.length,
            })}</Button
          >
        {/if}
      </SkeletonLegacy>
    </div>
  </div>

  <DownloadFilesVirusModal
    isShowModal={isShowVirusModal}
    onHide={handleHideModal}
    isDownloadLimit={downloadLimit}
    {isAllInfected}
    onSubmit={submitDownloadFiles}
  />
</Boundary>

<style lang="scss">
  .container {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .upper-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .title {
    @include typography-caption-semibold;

    color: var(--primary-500);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .count {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-height: 20px;
    min-width: 60px;
  }

  .note {
    @include typography-body-regular;

    display: flex;
    align-items: center;
    gap: 16px;
    padding-top: 8px;

    color: var(--primary-500);
  }

  .actions-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-self: flex-end;
    min-height: 40px;
    min-width: 100px;
  }
</style>
