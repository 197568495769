<script>
  import { onMount } from "svelte";

  import { account } from "stores/account.js";
  import { hasBrandSpace } from "stores/brand_space";
  import { t } from "stores/i18n.js";
  import { user } from "stores/user.js";

  import AccountApi from "apis/admin/accounts.js";

  import { Box, Toast } from "components";
  import styles from "styleguide/AdministrationSidebar.json";

  let adminUsers = [];
  onMount(async () => {
    if (!$user.is_admin) {
      getAdminUsers();
    }
  });

  function getAdminUsers() {
    AccountApi.getAdmins({
      params: {},
      success: function (data) {
        adminUsers = data;
      },
    });
  }

  $: accessMessage = () => {
    var message = $t("administrationSidebar.access_message");
    return (
      message +
      "<br>" +
      adminUsers
        .map((admin) => `<strong>${admin.name}</strong><br>${admin.email}`)
        .join("<br/>")
    );
  };
</script>

<div data-component="AdministrationSidebar" class={styles.wrapper}>
  <Box title={$t("administrationSidebar.title")} style="padding">
    <h4>{$account.official_name}</h4>
    <address>
      {@html $account.address.split(",").join("<br/>")}
    </address>
    {#if !$hasBrandSpace("eyde")}
      <p class={styles.muted}>
        {$t("administrationSidebar.disclaimer")}
      </p>
    {/if}
  </Box>
  {#if !$user.is_admin}
    <Toast
      title={$t("administrationSidebar.access_title")}
      message={accessMessage()}
    />
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }
  h4,
  address,
  p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .label {
    display: block;
    font-weight: 700;
  }

  .muted {
    color: gray;
    font-size: 0.875em;
    margin-bottom: 0;
  }
</style>
