<script>
  import Helper from "general/helper";
  import { accountTheme } from "stores/account.js";
  import { t } from "stores/i18n.js";
  import ColorPicker from "svelte-awesome-color-picker";

  import AccountApi from "apis/admin/accounts.js";
  import styles from "styleguide/ThemeAccountColorList.json";

  export let section;

  function updateColor(cssVariable, color) {
    $accountTheme[cssVariable] = color.hex;
    AccountApi.previewTheme($accountTheme);
  }

  const debouncedUpdateColor = Helper.debounce(updateColor, 300);
</script>

<div data-component="ThemeAccountColorList">
  <div class={styles["header"]}>
    {$t("theme_account_color_list.title")}
  </div>
  <ul>
    {#each Object.entries($accountTheme) as [cssVariable, colorCode]}
      {#if cssVariable.includes(section)}
        <li class={styles["color"]}>
          <ColorPicker
            label={$t(`theme_account_color_list.${cssVariable}`)}
            canChangeMode={false}
            hex={colorCode}
            on:input={(e) => debouncedUpdateColor(cssVariable, e.detail)}
            isAlpha={false}
          />
        </li>
      {/if}
    {/each}
  </ul>
</div>

<style scoped lang="scss">
  .color {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .header {
    @include typography-body-medium;

    margin: 0px 0px 5px 0px;
  }
</style>
