<script>
  import { onMount } from "svelte";
  import {
    AuditEmptyState,
    ControlGroup,
    ControlGroupNew,
    ControlStatus,
  } from "apps/pbc";
  import Sortable from "sortablejs";

  import { t } from "stores/i18n.js";
  import {
    audit,
    collapsedGroups,
    setCollapsedGroups,
    controlGroups,
    filteredControlGroups,
    filteredControlsArray,
    selectedGroups,
    selectedControls,
    filter,
    queryFilter,
    activeTemplate,
  } from "stores/pbc.js";
  import { can } from "stores/permission.js";
  import { isUserClient } from "stores/user.js";

  import AuditApi from "apis/pbc/audit.js";
  import ControlGroupApi from "apis/pbc/control_groups.js";

  import {
    Boundary,
    Button,
    Checkbox,
    ListFilters,
    Options,
    TextInput,
    Toast,
  } from "components";
  import styles from "styleguide/ControlGroupList.json";

  let items = [];
  let isSort = false;
  let list;

  $: isAllCollapsed = Object.keys($collapsedGroups).every(
    (key) => $collapsedGroups[key] === 0,
  );

  $: itemsToggle =
    $filteredControlsArray.every((c) =>
      $selectedControls.includes(c.permalink),
    ) && $filteredControlsArray.length;

  onMount(() => {
    new Sortable.create(list, {
      handle: ".dragicon-group",
      animation: 200,
      forceFallback: true,
      scroll: true,
      bubbleScroll: true,
      scrollSpeed: 15,
      onEnd: handleSortEnd,
    });
  });

  filteredControlGroups.subscribe((value) => {
    value.sort((ca, cb) => ca.position - cb.position);

    items = value.map((item) => {
      return item.__proto__;
    });
  });

  function handleSortEnd(event) {
    const { oldIndex, newIndex } = event;
    if (oldIndex === newIndex) return;

    const [movedItem] = items.splice(oldIndex, 1);
    items.splice(newIndex, 0, movedItem);

    isSort = false;
    ControlGroupApi.reorder({
      id: movedItem.permalink,
      position: newIndex + 1,
    });
  }

  function onSelectAllToggle(toggled) {
    if (toggled) {
      selectedGroups.set($filteredControlGroups.map((item) => item.permalink));
      selectedControls.set(
        $filteredControlsArray.map((item) => item.permalink),
      );
    } else {
      selectedGroups.set([]);
      selectedControls.set([]);
    }
  }

  function onCollapseAllItems() {
    const newCollapsedGroups = {};
    const oldCollapsedGroups = { ...$collapsedGroups };

    let isCollapsed = $controlGroups.every(
      (group) => oldCollapsedGroups[group.permalink] === 0,
    )
      ? 1
      : 0;

    $controlGroups.forEach((group) => {
      newCollapsedGroups[group.permalink] = isCollapsed;
    });

    setCollapsedGroups(newCollapsedGroups);
  }

  function removeFilter() {
    filter.set(null);
    AuditApi.goToShow();
  }
</script>

<Boundary>
  <div data-component="ControlGroupList">
    {#if $controlGroups.length === 0}
      <AuditEmptyState />
    {/if}

    <div class={styles.selectize}>
      <div class={styles["selectize-item"]}>
        <Checkbox toggle={onSelectAllToggle} toggled={itemsToggle} />
      </div>
      <TextInput
        placeholder={$t("control_group_list.search")}
        bind:value={$queryFilter}
        icon="search"
        style="big round-border no-border-sides left-side right-side"
      />
      {#if $can("read", "audit_template")}
        <div class={styles["selectize-item"]}>
          <Options
            title={$t("control_group_list.templates")}
            style="medium no-border acts-as-button-tonal"
          >
            {#if $can("create", "audit_template")}
              <Button
                click={() => {
                  activeTemplate.set("create");
                }}
                style="blue-text option"
                >{$t("control_group_list.templates_create")}</Button
              >
            {/if}
            <Button
              click={() => {
                activeTemplate.set("manage");
              }}
              style="blue-text option"
              >{$t("control_group_list.templates_manage")}</Button
            >
          </Options>
        </div>
      {/if}
      <div class={styles["selectize-item"]}>
        <div class={styles["margin-right"]}>
          <Button click={onCollapseAllItems} style="primary-text small">
            {isAllCollapsed
              ? $t("control_group_list.collapse")
              : $t("control_group_list.expand")}
          </Button>
        </div>
      </div>
    </div>

    {#if $filter}
      <div class={styles["list-filters"]}>
        <ListFilters>
          <ControlStatus close={removeFilter} status={$filter} size="medium" />
        </ListFilters>
      </div>
    {/if}

    <div bind:this={list} class="list-group">
      {#each items as item (item.id)}
        <ControlGroup
          {...item}
          onSort={(value) => {
            isSort = value;
          }}
        />
      {/each}
    </div>

    {#if $controlGroups.length && $filteredControlGroups.length === 0}
      <div class={styles["toast-margin-top"]}>
        <Toast type="info" message={$t("control_group_list.no_match")} />
      </div>
    {/if}

    {#if $can("create", "control_group") || ($isUserClient && $audit.can_client_users_add_controls)}
      <ControlGroupNew />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .selectize {
    display: flex;
  }

  .selectize-item {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 5px;
    border-top: solid 1px var(--primary-050);
    border-bottom: solid 1px var(--primary-050);
    background: #fff;
  }

  .selectize-item:first-child {
    padding: 0 20px 0 15px;
    border-left: solid 1px var(--primary-050);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  .selectize-item:last-child {
    border: 1px solid var(--primary-050);
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    align-items: center;
    justify-content: center;
    border-left: none;
  }

  .selectize-item:nth-child(3) {
    border-left: none;
  }

  .toast-margin-top {
    margin-top: 20px;
  }

  .margin-right {
    margin-right: 8px;
  }

  .list-filters {
    padding-top: 16px;
  }
</style>
