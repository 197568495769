<script>
  import { onMount } from "svelte";
  import { NotificationWidget } from "components/notification";
  import {
    showWidget,
    filteredNotificationsCount,
    notificationsCount,
  } from "stores/notification.js";
  import { webSocketUrl } from "stores/notification.js";
  import { user } from "stores/user.js";
  import { isUserTeamUser } from "stores/user.js";
  import NotificationApi from "apis/notification/notification.js";
  import AppCable from "lib/cable";
  import { BreadcrumbIconButton } from "components";
  import styles from "styleguide/Notification.json";

  $: notificationStatus = getNotificationStatus(
    $filteredNotificationsCount,
    $user.notificationsToggle,
  );

  function getNotificationStatus(count, toggled) {
    if (!toggled && $isUserTeamUser) {
      return "on-muted";
    } else if (count) {
      return "on";
    } else {
      return "off";
    }
  }

  function setupNotificationChannel() {
    new AppCable(webSocketUrl()).cable.subscriptions.create(
      { channel: "NotificationChannel" },
      {
        received: (data) => {
          if (data?.notifications_count >= 0) {
            notificationsCount.set(data.notifications_count);
            if ($showWidget) {
              NotificationApi.getData();
            }
          }
        },
      },
    );
  }

  onMount(async () => {
    setupNotificationChannel();
  });

  function toggleWidget() {
    showWidget.set(!$showWidget);
  }
</script>

<div>
  <div class={styles.notification}>
    <BreadcrumbIconButton
      click={toggleWidget}
      type="notification-{notificationStatus}"
    >
      {#if $notificationsCount}
        <span class={styles.count}>
          {$notificationsCount}
        </span>
      {/if}
    </BreadcrumbIconButton>
  </div>

  {#if $showWidget}
    <div class={styles.backdrop} on:click={toggleWidget} />
    <NotificationWidget />
  {/if}
</div>

<style lang="scss">
  .count {
    top: -2px;
    left: 13px;
    vertical-align: text-bottom;
    height: 14px;
    background: var(--red-100);
    min-width: 14px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    position: absolute;
    padding: 0 4px;
    border-radius: 7px;
  }

  .backdrop {
    all: initial;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 5;
  }
</style>
