<script>
  import { getContext, tick, onMount } from "svelte";
  import { DatePicker } from "date-picker-svelte";

  import { t } from "stores/i18n.js";
  import { iconPath } from "lib/helpers.js";

  import { Button } from "components";
  import styles from "styleguide/DatePicker.json";

  export let min = getInitialDate(-5);
  export let max = getInitialDate(5);
  export let value = null;
  export let deleteText = null;
  export let onSelect = null;
  export let onRemove = null;
  export let show = false;

  let arrow;
  let conditionalAttributes = {};
  let date = value ? new Date(value) : undefined;
  let deleteButton = deleteText ? deleteText : $t("date_picker.delete_date");

  const config = getContext("config");
  const localeTranslations = {
    de: {
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
    },
  };

  $: {
    if (config?.language !== "en")
      conditionalAttributes.locale = localeTranslations[config?.language];
  }

  onMount(() => {
    changeElements();
  });

  function getInitialDate(years) {
    let date = new Date();
    date.setFullYear(date.getFullYear() + years);
    return date;
  }

  function handlerRemove() {
    value = null;
    show = false;
    if (onRemove) onRemove();
  }

  function handlerSelect() {
    value = date;
    show = false;
    if (onSelect) onSelect(value);
  }

  async function changeElements() {
    await tick();
    let arrows = document.querySelectorAll(".date-time-picker .dropdown svg");
    let buttons = document.querySelectorAll(".date-time-picker .top button");

    arrows.forEach((target) => {
      let element = arrow.cloneNode();
      target?.replaceWith?.(element);
    });

    buttons.forEach((target) => {
      target.setAttribute("tabindex", 0);
    });
  }
</script>

<div data-component="DatePicker" class={styles.wrapper}>
  <div class={styles.inner}>
    <DatePicker
      {...conditionalAttributes}
      {min}
      {max}
      bind:value={date}
      on:select={handlerSelect}
    />
    <div class={styles.controls}>
      <div class={styles.control}>
        <Button click={handlerRemove} style={"error small"}>
          {deleteButton}
        </Button>
      </div>
    </div>
  </div>

  <div class={styles.hidden}>
    <img
      bind:this={arrow}
      class={styles.arrow}
      src={iconPath("stroke-1-5-down")}
      alt="stroke-1-5-down"
    />
  </div>
</div>

<style global lang="scss">
  .wrapper {
    padding: 16px;
    background: var(--white);
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-050);
    box-shadow: 0 3px 8px rgba(41, 49, 61, 0.1);
  }

  .inner {
    margin: 0 auto;
  }

  .arrow {
    position: absolute;
    bottom: 12px;
    pointer-events: none;
    box-sizing: content-box;
  }

  :global(.date-time-picker) {
    max-width: 290px;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
  }

  :global(.date-time-picker .top) {
    margin: 2px;
    height: 40px;
  }

  :global(.date-time-picker .tab-container .top button.page-button),
  :global(.date-time-picker .tab-container .top .dropdown select) {
    height: 40px;
    padding: 0;
    background: #fff;
    border-radius: var(--border-radius);
    cursor: pointer;

    &:hover {
      background: var(--blue-010);
      color: var(--blue-200);
    }

    &:focus:not(:active) {
      background: transparent;
      color: var(--blue-200);
      outline-offset: 2px;
      outline: 2px solid var(--blue-100);
    }

    &:active {
      background: var(--blue-025);
      color: var(--blue-200);
      fill: var(--blue-200);
    }
  }

  :global(.date-time-picker .tab-container .top .dropdown select.dummy-select) {
    border: 2px solid transparent;
    color: var(--primary-500);
  }

  :global(
      .date-time-picker
        .tab-container
        .top
        .dropdown
        select:hover
        + select.dummy-select
    ) {
    background: var(--blue-010);
    color: var(--blue-200);
  }

  :global(
      .date-time-picker
        .tab-container
        .top
        .dropdown
        select:focus:not(:active)
        + select.dummy-select
    ) {
    border: 2px solid var(--blue);
  }

  :global(
      .date-time-picker
        .tab-container
        .top
        .dropdown
        select:active
        + select.dummy-select
    ) {
    background: var(--blue-025);
    color: var(--blue-200);
  }

  :global(.date-time-picker .tab-container .top .dropdown option) {
    font-size: 14px;
  }

  :global(.date-time-picker .header) {
    flex-direction: row;
    line-height: initial;
    padding: 0;
    margin: 0;
    color: initial;
  }

  :global(.date-time-picker .top .dropdown.month) {
    margin-left: 4px;

    .arrow {
      right: 6px;
    }
  }

  :global(.date-time-picker .top .dropdown.year) {
    margin-right: 4px;

    .arrow {
      right: 4px;
    }
  }

  :global(.date-time-picker .tab-container .top .dropdown select) {
    font-size: 16px;
    font-weight: 700;
    padding: 6px 24px 6px 12px;
  }

  :global(.date-time-picker .tab-container .top .dropdown option) {
    padding: 5px 12px;

    &:hover {
      border-radius: 6px;
      background: var(--blue-010);
    }
  }

  :global(.date-time-picker .top button.page-button) {
    width: 40px;
  }

  :global(.date-time-picker .top button.page-button svg) {
    width: 13px;
    height: auto;
    opacity: 1;
    fill: var(--blue);
  }

  :global(.date-time-picker .top button.page-button:active svg) {
    fill: var(--blue-active);
  }

  :global(.date-time-picker .tab-container .header-cell),
  :global(.date-time-picker .tab-container .week .cell) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 32px;
    border-radius: var(--border-radius);
  }

  :global(.date-time-picker .tab-container .header-cell) {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-400);
  }

  :global(.date-time-picker .tab-container .week .cell) {
    font-weight: 600;
    font-size: 15px;
    margin: 2px;
    cursor: pointer;
  }

  :global(.date-time-picker .tab-container .week .cell:not(.other-month)) {
    background: transparent;
    color: var(--primary-500);

    &:hover {
      background: var(--blue-010);
      color: var(--blue-200);
      border: none;
    }

    &:active {
      background: var(--blue-025);
      color: var(--blue-200);
      border: none;
    }
  }

  :global(.date-time-picker .tab-container .week .cell.selected) {
    background: var(--blue-100);
    color: #fff;

    &:hover {
      background: var(--blue-200);
      color: #fff;
      border: none;
    }

    &:active {
      background: var(--blue-300);
      color: #fff;
      border: none;
    }
  }

  :global(.date-time-picker .tab-container .week .cell.today) {
    color: var(--blue-200);
    border: 2px solid transparent;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  .control {
    width: calc(50% - 12px);
  }

  .hidden {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
</style>
