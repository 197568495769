<script>
  import { Tooltip } from "components";
  import styles from "styleguide/AuditTemplateItem.json";

  export let title = "";
  export let description = "";
  export let onClick;
</script>

<div data-component="AuditTemplateItem" class={styles.item} on:click={onClick}>
  <Tooltip
    showArrow
    overflowingShow
    placement="bottom"
    nowrap={false}
    message={title}
  >
    <div class={styles.title}>{title}</div>
  </Tooltip>

  {#if description}
    <div class={styles.description}>{description}</div>
  {/if}
</div>

<style lang="scss">
  .item {
    padding: 16px;
    border-radius: 12px;
    min-height: 120px;
    border: 1px solid var(--primary-050);
    cursor: pointer;
    transition: all 0.2s ease-out;
    &:hover {
      border: 1px solid var(--blue-100);
      background: var(--blue-010);
    }
  }

  .title {
    @include typography-body-medium;
    @include overflow-protection;
    color: var(--blue-200);
    margin-bottom: 8px;
  }

  .description {
    @include typography-footnote-regular;
    color: var(--primary-500);
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
