<script>
  import { onMount } from "svelte";

  import { ADMIN_COMPONENT_KEY } from "apps/administration/constants";

  import { navigationEffectsService } from "apps/administration/services";

  import { t } from "stores/i18n.js";

  import AccountApi from "apis/admin/accounts.js";
  import { dateTimeLogFormat } from "lib/helpers.js";

  import { Box, Pagination } from "components";
  import styles from "styleguide/AdministrationLogs.json";

  onMount(async () => {
    loadLogs(1);
    navigationEffectsService.setBreadcrumbs(ADMIN_COMPONENT_KEY.LOGS);
    navigationEffectsService.updateHistory(ADMIN_COMPONENT_KEY.LOGS);
  });
  let logs = false;

  function loadLogs(page) {
    AccountApi.getLogs({
      params: {
        page: page,
      },
      success: function (data) {
        logs = data;
      },
    });
  }
</script>

<div data-component="AdministrationLogs">
  <Box style={"padding"}>
    <p class={styles.title}>
      {$t("administration_logs.title")}
    </p>

    {#if logs}
      <table>
        {#each logs.activities as activity}
          <tr>
            <td class={styles.date}>
              {dateTimeLogFormat(activity.created_at)}
            </td>

            <td>
              <span class={styles.message}>
                <span class={styles.user}>{activity.user} </span>
                {activity.message}
              </span>
            </td>
          </tr>
        {/each}
      </table>

      <div class={styles.footer}>
        <Pagination
          currentPage={logs.page}
          pagesNumber={logs.pages_num}
          click={loadLogs}
        />
      </div>
    {/if}
  </Box>
</div>

<style lang="scss">
  .title {
    @include typography-body-semibold;

    margin: 0 0 24px;
  }

  .date {
    width: 130px;
  }

  .message {
    word-wrap: break-word;
    white-space: break-spaces;
  }

  .user {
    @include typography-body-medium;
  }

  td {
    vertical-align: top;
    padding-block: 4px;
  }

  .footer {
    display: flex;
    text-align: center;
    justify-content: center;
  }
</style>
