import { breadcrumb } from "stores/breadcrumb.js";
import { updatePageHistory } from "stores/page_history.js";
import { writable, derived, get } from "svelte/store";

const audit = writable({});
const auditId = writable(null);
const auditStatuses = writable({});
const auditComments = writable([]);
const auditActivities = writable([]);
const auditTemplates = writable(null);
const auditClientUsers = writable([]);
const auditExportsList = writable([]);
const activeAudit = writable(false);
const activeExport = writable(false);
const activeTemplate = writable(null);
const activeRollForward = writable(false);

const activeDownloadUnsafe = writable(true);
const activeControlPermalink = writable(null);
const activeControlComments = writable([]);
const activeControlActivities = writable([]);
const activeMentionedFile = writable(null);

const collapsedGroups = writable({});
const controlGroups = writable([]);
const controls = writable([]);
const isNumeration = writable(false);
const IsHidingAcceptedControls = writable(false);
const improvedClientRights = writable(false);
const isUserCanDelegate = writable(false);

const selectedGroups = writable([]);
const selectedControls = writable([]);

const isSortAll = writable(false);
const filter = writable(null);
const queryFilter = writable(null);
const addedFiles = writable({});
const filteredEntities = writable([]);

const allowedFileTypes = writable([]);

audit.subscribe((value) => {
  if (!value.permalink) return;

  auditId.set(value.permalink);

  if (value.control_groups) {
    let groups = value.control_groups.map((item) => {
      item.id = item.permalink;
      item.numeration = convertToNumberingScheme(item.position);
      return item;
    });

    controlGroups.set(groups);
    setControls(value.control_groups);
  }

  isNumeration.set(value.enumerated);
  collapsedGroups.set(JSON.parse(localStorage.getItem(value.permalink)) || {});

  breadcrumb.set([
    { translation: "projects", path: value.dashboard_path },
    { name: value.project_name, path: value.project_path },
    { name: value.name, path: "" },
  ]);

  updatePageHistory([
    {
      path: value.pbc_path,
      titles: [{ name: value.project_name }, { name: value.name }],
    },
  ]);
});

const addedFilesControl = derived(
  [addedFiles, activeControlPermalink],
  ([$addedFiles, $activeControlPermalink], set) => {
    let files = [];

    if ($activeControlPermalink) {
      let storedFiles = $addedFiles[$activeControlPermalink];

      if (storedFiles) files = storedFiles;
    }

    set(files);
  },
);

// sets the activeControl to the control json object
const activeControl = derived(
  [controls, activeControlPermalink],
  ([$controls, $activeControlPermalink], set) => {
    let control = $controls.find((c) => c.permalink == $activeControlPermalink);
    set(control);
  },
);

const filteredControlsArray = writable();

// returns {'control_group.permalink'} => [controls]
const filteredControls = derived(
  [controls, controlGroups, filter, queryFilter, IsHidingAcceptedControls],
  (
    [
      $controls,
      $controlGroups,
      $filter,
      $queryFilter,
      $IsHidingAcceptedControls,
    ],
    set,
  ) => {
    let filtered = applyFilter($controls, $filter);
    filtered = applyQueryFilter(filtered, $queryFilter);

    if ($IsHidingAcceptedControls && $filter !== "accepted") {
      filtered = filtered.filter((item) => item.status !== "accepted");
    }

    filteredControlsArray.set(filtered);

    let byGroup = {};
    $controlGroups.forEach((group) => {
      let groupControls = filtered.filter(
        (c) => c.control_group_id == group.permalink,
      );
      groupControls = groupControls.sort((ca, cb) => ca.position - cb.position);
      groupControls = groupControls.map((item) => {
        item.nameNumeration = `${group.numeration}.${item.position}. ${item.name}`;
        return item;
      });

      byGroup[group.permalink] = groupControls;
    });

    set(byGroup);
  },
);

// This filters control groups & controls
// by query or status
const filteredControlGroups = derived(
  [controlGroups, filter, filteredControls, queryFilter],
  ([$controlGroups, $filter, $filteredControls, $queryFilter], set) => {
    let result = [];

    $controlGroups.forEach((originalGroup) => {
      let group = Object.create(originalGroup);
      let controls = $filteredControls[group.permalink] || [];
      let numeration = convertToNumberingScheme(group.position);

      group.__proto__.tags = setGroupsTags(group);
      group.__proto__.nameNumeration = `${numeration}. ${group.name}`;
      group.__proto__.numeration = numeration;

      // if a group has any matching controls just add it
      if (controls.length > 0) {
        result.push(group);
      } else if ($queryFilter && $queryFilter.length > 0) {
        // Check if the group name matches the search
        if (group.name.toLowerCase().includes($queryFilter.toLowerCase())) {
          if (!$filter) {
            result.push(group);
          }
        }
      } else if (!$filter) {
        result.push(group);
      }
    });

    set(result);
  },
);

function convertToNumberingScheme(number) {
  let baseChar = "A".charCodeAt(0);
  let letters = "";

  do {
    number -= 1;
    letters = String.fromCharCode(baseChar + (number % 26)) + letters;
    number = (number / 26) >> 0;
  } while (number > 0);

  return letters;
}

function setGroupsTags(group) {
  const tags = {};
  const statuses = [
    "pending",
    "accepted",
    "rejected",
    "overdue",
    "waiting_for_review",
  ];

  group.controls.map((control) => {
    const status = statuses.find((item) => item === control.status);

    if (status) {
      if (tags[status]) {
        tags[status] += 1;
      } else {
        tags[status] = 1;
      }
    }
  });

  return tags;
}

function setControls(groups) {
  let newControls = [];

  groups.forEach((group) => {
    group.controls.forEach((control) => {
      control.id = control.permalink;
      control.control_group_id = group.permalink;
      control.control_group_name = group.name;
    });

    newControls = [...newControls, ...group.controls];
  });

  controls.set(newControls);
}

function applyFilter(controls, value) {
  if (value) {
    if (value === "by-client:not-assigned") {
      return controls.filter((c) => c.client_users.length === 0);
    } else if (value.includes("by-client:")) {
      const clientEmail = value.slice(10);
      return controls.filter((c) =>
        c.client_users.some((client) => client.email === clientEmail),
      );
    } else if (value === "new-comments") {
      return controls.filter((c) => c.has_new_comments);
    } else if (value === "new-documents") {
      return controls.filter((c) => c.has_new_documents);
    } else if (value === "wfr" || value === "waiting_for_review") {
      return controls.filter(
        (c) =>
          c.waiting_for_review ||
          c.status == "waiting_for_review" ||
          c.status == "wfr",
      );
    } else if (value === "this-week") {
      return controls.filter(
        (c) => c.due_on_status_class === "orange" && c.status !== "accepted",
      );
    } else {
      return controls.filter((c) => c.status == value);
    }
  } else {
    return controls;
  }
}

function applyQueryFilter(controls, query) {
  if (query) {
    return controls.filter(
      (i) =>
        matchQuery(i.name, query) || matchQuery(i.control_group_name, query),
    );
  } else {
    return controls;
  }
}

function matchQuery(input, query) {
  return input.toLowerCase().indexOf(query.toLowerCase()) !== -1;
}

// sets activeControlPermalink
// also replaces URL in adressbar
function setActiveControl(permalink) {
  activeControlPermalink.set(permalink);
  let controlSegment = /\/controls\/.+/;
  let auditURL = window.location.href;

  if (permalink === null) {
    activeControlComments.set([]);
    activeControlActivities.set([]);
    auditURL = auditURL.replace(controlSegment, "");
    history.pushState(null, null, auditURL);
    document.title = get(audit).name;
  } else {
    if (auditURL.includes("/controls/")) {
      auditURL = auditURL.replace(controlSegment, `/controls/${permalink}`);
    } else {
      auditURL =
        auditURL.split(get(auditId))[0] +
        `${get(auditId)}/controls/${permalink}`;
    }

    history.pushState(null, null, auditURL);
    document.title = get(activeControl).name;
  }
}

/**
 * Replaces the active control in all controls, since activeControl is derived and can't be udpated directly
 * @param {any} control
 */
function replaceActiveControlAccess(control) {
  let udpatedControls = get(controls).map((c) => {
    if (c.permalink == control.permalink) {
      c.client_users = control.client_users;
      c.teams = control.teams;
    }
    return c;
  });
  controls.set(udpatedControls);
}

function setCollapsedGroups(groups) {
  collapsedGroups.set(groups);
  localStorage.setItem(get(auditId), JSON.stringify(groups));
}

function resetSelected() {
  selectedGroups.set([]);
  selectedControls.set([]);
}

function getAddedFilesControl(controlPermalink) {
  let allFiles = get(addedFiles);
  return allFiles[controlPermalink];
}

function setFilesToControl(files, controlPermalink) {
  let allFiles = get(addedFiles);
  allFiles[controlPermalink] = files;
  addedFiles.set(allFiles);
}

function addFilesToControl(files, controlPermalink) {
  let allFiles = get(addedFiles);
  let oldFiles = allFiles[controlPermalink] ? allFiles[controlPermalink] : [];
  let controlsFiles = [...oldFiles, ...files];
  allFiles[controlPermalink] = controlsFiles;
  addedFiles.set(allFiles);
}

function setUserCanDelegate(userId) {
  const clients = get(auditClientUsers);
  const canDelegate = clients.find((client) =>
    client.permalink.includes(userId),
  )?.rights.delegation;
  isUserCanDelegate.set(canDelegate);
}

export {
  audit,
  auditId,
  auditComments,
  auditStatuses,
  auditActivities,
  auditTemplates,
  auditClientUsers,
  activeAudit,
  activeExport,
  auditExportsList,
  activeTemplate,
  activeDownloadUnsafe,
  activeControl,
  activeRollForward,
  activeControlComments,
  activeControlActivities,
  activeControlPermalink,
  collapsedGroups,
  setCollapsedGroups,
  controlGroups,
  controls,
  IsHidingAcceptedControls,
  improvedClientRights,
  isUserCanDelegate,
  isNumeration,
  isSortAll,
  filter,
  filteredControls,
  filteredControlsArray,
  filteredControlGroups,
  queryFilter,
  setControls,
  setActiveControl,
  setUserCanDelegate,
  selectedGroups,
  selectedControls,
  setFilesToControl,
  addFilesToControl,
  getAddedFilesControl,
  addedFilesControl,
  resetSelected,
  activeMentionedFile,
  replaceActiveControlAccess,
  filteredEntities,
  allowedFileTypes,
};
