<script>
  import { link } from "apps/quickshareFrontend/stores";
  import Icons from "components/icons/Icons.svelte";

  import { differenceInDays } from "date-fns";

  import { currentLanguage } from "stores/i18n.js";

  import { Boundary, Box, Link } from "components";
  import { getSidebarInfoData } from "./helpers";

  import styles from "styleguide/QuickshareLandingSidebar.json";

  const { owner, expiredDate, downloadLimit, linkType } = $link;

  const daysUntilExpire = expiredDate
    ? differenceInDays(new Date(expiredDate), new Date())
    : null;

  $: dataList = [];
  $: $currentLanguage, setSidebarDataList();

  function setSidebarDataList() {
    dataList = getSidebarInfoData({
      type: linkType,
      owner,
      downloadLimit,
      daysUntilExpire,
    });
  }
</script>

<Boundary>
  <div data-component="QuickshareLandingSidebar" class={styles.sidebar}>
    <Box>
      <div class={styles.list}>
        {#each dataList as { icon, title, content }}
          <div class={styles.item}>
            <Icons {icon} fill="var(--primary-500)" width={18} height={18} />

            <div class={styles.info}>
              <div class={styles.title}>{title}</div>

              <div class={styles.content}>
                {#each content as { info, link }}
                  {#if link}
                    <Link href={link} label={info} />
                  {:else}
                    <span>{info}</span>
                  {/if}
                {/each}
              </div>
            </div>
          </div>
        {/each}
      </div>
    </Box>
  </div>
</Boundary>

<style lang="scss">
  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    color: var(--primary-500);
  }

  .item {
    @include typography-body-regular;

    display: flex;
    gap: 12px;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .title {
    @include typography-body-medium;
  }
</style>
