import { writable, derived } from "svelte/store";

const account = writable({});
const accountTheme = writable(false);
const accountRemoveLogo = writable(false);
const accountRemoveFavicon = writable(false);

const isAccountFeature = derived(account, ($account) => {
  return (feature) => $account.features.includes(feature);
});

const accountPasswordRules = writable({});
const currentIpAddress = writable("");
const ipWhitelist = writable([]);
const emailWhiteList = writable("");

export {
  account,
  accountTheme,
  accountRemoveLogo,
  accountRemoveFavicon,
  isAccountFeature,
  accountPasswordRules,
  currentIpAddress,
  ipWhitelist,
  emailWhiteList,
};
