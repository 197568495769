import {
  AdministrationDashboard,
  AdministrationDefaultSettings,
  AdministrationLogs,
  AdministrationSsoCredential,
  AdministrationTheme,
  AdministrationUserSynchronization,
  AdministrationSidebar,
} from "apps/administration/components";

import { ADMIN_COMPONENT_KEY } from "./adminComponentKey.js";
import {
  AdministrationApi,
  AdministrationCompliancePackage,
  Quickshare,
  QuickshareSidebar,
} from "../modules";

export const ADMIN_COMPONENTS = {
  [ADMIN_COMPONENT_KEY.DASHBOARD]: {
    component: AdministrationDashboard,
    sidebar: AdministrationSidebar,
  },
  [ADMIN_COMPONENT_KEY.LOGS]: {
    component: AdministrationLogs,
    sidebar: AdministrationSidebar,
  },
  [ADMIN_COMPONENT_KEY.API]: {
    component: AdministrationApi,
    sidebar: AdministrationSidebar,
  },
  [ADMIN_COMPONENT_KEY.THEME]: {
    component: AdministrationTheme,
    sidebar: AdministrationSidebar,
  },
  [ADMIN_COMPONENT_KEY.DEFAULT_SETTINGS]: {
    component: AdministrationDefaultSettings,
    sidebar: AdministrationSidebar,
  },
  [ADMIN_COMPONENT_KEY.COMPLIANCE_PACKAGE]: {
    component: AdministrationCompliancePackage,
    sidebar: AdministrationSidebar,
  },
  [ADMIN_COMPONENT_KEY.SCIM]: {
    component: AdministrationUserSynchronization,
    sidebar: AdministrationSidebar,
  },
  [ADMIN_COMPONENT_KEY.SSO]: {
    component: AdministrationSsoCredential,
    sidebar: AdministrationSidebar,
  },
  [ADMIN_COMPONENT_KEY.QUICKSHARE]: {
    component: Quickshare,
    sidebar: QuickshareSidebar,
  },
};
