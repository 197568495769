<script>
  import { isAfter, endOfDay, parse, format } from "date-fns";

  import { t } from "stores/i18n.js";

  import { dateFormat } from "lib/helpers";

  import { Button, DateInput, Tag } from "components";
  import { compliancePackageService } from "../services";
  import { compliancePackage } from "../stores";

  import styles from "styleguide/TwoFactorAuth.json";

  $: formData = {
    enforce_team_user_2fa_at: $compliancePackage.enforce_team_user_2fa_at,
    enforce_client_user_2fa_at: $compliancePackage.enforce_client_user_2fa_at,
  };

  function setDate(date, key) {
    const formattedDate = format(date, dateFormat.default) ?? null;
    compliancePackageService.updateValue(key, formattedDate);
  }

  function getLabel(date) {
    let label = $t("two_factor_auth.inactive");
    let color = "grey";

    if (date) {
      if (
        isAfter(
          parse(date, dateFormat.default, new Date()),
          endOfDay(new Date()),
        )
      ) {
        label = $t("two_factor_auth.pending");
        color = "orange";
      } else {
        label = $t("two_factor_auth.active");
        color = "green";
      }
    }
    return {
      label,
      color,
    };
  }

  function updateTwoFactorAuth() {
    compliancePackageService.updateCompliancePackage({
      payload: { ...formData },
    });
  }

  function resetTwoFactor(key) {
    compliancePackageService.updateValue(key, "");
  }
</script>

<div data-component="TwoFactorAuth">
  <div class={styles["section"]}>
    <div class={styles["section-header"]}>
      {$t("two_factor_auth.header")}
    </div>
    <div class={styles["section-description"]}>
      {$t("two_factor_auth.description")}
    </div>
    {$t("two_factor_auth.subtitle")}
    <div class={styles.items}>
      {#each Object.entries(formData) as [key, value]}
        <div class={styles["item"]}>
          <div class={styles["item-title"]}>
            {$t(`two_factor_auth.${key}`)}
            <Tag
              label={getLabel(value).label}
              color={getLabel(value).color}
              size="small"
            />
          </div>
          <div class={styles["item-datepicker"]}>
            <DateInput
              onRemove={() => resetTwoFactor(key)}
              onSelect={(date) => setDate(date, key)}
              dynamicPosition={false}
              {value}
              label={$t("two_factor_auth.activation_date")}
            />
          </div>
        </div>
      {/each}
    </div>
    <div class={styles["buttons"]}>
      <Button click={updateTwoFactorAuth} style={"primary"}>
        {$t("two_factor_auth.save")}
      </Button>
    </div>
  </div>
</div>

<style lang="scss">
  .section {
    &-header {
      display: flex;
      gap: 8px;
      align-items: center;
      padding-top: 24px;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: var(--primary-500, #052861);
    }

    &-description {
      padding-top: 8px;
      padding-bottom: 16px;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-300);
    }
  }
  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-top: 24px;

    .item {
      width: 100%;

      &-title {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--primary-500, #052861);
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        padding-bottom: 16px;
      }

      &-datepicker {
        font-weight: 500;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
    padding: 24px 0;
  }
</style>
