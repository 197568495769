<script>
  import {
    AdministrationSidebar,
    AdministrationDashboard,
  } from "apps/administration/components";

  import {
    currentAdminPage,
    defaultSettingsStore,
  } from "apps/administration/stores";
  import { AdministrationUpsellModal } from "components/modals";
  import { account } from "stores/account.js";
  import { t } from "stores/i18n.js";

  import { App, Header } from "components";

  import { AdministrationLayout } from "./components";
  import { ADMIN_COMPONENTS } from "./constants";

  export let appConfig;
  export let initialState;

  $: CurrentComponent =
    ADMIN_COMPONENTS[$currentAdminPage]?.component || AdministrationDashboard;
  $: SidebarComponent =
    ADMIN_COMPONENTS[$currentAdminPage]?.sidebar || AdministrationSidebar;

  currentAdminPage.set(initialState.adminPage);

  if (initialState) {
    defaultSettingsStore.update({
      project_allowed: initialState.projectsSettings.allowed_accesses
        .split(",")
        .map((item) => item.trim()),
      project_default: initialState.projectsSettings.default_access,
    });
  }
</script>

<App {...appConfig}>
  <div>
    <AdministrationLayout>
      <div slot="header">
        <Header
          type={$account.official_name}
          headline={$t(`administration.${$currentAdminPage}.title`)}
        />
      </div>

      <div slot="content">
        <svelte:component this={CurrentComponent} />
      </div>

      <div slot="sidebar">
        <svelte:component this={SidebarComponent} />
      </div>
    </AdministrationLayout>
  </div>

  <AdministrationUpsellModal />
</App>
