export const ADMIN_COMPONENT_KEY = Object.freeze({
  DASHBOARD: "dashboard",
  LOGS: "logs",
  API: "api",
  THEME: "theme",
  DEFAULT_SETTINGS: "default_settings",
  COMPLIANCE_PACKAGE: "compliance_package",
  SCIM: "scim",
  SSO: "sso",
  QUICKSHARE: "quickshare",
});
