<script>
  import clsx from "clsx";

  import SearchIcon from "components/icons/SearchIcon.svelte";

  import { Boundary, Button } from "components";

  import styles from "styleguide/SearchInput.json";

  export let value;
  export let placeholder;
  export let onSearch;
  export let isBordered = true;
  export let isEnterSearch = false;
  export let isThrottle = false;

  const throttlingTime = isThrottle ? 700 : 0;
  let throttleTimeout;

  function handleClean() {
    value = "";
    onSearch?.("");
  }

  function searchThrottle(val) {
    if (throttleTimeout) {
      clearTimeout(throttleTimeout);
    }

    throttleTimeout = setTimeout(() => {
      onSearch?.(val);
    }, throttlingTime);
  }

  function handleInput(e) {
    const { value } = e.target;
    if (!isEnterSearch && !isThrottle) {
      onSearch?.(value);
    } else if (!isEnterSearch) {
      searchThrottle(value);
    }
  }

  function handleKeydown(e) {
    if (isEnterSearch && e.key === "Enter") {
      onSearch?.(value);
    }
  }
</script>

<Boundary>
  <div
    data-component="SearchInput"
    class={clsx(styles.wrapper, { [styles["bordered"]]: isBordered })}
  >
    <SearchIcon width={18} fill="var(--blue-100)" />

    <input
      type="text"
      bind:value
      {placeholder}
      class={styles.input}
      on:input={handleInput}
      on:keydown={handleKeydown}
    />

    {#if value?.length}
      <Button
        click={handleClean}
        icon="grey_close"
        style={"small icon icon-only hover grey"}
      />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    padding: 6px 6px 6px 16px;
    border-radius: var(--border-radius);
    height: 48px;
    box-sizing: border-box;

    &.bordered {
      border: solid 1px var(--primary-050);
    }
  }

  .input {
    @include typography-body-regular;

    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    text-align: left;
    background: var(--white);
    color: var(--primary-500);
    border-radius: var(--border-radius);
    padding: 0 10px;

    &::placeholder {
      color: var(--primary-300);
    }
  }
</style>
