<script>
  import { t } from "stores/i18n";

  import { toastsHelper } from "shared/helpers";

  import { Button, TextInput } from "components";
  import { compliancePackageService } from "../services";

  import { compliancePackage } from "../stores";

  import styles from "styleguide/FiletypeWhiteList.json";

  const validationPattern = /^[a-z0-9]+(,[a-z0-9]+)*$/;

  $: filetypeWhiteList = $compliancePackage?.filetype_whitelist;

  let isActionLoading = false;

  function handleInputChange(value) {
    compliancePackageService.updateValue("filetype_whitelist", value);
  }

  function updateList() {
    const isValid =
      validationPattern.test(filetypeWhiteList) || !filetypeWhiteList.trim();

    if (!isValid) {
      toastsHelper.sendError("filetype_white_list.incorrect_input");
      return;
    }

    isActionLoading = true;

    const payload = {
      filetype_whitelist: filetypeWhiteList,
    };
    compliancePackageService.updateCompliancePackage({
      payload,
      onSuccess: () => (isActionLoading = false),
      onError: () => (isActionLoading = false),
    });
  }
</script>

<div data-component="FiletypeWhiteList" class={styles.section}>
  <div class={styles.header}>
    <div class={styles.title}>
      {$t("filetype_white_list.header")}
    </div>
    <div class={styles.description}>
      {$t("filetype_white_list.description")}
    </div>
    <div class={styles.description}>
      {$t("filetype_white_list.disclaimer")}
    </div>
  </div>
  <div class={styles.input}>
    <TextInput
      value={filetypeWhiteList}
      label={$t("filetype_white_list.label")}
      footnotes={[$t("filetype_white_list.sub_description")]}
      onKeyup={handleInputChange}
    />
  </div>
  <div>
    <Button click={updateList} style={"primary"} disabled={isActionLoading}>
      {isActionLoading
        ? $t("statuses.updating")
        : $t("filetype_white_list.save")}
    </Button>
  </div>
</div>

<style lang="scss">
  .section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .title {
    @include typography-caption-semibold;

    color: var(--primary-500);
  }

  .description {
    @include typography-body-regular;

    color: var(--primary-300);
  }

  .input {
    font-weight: 500;
  }
</style>
