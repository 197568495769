<script>
  import CheckmarkFilledIcon from "components/icons/CheckmarkFilledIcon.svelte";

  import { Boundary } from "components";

  import styles from "styleguide/QuickshareLandingStep.json";

  export let step;
  export let title;
  export let isActiveState = false;
  export let isCheckmark = false;
  export let desc = "";
</script>

<Boundary>
  <div
    data-component="QuickshareLandingStep"
    class={`${styles.container} ${step === 1 && isActiveState ? styles["container-unindented"] : ""} ${isActiveState ? styles["container-active"] : ""}`}
  >
    <div class={styles.header}>
      {#if isCheckmark}
        <CheckmarkFilledIcon width={32} height={32} />
      {:else}
        <span
          class={isActiveState
            ? styles["step-active"]
            : styles["step-inactive"]}
        >
          {step}
        </span>
      {/if}

      <div
        class={isActiveState
          ? styles["title-active"]
          : styles["title-inactive"]}
      >
        {title}
      </div>
    </div>

    {#if isActiveState}
      <div class={styles["active-container"]}>
        {#if desc}
          <div class={styles.desc}>
            {@html desc}
          </div>
        {/if}

        <slot name="content" />
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px;

    &-active {
      padding-bottom: 24px;
    }

    &-unindented {
      padding-bottom: 0;
    }
  }

  .header {
    @include typography-body-semibold;

    display: flex;
    align-items: center;
    gap: 12px;
  }

  .step {
    &-active {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 32px;
      height: 32px;
      border-radius: 100%;

      color: var(--white);
      background: var(--green-100);
    }

    &-inactive {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 32px;
      height: 32px;
      border-radius: 100%;

      color: var(--primary-200);
      background-color: var(--blue-010);
    }
  }

  .title {
    &-active {
      color: var(--primary-500);
    }

    &-inactive {
      color: var(--primary-200);
    }
  }

  .active-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .desc {
    @include typography-body-regular;

    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--primary-500);
  }
</style>
