<script>
  import { onMount } from "svelte";

  import { ADMIN_COMPONENT_KEY } from "apps/administration/constants";

  import { navigationEffectsService } from "apps/administration/services";

  import { Boundary, Pagination } from "components";

  import { QuickhareUsersSearch, QuickhareUsersTable } from "./components";
  import { quickshareService } from "./services";
  import { usersList } from "./stores";

  import styles from "styleguide/QuickshareAdmin.json";

  function handlePageChange(page) {
    const params = {
      page,
    };

    quickshareService.getUsersList({
      params,
    });
  }

  onMount(() => {
    quickshareService.getUsersList();
    navigationEffectsService.setBreadcrumbs(ADMIN_COMPONENT_KEY.QUICKSHARE);
    navigationEffectsService.updateHistory(ADMIN_COMPONENT_KEY.QUICKSHARE);
  });
</script>

<Boundary>
  <div data-component="QuickshareAdmin" class={styles.wrapper}>
    <QuickhareUsersSearch />

    {#if $usersList?.items}
      <div class={styles.pagination}>
        <Pagination
          currentPage={$usersList.page}
          pagesNumber={$usersList.pages_num}
          click={handlePageChange}
        />
      </div>
    {/if}

    <QuickhareUsersTable />
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 680px;
  }
</style>
