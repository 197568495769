<script>
  import { t } from "stores/i18n.js";

  import { Boundary, SearchInput } from "components";
  import { quickshareService } from "../../services";

  let value = "";

  function handleSearch() {
    const params = {
      page: 1,
      search: value,
    };

    quickshareService.getUsersList({
      params,
    });
  }
</script>

<Boundary>
  <div data-component="QuickhareAdminUsersSearch">
    <SearchInput
      bind:value
      onSearch={handleSearch}
      placeholder={$t("quickshare_admin.search")}
      isThrottle
    />
  </div>
</Boundary>
