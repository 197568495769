import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

// IMAGES
const images = import.meta.webpackContext("../images");

/**
 * Get the image path based on the provided name.
 * @param {string} name - The name of the image file.
 * @returns {string} - The path to the image.
 */
export const imagePath = (name) => {
  return images("./" + name)?.default ?? images("./" + name);
};

/**
 * Get the icon path based on the provided name.
 * @param {string} name - The name of the icon file.
 * @returns {string} - The path to the icon.
 */
export const iconPath = (name) => {
  return images("./icons/" + name)?.default ?? images("./icons/" + name);
};

/**
 * Get the file icon path based on the provided name.
 * @param {string} name - The name of the file icon file.
 * @returns {string} - The path to the file icon.
 */
export const fileIconPath = (name) => {
  return (
    images("./file-icons/" + name)?.default ?? images("./file-icons/" + name)
  );
};

/**
 * Get the admin icon path based on the provided name.
 * @param {string} name - The name of the admin icon file.
 * @returns {string} - The path to the admin icon.
 */
export const adminIconPath = (name) => {
  return (
    images("./admin-icons/" + name)?.default ?? images("./admin-icons/" + name)
  );
};

/**
 * A utility for formatting dates.
 *
 * @namespace
 * @property {string} default - The default date format string ("yyyy-MM-dd").
 * @returns {string} The formatted date string.
 */
export const dateFormat = {
  default: "yyyy-MM-dd",
};

/**
 * Formats a given timestamp string according to the specified locale and options.
 *
 * @param {string} timestring - The timestamp string to be formatted.
 * @param {string} [locale='de'] - The locale to use for formatting. Defaults to 'de'.
 * @param {Object} [options] - Additional options for formatting the timestamp.
 * @returns {string} The formatted date and time string.
 */
export const dateTimeFormat = (timestring, locale = "de", options) => {
  if (!timestring) {
    return "";
  }
  const timeOptions = options || {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: false,
    // timeZone: 'Germany/Berlin'
  };
  const localeString = locale == "de" ? "de-DE" : "en-US";
  return new Intl.DateTimeFormat(localeString, timeOptions).format(
    Date.parse(timestring),
  );
};

/**
 * Formats a given timestring into a localized date and time string.
 *
 * @param {string} timestring - The input timestring to be formatted.
 * @param {string} [locale='de'] - The locale for formatting (default is 'de').
 * @param {Intl.DateTimeFormatOptions} [options] - Additional options for date and time formatting.
 * @returns {string} The formatted date and time string.
 */
export const dateTimeLogFormat = (timestring, locale = "de", options) => {
  if (!timestring) {
    return "";
  }
  const timeOptions = options || {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    // timeZone: 'Germany/Berlin'
  };
  const localeString = locale == "de" ? "de-DE" : "en-US";
  return new Intl.DateTimeFormat(localeString, timeOptions).format(
    Date.parse(timestring),
  );
};

/**
 * Converts a JavaScript Date object to a string representation in the format 'YYYY-MM-DD'.
 *
 * @param {Date} objectDate - The Date object to be converted.
 * @returns {string} A string representing the date in the 'YYYY-MM-DD' format.
 */
export const dateTimeString = (objectDate) => {
  let day = objectDate.getDate();
  let month = objectDate.getMonth() + 1;
  let year = objectDate.getFullYear();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  return `${year}-${month}-${day}`;
};

/**
 * Converts a date or date string to a timestamp.
 *
 * @param {Date|string} date - The date or date string to be converted.
 * @returns {number} The timestamp representing the given date. Returns NaN if the input is invalid.
 */
const convertDateToTimestamp = (date) => {
  if (typeof date === "string") {
    return new Date(date).getTime();
  }
  if (date instanceof Date) {
    return date.getTime();
  }
  return NaN;
};

/**
 * Checks if the first date is after the second date.
 *
 * @param {Date} date1 - The first date to compare.
 * @param {Date} date2 - The second date to compare.
 * @returns {boolean|undefined} Returns "true" if date1 is after date2, "false" if date1 is before or equal to date2.
 *                              Returns "undefined" if either date1 or date2 is not a valid Date object.
 */
export const isDateAfterDate = (date1, date2) => {
  const timestamp1 = convertDateToTimestamp(date1);
  const timestamp2 = convertDateToTimestamp(date2);

  if (isNaN(timestamp1) || isNaN(timestamp2)) {
    return;
  }

  return timestamp1 > timestamp2;
};

/**
 * Escapes HTML special characters in a given string.
 *
 * @param {string} html - The input string containing HTML content.
 * @returns {string} - The escaped HTML string.
 */
export const escapeHtml = (html) => {
  const container = document.createElement("div");
  container.appendChild(document.createTextNode(html));
  return container.innerHTML;
};

/**
 * Takes a date string in the format 'YYYY-MM-DD' and returns a localized date string.
 *
 * @param {string} date - The input date string in 'YYYY-MM-DD' format.
 * @returns {string} The localized date string in 'DD.MM.YYYY' format.
 */
export const localizeDate = (date) => {
  return date.split("-").reverse().join(".");
};

/**
 * Returns the file icon path based on the file extension.
 *
 * @param {string} filename - The name of the file, including its extension.
 * @returns {string} - The file icon path.
 */
export const fileIcon = (filename) => {
  const extension = filename.split(".").pop().toLowerCase();
  let fileIcon = "file.svg";

  switch (extension) {
    case "pdf":
      fileIcon = "pdf.svg";
      break;
    case "xls":
    case "xlsx":
    case "csv":
      fileIcon = "excel.svg";
      break;
    case "ppt":
    case "pptx":
      fileIcon = "powerpoint.svg";
      break;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      fileIcon = "image.svg";
      break;
    case "doc":
    case "docx":
      fileIcon = "word.svg";
      break;
    case "zip":
      fileIcon = "zip.svg";
      break;
    default:
      fileIcon = "file.svg";
  }

  return fileIconPath(fileIcon);
};

/**
 * Converts a file size from megabytes (MB) to a human-readable format.
 *
 * @param {number} size - The size in megabytes (MB) to be converted.
 * @returns {string} A human-readable representation of the file size.
 */
export const humanFileSizeFromMB = (size) => {
  return humanFileSizeFromByte(size * 1024 * 1024);
};

/**
 * Converts a file size from bytes to a human-readable format.
 *
 * @param {number} size - The size of the file in bytes.
 * @returns {string} A string representation of the file size in a human-readable format, with appropriate units (B, KB, MB, GB, TB).
 */
export const humanFileSizeFromByte = (size) => {
  let i = Math.floor(Math.log(size) / Math.log(1024));
  i = i < 2 ? 2 : i;
  return `${parseFloat((size / Math.pow(1024, i)).toFixed(2))} ${["B", "KB", "MB", "GB", "TB"][i]}`;
};

/**
 * Calculates the difference in time, in seconds, between the current date and the provided date.
 *
 * @param {Date} date - The date to calculate the time difference from.
 * @returns {number} The time difference in seconds.
 */
export const distanceOfTimeInSeconds = (date) => {
  return Math.floor((new Date() - date) / 1000);
};

/**
 * Calculates the distance of time from the current date to the specified date.
 *
 * @param {Date} date - The target date for which the distance of time is calculated.
 * @param {string} locale - The locale to be used for formatting the distance of time.
 * @returns {string} A human-readable representation of the distance of time from the current date.
 */
export const distanceOfTimeFromDate = (date, locale) => {
  return distanceOfTime(date.getTime() / 1000, locale);
};

/**
 * Calculates the distance of time between a given timestamp and the current time.
 *
 * @param {number} timestamp - The timestamp in seconds to compare with the current time.
 * @param {string} locale - The locale code to determine the language and formatting of the output.
 * @returns {string} A human-readable string representing the time difference in a natural language format.
 */
export const distanceOfTime = (timestamp, locale) => {
  const translations = {
    en: {
      prefix: "",
      before: "",
      ago: "ago",
      in: "in",
      seconds: "less than a minute",
      minute: "about a minute",
      minutes: "%d minutes",
      hour: "about an hour",
      hours: "about %d hours",
      day: "a day",
      days: "%d days",
      month: "about a month",
      months: "%d months",
      year: "about a year",
      years: "%d years",
    },
    de: {
      prefix: "",
      before: "vor",
      ago: "",
      in: "in",
      seconds: "einer Minute",
      minute: "einer Minute",
      minutes: "%d Minuten",
      hour: "einer Stunde",
      hours: "%d Stunden",
      day: "einem Tag",
      days: "%d Tagen",
      month: "einem Monat",
      months: "%d Monaten",
      year: "einem Jahr",
      years: "%d Jahren",
    },
  };

  const locales = translations[locale];

  let seconds = Math.floor(new Date().getTime() / 1000 - parseInt(timestamp)),
    separator = locales.separator || " ",
    words = locales.prefix + separator,
    interval = 0,
    intervals = {
      year: Math.abs(seconds) / 31536000,
      month: Math.abs(seconds) / 2592000,
      day: Math.abs(seconds) / 86400,
      hour: Math.abs(seconds) / 3600,
      minute: Math.abs(seconds) / 60,
    };

  let distance = locales.seconds;

  for (var key in intervals) {
    interval = Math.floor(intervals[key]);

    if (interval > 1) {
      distance = locales[key + "s"];
      break;
    } else if (interval === 1) {
      distance = locales[key];
      break;
    }
  }

  distance = distance.replace(/%d/i, interval);

  if (seconds >= 0) {
    words += locales.before + separator + distance + separator + locales.ago;
  } else {
    words += locales.in + separator + distance;
  }

  return words.trim();
};

/**
 * Calculates the difference in days between two dates and returns a human-readable string representation.
 *
 * @param {string} [locale='de'] - The locale for language-specific translations ('en' for English, 'de' for German).
 * @param {string|number|Date} start - The start date for the calculation.
 * @param {string|number|Date} [finish=new Date()] - The end date for the calculation. Defaults to the current date if not provided.
 * @returns {string} - A human-readable representation of the date difference.
 */
export const diffDaysNumberToHuman = (
  locale = "de",
  start,
  finish = new Date(),
) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = Date.parse(finish);
  const secondDate = Date.parse(start);
  const todayDate = new Date();

  let diffDays = Math.floor((firstDate - secondDate) / oneDay);

  function getTranslation(key) {
    const translations = {
      en: {
        "in_x_days.one": "tomorrow",
        "in_x_days.other": "in %{count} days",
        "ago_x_days.one": "yesterday",
        "ago_x_days.other": "%{count} days ago",
        today: "today",
      },
      de: {
        "in_x_days.one": "morgen",
        "in_x_days.other": "in %{count} Tagen",
        "ago_x_days.one": "gestern",
        "ago_x_days.other": "vor %{count} Tagen",
        today: "heute",
      },
      fr: {
        "in_x_days.one": "dans 1 jour",
        "in_x_days.other": "dans %{count} jours",
        "ago_x_days.one": "il y a un jour",
        "ago_x_days.other": "il y a %{count} jours",
        today: "aujourd'hui",
      },
    };
    return translations[locale][key];
  }

  if (diffDays === 0) {
    const daysFromToday = Math.floor((todayDate - secondDate) / oneDay);

    switch (daysFromToday) {
      case 0:
        return getTranslation("today");
      case 1:
        return getTranslation("in_x_days.one");
      case -1:
        return getTranslation("ago_x_days.one");
      default:
        diffDays = daysFromToday;
    }
  }

  let number = Math.abs(diffDays) === 1 ? "one" : "other";
  let direction = diffDays < 0 ? "in_x_days" : "ago_x_days";

  return getTranslation(`${direction}.${number}`).replace(
    "%{count}",
    Math.abs(diffDays),
  );
};

/**
 * Formats the expiration message based on the number of days left.
 *
 * @param {number} expires_in - The number of days until expiration.
 * @param {string} [locale="de"] - The locale for the translation, either "en" or "de".
 * @returns {string} - The formatted expiration message.
 */
export function formatExpiresInDate(expires_in, locale = "de") {
  const translations = {
    en: {
      "in_x_days.one": "expires tomorrow",
      "in_x_days.other": "expires in %{count} days",
      today: "expires today",
    },
    de: {
      "in_x_days.one": "läuft morgen",
      "in_x_days.other": "läuft in %{count} Tagen",
      today: "läuft heute",
    },
  };

  const localeTranslations = translations[locale];

  let message;
  switch (expires_in) {
    case 0:
      message = localeTranslations["today"];
      break;
    case 1:
      message = localeTranslations["in_x_days.one"];
      break;
    default:
      message = localeTranslations["in_x_days.other"].replace(
        "%{count}",
        expires_in,
      );
      break;
  }

  return message;
}

/**
 * Checks if the provided text is a confirmation equivalent to 'YES' in the specified locale.
 *
 * @param {string} text - The text to be checked for confirmation.
 * @param {string} [locale='de'] - The locale to determine the confirmation word. Default is 'de'.
 * @returns {boolean} Returns "true" if the provided text is a confirmation, "false" otherwise.
 */
export const enterYesConfirmation = (text, locale = "de") => {
  const confirmationWords = {
    de: "JA",
    en: "YES",
  };

  return text.toUpperCase() === confirmationWords[locale];
};

/**
 * Retrieves the user path from the first three segments of the current window location's pathname.
 *
 * @returns {string} The user path derived from the window location.
 */
export const userPath = window.location.pathname
  .split("/")
  .slice(0, 3)
  .join("/");

/**
 * Generates a platform-specific link by concatenating the user's base path with the provided path.
 *
 * @param {string} path - The path to be appended to the user's base path.
 * @returns {string} The complete platform-specific link.
 */
export const platformLink = (path) => {
  return `${userPath}/${path}`;
};

export const platformRedirect = (path) => {
  window.location.href = platformLink(path);
};

export const platformRedirectByType = (permalink, type) => {
  let productLink = "";
  switch (type) {
    case "xba":
      productLink = `apps/confirmations/${permalink}`;
      break;
    case "sba":
      productLink = `reviews/${permalink}/samples`;
      break;
    case "pbc":
      productLink = `apps/pbc/${permalink}`;
      break;
    default:
      console.log("Unexpected type of product");
      return;
  }
  window.location.href = platformLink(productLink);
};

/**
 * Retrieves the CSRF token from the meta tag with the name "csrf-token" in the document.
 * @returns {string} The CSRF token value, or an empty string if the meta tag is not found.
 */
export const csrfToken = () => {
  let metaToken = document.querySelector(`meta[name="csrf-token"]`);
  return metaToken ? metaToken.content : "";
};

/**
 * Recursively builds FormData object from a nested data structure, handling arrays and objects.
 *
 * @param {FormData} formData - The FormData object to append key-value pairs to.
 * @param {Object} data - The data object to be converted into FormData.
 * @param {string} [parentKey] - The parent key for nested structures.
 * @param {boolean} [toArray] - Indicates whether to treat arrays differently for naming in FormData.
 */
export const buildFormData = (formData, data, parentKey, toArray) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey
          ? `${parentKey}[${toArray && Array.isArray(data) ? "" : key}]`
          : key,
        toArray,
      );
    });
  } else {
    const value = data === null ? "" : data;
    formData.append(parentKey, value);
  }
};

/**
 * Serializes an object into a URL query string.
 *
 * @param {Object} params - The object containing key-value pairs to be serialized.
 * @param {string} [prefix] - Optional prefix to be added to each parameter key.
 * @returns {string} - The serialized URL query string.
 */
export const serializeQuery = (params, prefix) => {
  const query = Object.keys(params).map((key) => {
    const value = params[key];

    if (params.constructor === Array) key = `${prefix}[]`;
    else if (params.constructor === Object)
      key = prefix ? `${prefix}[${key}]` : key;

    if (typeof value === "object") return serializeQuery(value, key);
    else return `${key}=${encodeURIComponent(value)}`;
  });

  return [].concat.apply([], query).join("&");
};

/**
 * Copies a given value or the content of a target element to the clipboard.
 *
 * @param {string} [value] - The value to be copied to the clipboard.
 * @param {HTMLElement} [target] - The target element from which the content will be copied.
 */
export const copyToClickboard = (value, target, message) => {
  let tempInput = document.createElement("input");

  if (target) {
    if (target.tagName === "INPUT" || tempInput.tagName === "TEXTAREA") {
      tempInput.value = target.value;
    } else {
      tempInput.value = target.textContent;
    }
  } else if (value) {
    tempInput.value = value;
  }

  tempInput.focus();
  navigator.clipboard.writeText(tempInput.value); // ain't supported by Internet Explorer
  tempInput.remove();
  toasts.send({
    message: message ?? get(t)("success.copied_link_successfully"),
    type: "success",
  });
};

/**
 * Replaces an object in an array if an object with the same identifier exists, otherwise appends the object to the array.
 *
 * @param {Array} array - The array to operate on.
 * @param {Object} object - The object to replace or append.
 * @param {string} [id='permalink'] - The identifier property to determine object equality.
 * @returns {Array} - The modified array with the object replaced or appended.
 */
export const replaceObjectInArray = (array, object, id = "permalink") => {
  if (array.find((item) => item[id] === object[id])) {
    return array.map((item) => (item[id] === object[id] ? object : item));
  } else {
    return [...array, object];
  }
};

/**
 * Downloads a file from a given response.
 *
 * @param {object} response - The response object containing file information.
 * @param {function} [success] - Optional callback function to be executed after successful download.
 * @param {boolean} [byBlob] - Indicates whether the file should be downloaded using Blob (default: based on file extension).
 * @returns {void}
 * @throws {Error} If the response object is not provided or lacks necessary file information.
 */
export const downloadFile = (response, success, byBlob) => {
  let url;
  let filename;

  if (response.data && response.data.attachment) {
    const file = response.data.attachment;
    url = file.url;
    filename = file.filename;
  } else {
    url = response.url;
    filename = response.filename;
  }

  const extension = filename.split(".").pop().toLowerCase();
  const textExtension = ["pdf", "txt", "csv", "png", "jpg", "jpeg"];
  const _byBlob = byBlob || textExtension.includes(extension);

  if (_byBlob) {
    fetch(url).then((t) => {
      return t.blob().then((b) => {
        let a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
        if (success) success();
      });
    });
  } else {
    let a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", filename);
    a.click();
    if (success) success();
  }
};

/**
 * Fetches a Blob from the specified URL.
 *
 * @param {string} url - The URL from which to fetch the Blob.
 * @returns {Promise<Blob>} A Promise that resolves to a Blob retrieved from the specified URL.
 */
export const blobPromiseFromUrl = (url) => {
  return fetch(url).then((t) => {
    return t.blob();
  });
};

/**
 * Determines whether the provided file type is one of the supported types
 * that can be previewed.
 *
 * @param {string} type - The file type to check for preview eligibility.
 * @returns {boolean} - Returns true if the file type is eligible for preview, otherwise false.
 */
export const isPreview = (type) => {
  switch (type) {
    case "application/pdf":
    case "image/jpeg":
    case "image/png":
    case "image/gif":
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return true;
    default:
      return false;
  }
};

/**
 * Checks if an item has been reviewed based on the specified role.
 *
 * @param {Object} item - The item to check for review status.
 * @param {string} role - The role for which the review status is checked ('team' or 'client').
 * @returns {boolean} - Returns "true" if the item has been reviewed for the specified role, otherwise "false".
 */
export const isReviewed = (item, role) => {
  return (
    (role === "team" && item.reviewed_as_team_user) ||
    (role === "client" && item.reviewed_as_client_user)
  );
};

/**
 * Checks if a given string contains valid HTML content.
 *
 * @param {string} string - The input string to check for HTML content.
 * @returns {boolean} - Returns "true" if the input string contains HTML, otherwise "false".
 */
export const isHTML = (string) => {
  const doc = new DOMParser().parseFromString(string, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

/**
 * Checks if two arrays are equal, considering the order of elements.
 *
 * @param {Array} arr1 - The first array to compare.
 * @param {Array} arr2 - The second array to compare.
 * @returns {boolean} True if the arrays are equal, false otherwise.
 */
export const isArraysAreEqual = (arr1, arr2) => {
  return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
};

/**
 * Temporary solution for replacing the host in SBA mailing urls to bypass CORS issues.
 * Can be removed once migrated to shrine
 *
 * @param {string} mailingUrl - URL to mailing (documents_controller).
 * @returns {string} url with current window.location.host.
 */
export const fixCarrierWaveUrl = (mailingUrl) => {
  return mailingUrl.replace(/\/\/[^\/]+\//, "//" + window.location.host + "/");
};
