import { get } from "svelte/store";

import { toastsHelper } from "shared/helpers";

import { quickshareApi } from "../apis";
import { usersList, usersListLoading } from "../stores";

class QuickshareService {
  constructor() {}

  getUsersList({ onSuccess, onError, params } = {}) {
    const { page, search } = params || {};

    usersListLoading.set(true);

    usersList.update((data) => ({
      ...data,
      ...(page && { page }),
    }));

    quickshareApi.getUsersList({
      params: {
        ...(page && { page }),
        ...(search && { search }),
      },
      success: (res) => {
        usersList.set(res?.data);
        usersListLoading.set(false);
        onSuccess?.(res);
      },
      error: (err) => {
        usersListLoading.set(false);
        toastsHelper.sendError("errors.unknown");
        onError?.(err);
      },
    });
  }

  updateUser({ onSuccess, onError, params } = {}) {
    const { id, isEnabled } = params || {};
    const currentData = get(usersList);

    const seatsLimit = currentData.quickshare_seats_limit;
    const seatsCount = currentData.quickshare_seats_count;

    if (seatsLimit === seatsCount && isEnabled) {
      toastsHelper.sendError("quickshare_admin.seats_limit");
      this.updateUsersList({ id, isEnabled: false });
      return;
    }

    this.updateUsersList({
      id,
      isEnabled,
      seatsCount: isEnabled ? seatsCount + 1 : seatsCount - 1,
    });

    quickshareApi.updateUser({
      params: {
        isEnabled,
        id,
      },
      success: (res) => {
        toastsHelper.sendSuccess("quickshare_admin.status_updated");
        onSuccess?.(res);
      },
      error: (err) => {
        usersList.set(currentData);
        toastsHelper.sendError("errors.unknown");
        onError?.(err);
      },
    });
  }

  updateUsersList({ id, isEnabled, seatsCount }) {
    usersList.update((data) => ({
      ...data,
      ...(seatsCount && { quickshare_seats_count: seatsCount }),
      items: data.items?.map((item) =>
        item.id === id ? { ...item, quickshare_seat: isEnabled } : item,
      ),
    }));
  }

  static instance;

  static getInstance() {
    if (!QuickshareService.instance) {
      QuickshareService.instance = new QuickshareService();
    }
    return QuickshareService.instance;
  }
}

export const quickshareService = QuickshareService.getInstance();
