<script>
  import { quickshareFrontendUploadService } from "apps/quickshareFrontend/services";

  import { isVerificationEmailSent } from "apps/quickshareFrontend/stores";

  import ErrorFilledIcon from "components/icons/ErrorFilledIcon.svelte";
  import Icons from "components/icons/Icons.svelte";
  import TrashIcon from "components/icons/TrashIcon.svelte";
  import { t } from "stores/i18n.js";

  import { fileIcon, humanFileSizeFromByte } from "lib/helpers.js";

  import { ProgressRing, Button, Spinner, Tooltip, Boundary } from "components";

  import styles from "styleguide/QuickshareLandingUploadFilesItem.json";

  export let file;

  let isActionLoading = false;

  $: icon = file.name ? fileIcon(file.name) : null;
  $: fileProgress = file.progress.percentage;

  function handleRemove() {
    isActionLoading = true;

    const params = {
      cacheId: file.cacheId,
      id: file.permalink,
    };

    quickshareFrontendUploadService.removeFile({
      params,
      onError: () => (isActionLoading = false),
    });
  }
</script>

<Boundary>
  <div class={styles.item} data-component="QuickshareLandingUploadFilesItem">
    <div class={styles.icon}>
      {#if file.progress.uploadStarted && !file.progress.uploadComplete}
        <ProgressRing size="28" stroke="4" completedPercent={fileProgress} />
      {:else if file.progress.uploadComplete && !file.stored}
        <Spinner color="var(--primary-100)" />
      {:else if file.progress.uploadComplete && file.virus}
        <Tooltip
          message={$t("quickshare_virus_tooltips.detected")}
          showArrow={true}
        >
          <ErrorFilledIcon width="22" height="22" fill="var(--red-100)" />
        </Tooltip>
      {:else if file.progress.uploadComplete}
        <img src={icon} alt="" />
      {/if}
    </div>
    <div class={styles.content}>
      <div class={styles.info}>
        <div class={styles.title}>{file.name}</div>
        <div class={styles.desc}>
          {#if !file.stored && file.progress.uploadComplete}
            {$t("quickshare_frontend_landing_file_list_item.checking")}
          {:else}
            ({humanFileSizeFromByte(file.size)})
          {/if}
        </div>
      </div>

      <div class={styles.actions}>
        {#if file.stored && file.virus}
          <Tooltip
            message={$t("quickshare_virus_tooltips.report")}
            showArrow={true}
          >
            <Button
              style={"primary-tonal icon small"}
              click={() => window.open(file.virus_report.url, "_blank")}
            >
              <Icons
                icon="virusReport"
                fill="var(--blue-100)"
                width={18}
                height={18}
              />
            </Button>
          </Tooltip>
        {/if}

        <Tooltip
          message={$t("quickshare_frontend_landing_file_list_item.delete")}
          showArrow={true}
        >
          <Button
            click={handleRemove}
            style={"icon error-text small"}
            disabled={$isVerificationEmailSent}
          >
            {#if isActionLoading}
              <Spinner scale="0.2" color="var(--primary-100)" />
            {:else}
              <TrashIcon height="18px" width="18px" />
            {/if}
          </Button>
        </Tooltip>
      </div>
    </div>
  </div>
</Boundary>

<style lang="scss">
  .item {
    position: relative;
    display: grid;
    grid-template-columns: 30px minmax(0, 1fr);
    align-items: center;
    padding: 0 5px;
    gap: 8px;
    height: 48px;

    &:not(:last-of-type):before {
      content: "";
      display: block;
      position: absolute;
      border-bottom: 1px solid var(--primary-050);
      bottom: 0;
      right: 0;
      width: calc(100% - 36px);
    }
  }

  .title {
    @include typography-body-regular;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .actions {
    display: flex;
    gap: 5px;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;

    img {
      display: flex;
    }
  }

  .desc {
    @include typography-footnote-regular;

    color: var(--primary-200);
    flex-shrink: 0;
  }
</style>
