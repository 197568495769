<script>
  import { onDestroy, onMount } from "svelte";

  import { ADMIN_COMPONENT_KEY } from "apps/administration/constants";

  import { navigationEffectsService } from "apps/administration/services";

  import { hasBrandSpace } from "stores/brand_space";
  import { can } from "stores/permission";

  import {
    AllowList,
    CustomPasswordRule,
    EmailWhiteList,
    FiletypeWhiteList,
    TwoFactorAuth,
  } from "./components";
  import { compliancePackageService } from "./services";

  import styles from "styleguide/AdministrationCompliancePackage.json";

  onMount(async () => {
    if (!$can("manage_compliance_package", "account")) {
      window.history.back();
    }

    compliancePackageService.getCompliancePackage();

    navigationEffectsService.setBreadcrumbs(
      ADMIN_COMPONENT_KEY.COMPLIANCE_PACKAGE,
    );
    navigationEffectsService.updateHistory(
      ADMIN_COMPONENT_KEY.COMPLIANCE_PACKAGE,
    );
  });

  onDestroy(() => {
    compliancePackageService.resetData();
  });

  const components = [
    !$hasBrandSpace("eyde") && CustomPasswordRule,
    TwoFactorAuth,
    AllowList,
    EmailWhiteList,
    FiletypeWhiteList,
  ].filter(Boolean);
</script>

<div data-component="AdministrationCompliancePackage">
  <div class={styles["content"]}>
    {#each components as component (component.key)}
      {#if component}
        <svelte:component this={component} />
        {#if component !== components[components.length - 1]}
          <div class={styles["divider"]} />
        {/if}
      {/if}
    {/each}
  </div>
</div>

<style scoped lang="scss">
  .content {
    width: 679px;
    padding: 0 24px 24px 24px;
    background: var(--white);
    box-shadow:
      0 6px 8px -6px rgba(24, 39, 75, 0.12),
      0 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
  }

  .divider {
    height: 4px;
    width: 100%;
    background: var(--primary-025, #d3d9e3);
    border-radius: 10px;
  }
</style>
