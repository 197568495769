import { t } from "stores/i18n";
import { get } from "svelte/store";

export const getTableHeaders = () => {
  return [
    {
      label: get(t)("quickshare_admin_table.status"),
      width: 15,
    },
    {
      label: get(t)("quickshare_admin_table.name"),
      width: 35,
    },
    {
      label: get(t)("quickshare_admin_table.email"),
      width: 30,
    },
    {
      label: get(t)("quickshare_admin_table.role"),
      width: 20,
    },
  ];
};
