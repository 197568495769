<script>
  import { onDestroy, onMount, afterUpdate } from "svelte";
  import { isAccountFeature } from "stores/account";

  import { t } from "stores/i18n.js";
  import { getModalContentByKey, updateModalsContent } from "stores/modals";
  import {
    addedFilesControl,
    activeControl,
    activeMentionedFile,
    activeControlComments,
    activeControlPermalink,
    activeControlActivities,
    allowedFileTypes,
  } from "stores/pbc.js";
  import { user, isUserTeamUser } from "stores/user.js";

  import CommentsApi from "apis/comments/comments.js";
  import ControlApi from "apis/pbc/controls.js";
  import { dateTimeFormat, isReviewed } from "lib/helpers.js";

  import {
    Activities,
    Boundary,
    Button,
    Comment,
    LegacyButton,
    Smalltabs,
    TextEditor,
    Toast,
    Avatar,
  } from "components";
  import styles from "styleguide/ControlComments.json";

  export let clientUsers;
  export let value;
  export let storageModalKey;
  export let storageModalContent = [];

  let type = "comment";

  onMount(() => {
    const content = getModalContentByKey(storageModalKey);
    if (!content) return;

    const storedControlComment = content.find(
      (item) => item.parent_id === $activeControlPermalink,
    );

    if ($activeControl) {
      if (
        storedControlComment &&
        storedControlComment.parent_id === $activeControlPermalink
      ) {
        value = storedControlComment.value;
      } else {
        value = "";
      }
    }
  });

  afterUpdate(() => {
    let newEntry = {
      value,
      parent_id: $activeControlPermalink,
    };

    const content = getModalContentByKey(storageModalKey);
    storageModalContent = content || [];

    const existingIndex = storageModalContent.findIndex(
      (entry) => entry.parent_id === newEntry.parent_id,
    );

    if (newEntry.value !== "") {
      if (existingIndex !== -1) {
        storageModalContent[existingIndex] = newEntry;
      } else {
        storageModalContent.push(newEntry);
      }
    } else {
      if (existingIndex !== -1) {
        console.log(newEntry.value);
        storageModalContent.splice(existingIndex, 1);

        updateModalsContent([
          {
            key: storageModalKey,
            state: storageModalContent,
          },
        ]);
      }
    }
  });

  $: highlightComments = $activeControlComments.map((comment) => {
    const parsedHTMLByTags = comment.content.split(
      /(<\/?\w+(?:(?:\s+\w+(?:\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>)/gim,
    );
    const parsedContent = parsedHTMLByTags
      .map((item) => {
        let stringIndex = item.indexOf("mentioned-item");
        if (stringIndex > -1 && item.includes($user.permalink)) {
          return (
            item.slice(0, stringIndex) +
            "mentioned-owner " +
            item.slice(stringIndex)
          );
        } else {
          return item.replace("mentioned-owner", "mentioned-user");
        }
      })
      .join("");

    return {
      ...comment,
      content: parsedContent,
    };
  });

  $: notRead = !$activeControlComments.every((item) =>
    isReviewed(item, $user.role),
  );
  $: mentionFiles = $addedFilesControl
    .sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })
    .map((item, index, array) => {
      let { permalink, filename, created_at } = item;

      let counts = array.reduce(
        (acc, value) => ({
          ...acc,
          [value.filename]: (acc[value.filename] || 0) + 1,
        }),
        {},
      );

      let label =
        counts[filename] > 1
          ? `${filename} (${dateTimeFormat(created_at)})`
          : filename;

      return {
        label,
        value: permalink,
      };
    });

  const unsubscribe = activeControlPermalink.subscribe((value) => {
    if (value) {
      ControlApi.getComments(value);
      ControlApi.getActivities(value);
    }
  });

  onDestroy(() => {
    unsubscribe();
  });

  function goToFile(id) {
    activeMentionedFile.set(id);
  }

  function onPostComment(content, attachments, mentions) {
    if (content) {
      CommentsApi.create({
        params: {
          parent_type: "control",
          parent_id: $activeControlPermalink,
          content,
          attachments,
          mentions,
        },
        success: getComments,
      });
    }
  }

  function onMarkAsRead() {
    CommentsApi.markAsRead({
      params: {
        parent_type: "control",
        parent_id: $activeControlPermalink,
      },
      success: getComments,
    });
  }

  function getComments() {
    ControlApi.getComments($activeControlPermalink);
  }
</script>

<Boundary>
  <div data-component="ControlComments">
    <Smalltabs>
      <LegacyButton
        style="smalltab"
        active={type === "comment"}
        click={() => {
          type = "comment";
        }}
      >
        {$t("control_comments.comments")}
        <span class={styles.count}>({$activeControlComments.length})</span>
      </LegacyButton>
      <LegacyButton
        style="smalltab"
        active={type === "activity"}
        click={() => {
          type = "activity";
        }}
      >
        {$t("control_comments.activities")}
      </LegacyButton>
    </Smalltabs>

    {#if type === "comment"}
      <div class={styles["comment-wrapper"]}>
        {#if $isUserTeamUser}
          <Toast
            message={$t("control_comments.comments_visible")}
            type="warning"
          />
        {/if}
        <div class={styles.write}>
          <div class={styles.avatar}>
            <Avatar
              name={$user.name}
              avatar={$user.avatar}
              isHighlighted={$user.role === "client"}
            />
          </div>

          <div class={styles.editor}>
            <TextEditor
              allowMentions={$isAccountFeature("mentions")}
              style={"toolbar-full"}
              mentionStyle="mention-big"
              parent_type="control"
              {clientUsers}
              parent_id={$activeControlPermalink}
              {onPostComment}
              {mentionFiles}
              bind:valueInput={value}
              {storageModalKey}
              allowedFileTypes={$allowedFileTypes}
            />
          </div>
        </div>

        <div class={styles.comments}>
          {#if notRead}
            <div class={styles.mark}>
              <Button click={onMarkAsRead} style="primary-text small"
                >{$t("control_comments.comments_mark_as_read")}</Button
              >
            </div>
          {/if}

          {#each highlightComments as comment}
            <Comment
              {...comment}
              parent_type="control"
              parent_id={$activeControlPermalink}
              onDeleteSuccess={getComments}
              mentionEvent={{
                dataset: "mentionedFile",
                handler: goToFile,
              }}
            />
          {:else}
            <div class={styles.noyet}>{$t("control_comments.no_comments")}</div>
          {/each}
        </div>
      </div>
    {/if}

    {#if type === "activity"}
      <Activities
        activitiesStore={activeControlActivities}
        getActivities={(page) => {
          ControlApi.getActivities($activeControlPermalink, page);
        }}
        style="no-padding"
      />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .write {
    display: flex;
  }

  .avatar {
    margin-right: 10px;
  }

  .count {
    font-weight: normal;
    color: var(--primary-300);
  }

  .label {
    margin-right: 24px;
    color: var(--blue-200);
    cursor: pointer;
  }

  .label.active {
    font-weight: 600;
    color: var(--primary-500);
  }

  .heading {
    display: flex;
    margin-bottom: 12px;
  }

  .comments {
    margin-top: 22px;
  }
  .comment-wrapper {
    padding-top: 1em;
  }

  .editor {
    width: 100%;
  }

  .noyet {
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: var(--primary-400);
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }

  .mark {
    margin: 10px 0 0 0;
    text-align: center;
  }
</style>
