<script>
  import ChevronRightIcon from "components/icons/ChevronRightIcon.svelte";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { t } from "stores/i18n.js";
  import { LegacyButton } from "components";
  import styles from "styleguide/Breadcrumb.json";

  let breadcrumbElement;

  function truncate(name) {
    const segmentLength = 45;
    return name?.length > segmentLength
      ? [name.slice(0, segmentLength / 2), name.slice(-segmentLength / 2)].join(
          "…",
        )
      : name;
  }
</script>

<div bind:this={breadcrumbElement}>
  {#if $breadcrumb}
    <ul class={styles.breadcrumb}>
      {#each $breadcrumb as { name, translation, path, click }, index}
        {#if path || click}
          <li>
            <LegacyButton link={path} {click} style={"breadcrumb-button"}>
              {name ? truncate(name) : $t(`breadcrumb.${translation}`)}
            </LegacyButton>
          </li>
        {:else}
          <li>
            <span>
              {name ? truncate(name) : $t(`breadcrumb.${translation}`)}
            </span>
          </li>
        {/if}
        {#if index < $breadcrumb.length - 1}
          <li class={styles.divider}>
            <ChevronRightIcon fill={"var(--breadcrumb-divider-color)"} />
          </li>
        {/if}
      {/each}
    </ul>
  {/if}
</div>

<style lang="scss">
  .breadcrumb {
    list-style-type: none;
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;

      &:last-child {
        pointer-events: none;
        padding-left: var(--spacing-12);
        span {
          color: var(--breadcrumb-last-item-color);
        }
      }
      &:first-child {
        padding-left: 0;
        span {
          padding-left: var(--spacing-12);
        }
      }

      &.divider {
        display: block;
        width: 26px;
        height: 18px;
        display: flex;
        justify-content: center;
      }
    }
  }
</style>
