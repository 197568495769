<script>
  import clsx from "clsx";
  import Icons from "components/icons/Icons.svelte";
  import styles from "styleguide/Card.json";

  export let style = "";
  export let icon = null;
  export let header;
  export let link;
  export let onCardClick = () => {};
  export let label;
  export let labelColor;
  export let disabled = false;
  export let description;

  function handlerCardClick() {
    if (onCardClick) {
      onCardClick(link);
    }
  }
  let hover = false;
  $: iconColor = disabled
    ? "var(--primary-300)"
    : hover
      ? "var(--blue-100)"
      : "var(--primary-500)";
</script>

<div
  data-component="Card"
  class={clsx(
    styles.card,
    style.split(" ").map((x) => styles[x]),
    { [styles["disabled"]]: disabled },
  )}
  tabindex="0"
  on:click={disabled ? "" : handlerCardClick}
  on:mouseenter={() => {
    hover = true;
  }}
  on:mouseleave={() => {
    hover = false;
  }}
>
  {#if icon}
    <Icons {icon} fill={iconColor} width={28} height={28} />
  {/if}
  {#if header}
    <div class={styles.header}>{header}</div>
  {/if}
  {#if description}
    <div class={styles.description}>{description}</div>
  {/if}
  {#if $$slots.default}
    <div class={styles.footer}>
      <slot />
    </div>
  {/if}
  {#if label}
    <span
      class={clsx(
        styles.label,
        labelColor === "blue" ? styles["--brand"] : styles["--pink"],
      )}>{label}</span
    >
  {/if}
</div>

<style scoped lang="scss">
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 4px;
    background: var(--white);
    cursor: pointer;
    box-shadow:
      0 2px 4px -2px rgba(24, 39, 75, 0.12),
      0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    transition: all 0.1s ease-out;

    &:hover:not(.disabled) {
      transform: translateY(-4px);
      box-shadow:
        0 6px 14px -6px rgba(24, 39, 75, 0.12),
        0px 10px 32px -4px rgba(24, 39, 75, 0.1);
      .header {
        color: var(--blue-200);
        transition: all 0.1s ease-out;
      }
    }

    &:focus {
      border-color: var(--blue);
      border-radius: 10px;
    }

    &.disabled {
      cursor: auto;
      .header,
      .description {
        color: var(--primary-300);
      }
    }
  }

  .header {
    height: 22px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--primary-500);
  }
  .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-500);
    margin-bottom: auto;
  }
  .label {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 0 8px;
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    line-height: 22px;
    &.--pink {
      background: var(--violet-010);
      color: var(--violet-200);
    }
    &.--brand {
      background: var(--blue-010);
      color: var(--blue-200);
    }
  }
  .footer {
    margin-top: 15px;
  }
</style>
