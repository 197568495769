<script>
  export let width = 16;
  export let height = 16;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 16 16"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="M9.249875,11.749625 C9.057875,11.749625 8.865875,11.676625 8.719875,11.529625 L4.719875,7.529625 C4.426875,7.236625 4.426875,6.762625 4.719875,6.469625 C5.012875,6.176625 5.486875,6.176625 5.779875,6.469625 L9.249875,9.938625 L12.719875,6.469625 C13.012875,6.176625 13.486875,6.176625 13.779875,6.469625 C14.072875,6.762625 14.072875,7.236625 13.779875,7.529625 L9.779875,11.529625 C9.633875,11.676625 9.441875,11.749625 9.249875,11.749625"
    id="icon-color"
    transform="translate(9.249875, 8.999750) rotate(-90.000000) translate(-9.249875, -8.999750)"
  />
</svg>
