import { writable } from "svelte/store";

export const usersList = writable({
  items: [],
  count: 0,
  page: 1,
  pages_num: 0,
  quickshare_seats_limit: 0,
  quickshare_seats_count: 0,
});
