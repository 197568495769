<script>
  import clsx from "clsx";

  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";

  import { imagePath } from "lib/helpers.js";

  import { ALLOWED_FILE_TYPES_DEFAULT } from "shared/constants";

  import { isFileTypeAcceptable } from "shared/helpers";

  import styles from "styleguide/Dropzone.json";

  export let allowedFileTypes = ALLOWED_FILE_TYPES_DEFAULT;
  export let uppyWrapper;
  export let uppy = false;
  export let loadingState = false;
  export let onFiles = (files) => {
    console.log(files);
  };
  export let accept = "";
  export let style = "";

  let fileInput;
  let dragging = false;

  function checkFilesType(files) {
    let _files = Array.from(files);

    for (let index = 0; index < _files.length; index++) {
      const element = _files[index];

      if (!isFileTypeAcceptable(element.name, allowedFileTypes)) {
        toasts.send({
          title: $t("errors.no_acceptable_file_type"),
          type: "error",
        });
        loadingState = false;
        return false;
      }
    }

    onFiles(files);
  }

  export function click(event) {
    fileInput.click();
  }

  function onDragEnter(event) {
    dragging = true;
  }

  function onDragLeave(event) {
    dragging = false;
  }

  function oDragover(event) {}

  function onDrop(event) {
    event.preventDefault();
    if (loadingState) return;
    dragging = false;
    let files = event.dataTransfer.files;
    checkFilesType(files);
  }

  function onFileInputChange(event) {
    if (loadingState) return;
    let files = event.target.files;
    checkFilesType(files);
    if (fileInput) fileInput.value = "";
  }
</script>

<div
  data-component="Dropzone"
  class={clsx(
    styles.dropzone,
    style.split(" ").map((x) => styles[x]),
    { [styles["dragging"]]: dragging },
  )}
  {loadingState}
  on:dragenter|preventDefault={onDragEnter}
  on:dragleave|preventDefault={onDragLeave}
  on:dragover|preventDefault={oDragover}
  on:drop|preventDefault={onDrop}
  on:change={onFileInputChange}
>
  <slot>
    <div class={styles.description} on:click={click}>
      {#if loadingState}
        <img
          class={styles.loading}
          src={imagePath("loading-spinner")}
          alt={""}
        />
      {:else}
        <span class={styles.title}>{$t("dropzone.dragdrop")}</span>
        <span class={styles.select}>{$t("dropzone.select")}</span>
      {/if}
    </div>
  </slot>

  {#if uppy}
    <div class={styles.uppy} bind:this={uppyWrapper} id="uppy" />
  {:else}
    <input
      bind:this={fileInput}
      {accept}
      type="file"
      multiple
      class={styles.fileInput}
    />
  {/if}
</div>

<style lang="scss">
  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
    overflow: hidden;

    &.dragging:after {
      content: "";
      pointer-events: none;
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      box-shadow: inset 0px 0px 10px 0px var(--blue);
      border-radius: var(--border-radius);
    }

    &.hidden {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }

  .description {
    flex: 1;
    border: dashed 2px var(--primary-050);
    border-radius: var(--border-radius);
    background: var(--primary-010);
    color: var(--primary-300);
    padding: 40px;
    cursor: pointer;
    text-align: center;
  }

  .description span {
    display: block;
    pointer-events: none;
  }

  .fileInput {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .title {
    font-size: 16px;
  }

  .select {
    color: var(--blue-200);
  }

  .uppy {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
</style>
