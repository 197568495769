<script>
  import styles from "styleguide/Skeleton.json";

  export let bgColor = "var(--blue-010)";
  export let radius = 8;
  export let width = 300;
  export let height = 24;
</script>

<div
  data-component="Skeleton"
  class={styles.skeleton}
  style={`background: ${bgColor}; border-radius: ${radius}px; width: ${width}px; height: ${height}px;`}
/>

<style lang="scss">
  .skeleton {
    overflow: hidden;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -80%;
      top: 0;
      height: 100%;
      width: 80%;
      background: linear-gradient(
        to right,
        transparent 0%,
        var(--primary-010) 50%,
        transparent 100%
      );
      animation: skeleton 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }

  @keyframes skeleton {
    from {
      left: -80%;
    }
    to {
      left: 100%;
    }
  }
</style>
