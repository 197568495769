<script>
  import { t } from "stores/i18n.js";

  import { Avatar } from "components";

  import styles from "styleguide/AvatarsGroup.json";

  export let users = [];
  export let limit = 4;
  export let ignoreLimit = false;

  $: visibleUsers =
    users.length > limit && !ignoreLimit ? users.slice(0, limit) : users;

  $: hiddenUsers = users.length > limit ? users.slice(limit) : [];
</script>

<div data-component="AvatarsGroup" class={styles.wrapper}>
  {#each visibleUsers as { name, avatar }}
    <div class={styles.item}>
      <Avatar {name} {avatar} size="small" />
    </div>
  {/each}

  {#if hiddenUsers.length}
    <div class={styles.item}>
      <Avatar name={`+${hiddenUsers.length}`} size="small">
        <div class={styles.tooltip}>
          <div class={styles.title}>
            {$t("avatars_group.members", {
              count: hiddenUsers.length,
            })}
          </div>

          <ul>
            {#each hiddenUsers as user}
              <li>{user.name}</li>
            {/each}
          </ul>
        </div>
      </Avatar>
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    width: fit-content;
  }

  .item {
    margin-left: -5px;
  }

  .tooltip {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
  }

  .title {
    border-bottom: 1px solid var(--primary-050);
    padding-bottom: var(--spacing-8);
  }
</style>
