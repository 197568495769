<script>
  import { quickshareService } from "apps/quickshare/services";
  import { t } from "stores/i18n";

  import { Boundary, Button, SearchInput } from "components";
  import { QuickshareFilterDropdown } from "./components";

  import styles from "styleguide/QuickshareSearchBar.json";

  export let isCollapsed;
  export let onToggleExpand;
  export let onFilterEnter;
  export let filters;

  function handleFilter() {
    quickshareService.setListFilters({ ...filters });
    onFilterEnter();
  }
</script>

<Boundary>
  <div class={styles.header} data-component="QuickshareSearchBar">
    <SearchInput
      bind:value={filters.name}
      onSearch={handleFilter}
      placeholder={$t("quickshare_content.search")}
      isBordered={false}
      isEnterSearch
    />

    <div class={styles.actions}>
      <QuickshareFilterDropdown bind:filters onEnter={handleFilter} />

      <Button style={"primary-text small"} click={onToggleExpand}>
        {isCollapsed
          ? $t("quickshare_content.expand")
          : $t("quickshare_content.collapse")}
      </Button>
    </div>
  </div>
</Boundary>

<style lang="scss">
  .header {
    display: flex;
    height: 50px;
    background: var(--white);
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-050);
  }

  .actions {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 8px;
    padding-right: 16px;
  }
</style>
