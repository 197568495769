import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class QuickshareApi {
  constructor() {
    this.endpoint = "/team_users/quickshare";
  }

  getUsersList(options) {
    const { success, error, params } = options || {};
    const { search, ...rest } = params;

    const formData = new FormData();
    if (params) {
      buildFormData(formData, {
        ...rest,
        ...(search && { search_term: search }),
      });
    }

    Api.get(`${this.endpoint}/search`, {
      params: formData,
      success,
      error,
    });
  }

  updateUser(options) {
    const { success, error, params } = options || {};
    const { isEnabled, id } = params || {};

    const formData = new FormData();
    buildFormData(formData, { quickshare_seat: isEnabled, id });

    Api.post(`${this.endpoint}/seat`, {
      body: formData,
      success,
      error,
    });
  }

  static instance;

  static getInstance() {
    if (!QuickshareApi.instance) {
      QuickshareApi.instance = new QuickshareApi();
    }
    return QuickshareApi.instance;
  }
}

export const quickshareApi = QuickshareApi.getInstance();
