<script>
  import styles from "styleguide/AdministrationLayout.json";
</script>

<div data-component="AdministrationLayout" class={styles.container}>
  <slot name="header" />

  <div class={styles.wrapper}>
    <div class={styles.content}>
      <slot name="content" />
    </div>

    <div class={styles.sidebar}>
      <slot name="sidebar" />
    </div>
  </div>
</div>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    padding: 0 var(--page-gutter) 40px var(--page-gutter);
  }

  .wrapper {
    display: flex;
    gap: 35px;
  }

  .content {
    flex: 1;
    min-width: 0;
  }

  .content > div {
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    flex: 0 0 335px;
  }
</style>
