<script>
  import { onMount } from "svelte";

  import { ADMIN_COMPONENT_KEY } from "apps/administration/constants";

  import { navigationEffectsService } from "apps/administration/services";

  import { isAccountFeature } from "stores/account";

  import { Boundary } from "components";
  import { ApiKey, Webhooks } from "./components";

  import styles from "styleguide/AdministrationApi.json";

  onMount(async () => {
    navigationEffectsService.setBreadcrumbs(ADMIN_COMPONENT_KEY.API);
    navigationEffectsService.updateHistory(ADMIN_COMPONENT_KEY.API);
  });
</script>

<Boundary>
  <div data-component="AdministrationApi" class={styles.wrapper}>
    <ApiKey />

    {#if $isAccountFeature("webhooks")}
      <Webhooks />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
</style>
