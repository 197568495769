<script>
  import { ResponderUploadItem } from "apps/responder";
  import { t } from "stores/i18n.js";
  import {
    pin,
    addedFiles,
    product,
    successResponse,
  } from "stores/responder.js";
  import { toasts } from "stores/toasts.js";

  import SampleResponseSubmissions from "apis/responder/general_method.js";

  import { Button, Dropzone, Tag } from "components";
  import styles from "styleguide/RespondSteps.json";

  export let setStatus;

  let dropzone;
  let dirty = false;
  let files;
  let addedFilesCount = 0;

  addedFiles.subscribe((value) => {
    files = value;
    addedFilesCount = value.length;
  });

  function addFiles(files) {
    dirty = false;
    const newFilesArray = [...$addedFiles, ...files];
    addedFiles.set(Array.from(newFilesArray));
  }

  function onCreateSuccess(response) {
    successResponse.set(response);
    setStatus("success");
  }

  function onCreateError() {
    toasts.send({ title: $t("errors.check_entries"), type: "error" });
  }

  function onSubmit() {
    if (files.length === 0) {
      dirty = true;

      return;
    }

    if (!files.some((file) => file.type === "application/pdf")) {
      toasts.send({
        title: $t("responder_steps.pdf_required"),
        type: "error",
      });
      return;
    }

    let formData = new FormData();

    formData.append("pin", $pin);

    for (let file of files) {
      formData.append("files[]", file);
    }

    SampleResponseSubmissions.create({
      body: formData,
      success: onCreateSuccess,
      error: onCreateError,
    });
  }
</script>

<form data-component="RespondSteps" on:submit|preventDefault={onSubmit}>
  <div class={styles.step}>
    <div class={styles.inner}>
      <div class={styles.top}>
        <div class={styles.tagWrapper}>
          <Tag color="blue" label="Step 1" size="medium" />
        </div>
        <h4 class={styles.title}>
          {$t("responder_steps.fill_and_scan_title")}
        </h4>
      </div>
      <p class={styles.text}>
        {@html $t(`responder_steps.fill_and_scan_${$product}_subtitle`)}
      </p>
    </div>
  </div>

  <div class={styles.step}>
    <div class={styles.inner}>
      <div class={styles.top}>
        <div class={styles.tagWrapper}>
          <Tag color="blue" label="Step 2" size="medium" />
        </div>
        <h4 class={styles.title}>
          {$t("responder_steps.upload_answer_title")}
        </h4>
      </div>
      <p class={styles.text}>
        {@html $t("responder_steps.upload_answer_subtitle")}
      </p>
      <div class={styles.addFile}>
        <div class={styles.addFileBottom}>
          {#if addedFilesCount < 10}
            <div class={styles.dropzoneWrapper}>
              <Dropzone
                bind:this={dropzone}
                onFiles={addFiles}
                accept="application/pdf,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/vnd.ms-excel,
                      application/msword,
                      image/jpeg,
                      image/png,
                      image/tiff,
                      text/csv"
              />
            </div>
            {#if dirty && addedFilesCount === 0}
              <div class={styles.error}>
                {$t("responder_steps.pdf_required")}
              </div>
            {/if}
          {/if}

          {#if addedFilesCount > 0}
            <div class={styles.dropzoneBody}>
              {#each $addedFiles as addedFile, index (addedFile)}
                <ResponderUploadItem file={addedFile} {index} />
              {/each}
            </div>
          {/if}
        </div>
      </div>

      <div class={styles.submit}>
        <Button style="primary">
          {$t("responder_steps.submit")}
        </Button>
      </div>
    </div>
  </div>
</form>

<style lang="scss">
  .step {
    display: flex;
    align-items: flex-start;
    margin: 35px 0;
    color: var(--primary-500);
  }

  .text {
    margin: 15px 0 0;
    line-height: 22px;
  }

  .top {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  .tagWrapper {
    white-space: nowrap;
  }

  .addFile {
    margin-top: 25px;
  }

  .dropzoneBody {
    flex: 1;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    box-sizing: border-box;
  }

  .qrcodeConfirmation {
    display: flex;
    align-items: flex-start;
    margin: 30px 0;
  }

  .submit {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .error {
    color: var(--red-100);
    padding: 8px 0;
    font-size: 12px;
    line-height: 18px;
  }
</style>
