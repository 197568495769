<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg xmlns="http://www.w3.org/2000/svg" {width} {height} viewBox="0 0 18 18">
  <path
    {fill}
    fill-rule="evenodd"
    d="M14,8 L10,8 L10,4 C10,3.448 9.553,3 9,3 C8.447,3 8,3.448 8,4 L8,8 L4,8 C3.447,8 3,8.448 3,9 C3,9.553 3.447,10 4,10 L8,10 L8,14 C8,14.553 8.447,15 9,15 C9.553,15 10,14.553 10,14 L10,10 L14,10 C14.553,10 15,9.553 15,9 C15,8.448 14.553,8 14,8"
  />
</svg>
