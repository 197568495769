<script>
  import clsx from "clsx";

  import { InputWrapper } from "components";

  import styles from "styleguide/TextArea.json";

  export let name = "";
  export let label = "";
  export let optional = false;
  export let footnotes;
  export let placeholder = "";
  export let maxLength = 2000;
  export let withCounter = false;
  export let value = "";
  export let disabled = false;
  export let errors = [];
  export let invalid = false;
  export let textareaRows = 4;

  export let onKeydown = () => {};
  export let onChange = () => {};
  export let onInput = () => {};

  $: _invalid = invalid || errors.length > 0;
  $: rightLabel =
    withCounter && maxLength ? `${value?.length || 0}/${maxLength}` : "";
</script>

<InputWrapper {label} {optional} {footnotes} {errors} {rightLabel}>
  <textarea
    data-component="TextArea"
    class={clsx(styles.textarea, { [styles["invalid"]]: _invalid })}
    bind:value
    on:keydown={onKeydown}
    on:change={onChange}
    on:input={onInput}
    maxlength={maxLength}
    rows={textareaRows}
    {name}
    {placeholder}
    {disabled}
  />
</InputWrapper>

<style lang="scss">
  .textarea {
    display: flex;
    position: relative;
    text-align: left;
    background: var(--white);
    color: var(--primary-500);
    border-radius: var(--border-radius);
    border: solid 1px var(--primary-050);
    outline: none;
    resize: none;
    padding: 10px;
    font-family: inherit;

    &:disabled {
      background: var(--primary-010);
    }

    &.invalid {
      border: solid 1px var(--red-200);
    }

    &:hover,
    &:focus,
    &:active {
      &:not(:disabled):not(.invalid) {
        border: solid 1px var(--blue);
      }
    }

    &::placeholder {
      color: var(--primary-300);
    }

    &:focus::placeholder {
      color: var(--primary-040);
    }
  }
</style>
