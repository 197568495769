<script>
  import { onMount, onDestroy, setContext } from "svelte";
  import {
    ConfirmationChecklist,
    ConfirmationContactsEdit,
    ConfirmationEmptyState,
    ConfirmationFilters,
    ConfirmationList,
    ConfirmationRollForward,
    ConfirmationSettingsEdit,
    ConfirmationSignersEdit,
    ConfirmationSidebar,
  } from "apps/confirmation";
  import {
    ConfirmationArchiveModal,
    ConfirmationDeleteModal,
    ConfirmationExportModal,
    ConfirmationLetterheadModal,
    ConfirmationReleaseModal,
    SandboxDeleteModal,
  } from "components/modals";
  import {
    filter,
    sidebar,
    comments,
    confirmation,
    confirmationsItems,
    checkFilterOnPopstate,
    checkFilterOnMount,
    letterhead,
  } from "stores/confirmation/confirmation.js";
  import { sandboxDelete } from "stores/dashboard/sandbox.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import { user } from "stores/user.js";

  import ConfirmationApi from "apis/confirmation/confirmation.js";
  import { initializeNavigation } from "shared/helpers";

  import { App, ContentSidebar, Header, Toast } from "components";

  export let appConfig;
  export let initialState;

  onMount(async () => {
    confirmation.set(initialState.confirmation);
    sidebar.set(initialState.sidebar);
    letterhead.set(initialState.letterhead);
    comments.set(initialState.comments);

    initializeNavigation({
      state: initialState.confirmation,
      path: initialState.confirmation.root_path,
      type: "",
    });

    checkFilterOnMount();
    window.addEventListener("popstate", checkFilterOnPopstate);
  });

  onDestroy(() => {
    removeEventListener("popstate", checkFilterOnPopstate);
  });

  setContext("comments", comments);
  setContext("parent", confirmation);
  setContext("parent_type", "confirmation");
  setContext("comments_visible", false);

  function deleteConfirmation() {
    ConfirmationApi.delete({
      params: {
        id: $confirmation.permalink,
      },
      success: (response) => {
        const path = response.data.url;

        if (path) {
          window.location = path;
        }
      },
      error: () => {
        toasts.send({
          title: $t("confirmation_page.confirmation_error"),
          type: "error",
        });
      },
    });
  }
</script>

<App {...appConfig}>
  <ContentSidebar>
    <div slot="content" data-component="Confirmation">
      <Header
        type={$t("products.xba")}
        headline={$confirmation.name}
        client_name={$confirmation.client_name}
        client_official_name={$confirmation.client_official_name}
      />

      {#if $confirmation.sandbox}
        <Toast
          title={$t("confirmation_page.sandbox")}
          isSandboxBtnDelete
          isActionShown={false}
          action={{
            style: "error-text small",
            click: () => sandboxDelete.set(true),
            button: $t("confirmation_page.delete"),
          }}
        />
      {/if}

      <ConfirmationFilters />

      {#if $user.role === "client"}
        <ConfirmationChecklist />
      {/if}

      {#if $confirmationsItems.length || $filter}
        <ConfirmationList />
      {:else if $user.role !== "client"}
        <ConfirmationEmptyState />
      {/if}

      <ConfirmationArchiveModal />
      <ConfirmationDeleteModal />
      <ConfirmationExportModal />
      <ConfirmationSignersEdit />
      <ConfirmationContactsEdit />
      <ConfirmationSettingsEdit />
      <ConfirmationRollForward />

      {#if $user.role !== "client"}
        <ConfirmationReleaseModal />
      {/if}
      <ConfirmationLetterheadModal />
      <SandboxDeleteModal onDelete={deleteConfirmation} />
    </div>

    <div slot="sidebar" data-component="ConfirmationIndex">
      <ConfirmationSidebar />
    </div>
  </ContentSidebar>
</App>
