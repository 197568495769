<script>
  import clsx from "clsx";
  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";
  import {
    imagePath,
    iconPath,
    fileIcon,
    humanFileSizeFromByte,
    humanFileSizeFromMB,
  } from "lib/helpers.js";

  import { Boundary, ProgressRing } from "components";
  import styles from "styleguide/ControlFileItem.json";

  $: icon = name ? fileIcon(name) : null;

  export let name;
  export let size;
  export let uploadedSize;
  export let progress;
  export let checked;
  export let virus;
  export let virus_scanning;
  export let uploadFailed;
  export let user;
  export let versionName;
  export let hovering;
  export let is_stored;
  export let last;
  export let preview = null;
  export let file;
  export let url;

  $: meta = getMeta(user, size);

  function getMeta(user, size) {
    let _size = uploadedSize
      ? humanFileSizeFromMB(uploadedSize)
      : humanFileSizeFromByte(size);
    return user
      ? `(${_size}) ${$t("meta.by")} ${user.name ? user.name : user.email}`
      : `(${_size})`;
  }

  function onOpenDocument() {
    if (url) {
      window.open(url, "_blank").focus();
    } else if (!virus_scanning || is_stored) {
      documentViewerStore.openDocument({
        title: file.filename,
        url: file.url,
        id: file.permalink,
        is_archived: file.archived,
        is_read: file.reviewed_as_team_user,
        is_pdf: file.is_pdf,
      });
    }
  }
</script>

<Boundary>
  <div
    data-component="ControlFileItem"
    class={clsx(
      styles.file,
      last && styles.last,
      hovering && styles.hovering,
      (virus || uploadFailed) && styles.virus,
      preview && styles.preview,
      virus_scanning && styles.scanning,
    )}
  >
    <div on:click={onOpenDocument} class={styles.icon}>
      {#if progress == 100}
        <ProgressRing
          size="32"
          stroke="2"
          completedPercent={100}
          showSpinner={true}
        />
      {:else if progress}
        <ProgressRing
          size="32"
          stroke="2"
          completedPercent={progress}
          showCheckmark={false}
        />
      {:else if uploadFailed}
        <img class={styles.checkmark1} src={iconPath("alert")} alt="filled" />
      {:else if virus_scanning}
        <ProgressRing
          size="32"
          stroke="2"
          completedPercent={100}
          showCheckmark={true}
        />
      {:else if virus}
        <img class={styles.checkmark1} src={iconPath("alert")} alt="filled" />
      {:else if icon}
        <img src={icon} alt={icon} />
      {/if}
      {#if checked && !virus_scanning && !virus}
        <img
          class={styles.checkmark}
          src={iconPath("icon-file-downloaded-checkmark_blue")}
          alt="checkmark"
        />
      {/if}
    </div>
    <div class={styles.main}>
      <div on:click={onOpenDocument} class={styles.content}>
        <div class={styles.name}>{versionName ? versionName : name}</div>
        {#if virus}
          <div class={styles.meta}>{$t("statuses.virus_was_found")}</div>
        {:else if uploadFailed}
          <div class={styles.meta}>{$t("statuses.upload_failed")}</div>
        {:else}
          <div class={styles.meta}>{meta}</div>
        {/if}
      </div>
      {#if virus_scanning && !uploadFailed}
        <div class={styles.viruses}>
          <span>{$t("statuses.checking_for_viruses")}</span>
          <img
            src={imagePath("loading-spinner")}
            alt={$t("statuses.checking_for_viruses")}
          />
        </div>
      {:else}
        <div class={styles.actions}>
          <slot />
        </div>
      {/if}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .file {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    padding-right: 5px;

    &.hovering {
      background-color: var(--blue-010);
    }
  }

  .main {
    display: flex;
    align-items: center;
    flex: 1;
    border-bottom: 1px solid var(--primary-010);

    .hovering & {
      border-bottom: 1px solid transparent;
    }

    .last & {
      border-bottom: 1px solid transparent;
    }
  }

  .icon {
    margin-right: 6px;
    position: relative;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 28px;
    }

    .checkmark {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: -5px;
      right: -2px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    cursor: pointer;
  }

  .name {
    font-weight: 500;
    line-height: 22px;
    color: var(--primary-500);

    .preview & {
      color: var(--blue-200);
    }
  }

  .meta {
    color: var(--primary-300);
    font-size: 12px;
    line-height: 18px;

    .virus & {
      color: var(--red-200);
    }
  }

  .name,
  .meta {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .hovering &,
    .scanning & {
      max-width: 220px;
      padding-right: 20px;
    }
  }

  .progress {
    font-size: 12px;
    font-weight: 500;
    color: var(--green-400);
  }

  .viruses {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--primary-300);
  }

  .viruses img {
    width: 28px;
    height: 28px;
    margin-left: 5px;
  }
</style>
