import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import DragDrop from "@uppy/drag-drop";
import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";
import { ALLOWED_FILE_TYPES_DEFAULT } from "shared/constants";
import { isFileTypeAcceptable } from "shared/helpers";

class Upload {
  constructor() {
    this.id = null;
    this.apiHandler = null;
    this.onBeforeFileAdded = (currentFile) => {};
    this.onFileAdded = () => {};
    this.setFileSending = () => {};
    this.setFileProgress = () => {};
    this.responseFunc = () => {};
    this.mutexPool = null;
  }

  onAddFiles(files, setLoading) {
    if (setLoading) setLoading(true);

    files.forEach((file) => {
      try {
        uppy.addFile({
          source: "file input",
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (error) {
        if (error.isRestriction) {
          console.error("Restriction error:", error);
        } else {
          console.error(error);
        }
      }
    });
  }

  setupUppy(element, allowedFileTypes) {
    const fileTypes = allowedFileTypes || ALLOWED_FILE_TYPES_DEFAULT;
    this.uppy = this.initializeUppy(fileTypes);

    this.setAws();
    this.initializeUppyEvents();

    if (element) {
      this.setFileDrop(element);
    }
  }

  setFileDrop(element) {
    this.uppy.use(DragDrop, {
      target: element,
      width: "100%",
      height: "100%",
      inputName: "filesInput[]",
    });
  }

  closeUppy() {
    this.uppy.cancelAll({ reason: "user" });
    this.uppy.clear();
  }

  setAws() {
    this.uppy.use(AwsS3, {
      limit: 5,
      timeout: 30 * 1000,
      endpoint: "/minio",
    });
  }

  initializeUppy(allowedFileTypes) {
    return new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 2000 * 1024 * 1024, // # 2000 MB
        maxNumberOfFiles: 500,
        minNumberOfFiles: 1,
      },
      onBeforeFileAdded: (currentFile) => {
        const isFileTypeError = !isFileTypeAcceptable(
          currentFile.name,
          allowedFileTypes,
        );

        if (isFileTypeError) {
          toasts.send({
            title: get(t)("errors.no_acceptable_file_type"),
            type: "error",
          });
          this.onBeforeFileAdded(null);
          return false;
        }

        return this.onBeforeFileAdded(currentFile);
      },
    });
  }

  initializeUppyEvents() {
    let self = this;

    this.uppy.on("file-added", (file) => {
      file.percentage = 10;
      file.created_at = new Date().toISOString();
      this.onFileAdded(file);
    });

    this.uppy.on("upload-progress", (file, progress) => {
      const id = file.id,
        percentage = (
          (progress.bytesUploaded / progress.bytesTotal) *
          100
        ).toFixed(0);

      this.setFileProgress(id, percentage);
    });

    this.uppy.on("upload-success", (file, response) => {
      this.handleUploadSuccess(file, response);
    });

    this.uppy.on("complete", (result) => {
      self.uppy.cancelAll({ reason: "user" });
    });
  }

  handleUploadSuccess(file, response) {
    this.setFileSending(file.id);
    if (this.mutexPool) {
      this.mutexPool
        .pickMutex("upload")
        .acquire()
        .then((releaseFunc) => {
          this.sendFileMeta(file, file.id, releaseFunc);
        });
    } else {
      this.sendFileMeta(file, file.id);
    }
  }

  sendFileMeta(file, id, releaseFunc) {
    let params = {
      files: [
        {
          id: file.s3Multipart["key"].match(/^cache\/(.+)/)[1], // remove the Shrine storage prefix
          storage: "pbc_cache",
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        },
      ],
    };

    this.apiHandler.create(params, id, releaseFunc);
  }
}

export default Upload;
