<script>
  import { onMount } from "svelte";

  import {
    AdminstrationSection,
    ThemeAccountColorList,
    ThemeAccountFavicon,
    ThemeAccountLogo,
  } from "apps/administration/components";
  import { ADMIN_COMPONENT_KEY } from "apps/administration/constants";

  import { navigationEffectsService } from "apps/administration/services";

  import {
    account,
    accountTheme,
    accountRemoveLogo,
    accountRemoveFavicon,
  } from "stores/account.js";
  import { t } from "stores/i18n.js";

  import AccountApi from "apis/admin/accounts.js";

  import { Button } from "components";
  import styles from "styleguide/AdministrationTheme.json";

  onMount(() => {
    navigationEffectsService.setBreadcrumbs(ADMIN_COMPONENT_KEY.THEME);
    navigationEffectsService.updateHistory(ADMIN_COMPONENT_KEY.THEME);
    AccountApi.getTheme();
  });

  function resetTheme() {
    const response = confirm($t("administration_theme.reset.confirm"));
    if (!response) {
      return;
    }

    AccountApi.resetTheme();
  }

  function saveTheme() {
    const response = confirm($t("administration_theme.save.confirm"));
    if (!response) {
      return;
    }
    AccountApi.updateTheme({
      theme: $accountTheme,
      logo: $account.logo,
      remove_logo: $accountRemoveLogo,
      favicon: $account.favicon,
      remove_favicon: $accountRemoveFavicon,
    });
  }
</script>

<div data-component="AdministrationTheme">
  <div class={styles.content}>
    <p class={styles.lead}>
      {$t(`administration_theme.intro`)}
    </p>
    {#if $accountTheme}
      <AdminstrationSection
        title={$t(`administration_theme.buttons.title`)}
        description={$t(`administration_theme.buttons.description`)}
        style="border"
      >
        <ThemeAccountColorList section="brand" />
        <h4>{$t(`administration_theme.buttons.examples`)}</h4>
        <div class={styles.examples}>
          <Button style="primary-tonal">Secondary Button</Button>
          <Button style="primary">Primary Button</Button>
        </div>
      </AdminstrationSection>

      <AdminstrationSection
        title={$t(`administration_theme.header.title`)}
        description={$t(`administration_theme.header.description`)}
        style="border"
      >
        <ThemeAccountLogo />
        <ThemeAccountFavicon />
        <ThemeAccountColorList section="header" />
      </AdminstrationSection>

      <AdminstrationSection
        title={$t(`administration_theme.breadcrumb.title`)}
        description={$t(`administration_theme.breadcrumb.description`)}
      >
        <ThemeAccountColorList section="breadcrumb" />
      </AdminstrationSection>

      <div class={styles.buttons}>
        <Button click={saveTheme} style="primary">
          {$t("administration_theme.save.label")}
        </Button>
        <Button click={resetTheme} style="error-text">
          {$t("administration_theme.reset.label")}
        </Button>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .content {
    padding: 0px 24px 24px 24px;
    background: var(--white);
    box-shadow:
      0px 6px 8px -6px rgba(24, 39, 75, 0.12),
      0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
  }
  .lead {
    padding-top: 2em;
    margin: 0;
  }

  .buttons {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  h4 {
    margin: 0;
  }
  .examples {
    display: flex;
    gap: 16px;
  }
</style>
