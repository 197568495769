<script>
  import { onDestroy, onMount } from "svelte";
  import clsx from "clsx";
  import { Notification } from "components/notification";
  import { PageHistory } from "components/pageHistory";
  import { isAccountFeature } from "stores/account";
  import { user, isUserTeamUser } from "stores/user.js";

  import { Breadcrumb } from "components";
  import { QuickshareButton } from "./components";
  import styles from "styleguide/NavigationBar.json";

  let isSticky = false;
  let style = "";

  onMount(async () => {
    window.addEventListener("scroll", handleScroll);
  });

  onDestroy(() => {
    window.removeEventListener("scroll", handleScroll);
  });

  function handleScroll() {
    isSticky = window.scrollY >= 80;
  }
</script>

<div
  data-svelte-component="NavigationBar"
  class={clsx(
    styles["navigation-bar"],
    style.split(" ").map((x) => styles[x]),
    { [styles["is-sticky"]]: isSticky },
  )}
>
  <div class={styles["breadcrumbs-wrapper"]}>
    <PageHistory />
    <Breadcrumb />
  </div>

  {#if $user && $user.role}
    <div class={styles.actions}>
      {#if $isAccountFeature("quickshare") && $isUserTeamUser}
        <QuickshareButton />
      {/if}
      <Notification />
    </div>
  {/if}
</div>

<style lang="scss">
  .navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--breadcrumb-background-color);
    padding: var(--spacing-8) var(--spacing-40);

    &.is-sticky {
      @include box-shadow(header-bar);
    }
  }

  .actions {
    display: flex;
    gap: var(--spacing-8);
  }

  .breadcrumbs-wrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
  }
</style>
