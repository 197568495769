<script>
  import MinusIcon from "components/icons/MinusIcon.svelte";
  import PlusIcon from "components/icons/PlusIcon.svelte";

  import { t } from "stores/i18n.js";

  import { Button } from "components";

  import styles from "styleguide/NumberInput.json";

  export let value = 0;
  export let minValue = 0;
  export let maxValue = Infinity;
  export let label = "";
  export let errors = [];
  export let onChange = () => {};

  $: innerErrors = [];
  $: allErrors = [...errors, ...innerErrors];

  function changeValue(newValue) {
    innerErrors = [];

    if (newValue === null) {
      return;
    }

    checkInnerErrors(newValue);

    const clampedValue = Math.max(minValue, Math.min(maxValue, newValue));
    value = clampedValue;
    onChange(clampedValue);
  }

  function checkInnerErrors(newValue) {
    if (newValue > maxValue) {
      innerErrors.push([
        $t("number_input.max_value_error", {
          postProcess: "interval",
          count: maxValue,
        }),
      ]);
    }

    if (newValue < minValue) {
      innerErrors.push([
        $t("number_input.min_value_error", {
          postProcess: "interval",
          count: minValue,
        }),
      ]);
    }
  }

  function handleInput(e) {
    const rawValue = e.target.value;
    const normalizedValue = rawValue === "" ? null : Number(rawValue);
    e.target.value = normalizedValue;
    changeValue(normalizedValue);
  }

  function handleBlur() {
    if (value === null) changeValue(0);
  }
</script>

<div class={styles.wrapper} data-component="NumberInput">
  <div class={styles.label}>{label}</div>

  <div class={styles["input-wrapper"]}>
    <Button
      click={() => changeValue(value - 1)}
      style={"icon primary-text"}
      disabled={value <= minValue}
    >
      <MinusIcon />
    </Button>

    <input
      type="number"
      class={styles.input}
      bind:value
      on:input={handleInput}
      on:blur={handleBlur}
    />

    <Button
      click={() => changeValue(value + 1)}
      style={"icon primary-text"}
      disabled={value >= maxValue}
    >
      <PlusIcon />
    </Button>
  </div>

  {#if allErrors?.length}
    <div class={styles.errors}>
      {allErrors.concat()}
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .input-wrapper {
    height: 40px;
    display: flex;
    gap: 4px;
  }

  .input::-webkit-inner-spin-button,
  .input::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  .input {
    @include typography-body-regular;

    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    background: var(--white);
    appearance: textfield;
    padding: 0 8px;
    text-align: center;
    width: 60px;

    &:focus {
      border-color: var(--blue-200);
    }
  }

  .label {
    @include typography-body-medium;

    color: var(--primary-500);
  }

  .errors {
    @include typography-footnote-regular;

    color: var(--red-200);
  }
</style>
