import { ADMIN_COMPONENT_KEY } from "apps/administration/constants";
import { currentAdminPage } from "apps/administration/stores";
import { breadcrumb } from "stores/breadcrumb.js";
import { updatePageHistory } from "stores/page_history.js";

import { platformLink, userPath } from "lib/helpers.js";

class NavigationEffectsService {
  constructor() {}

  setBreadcrumbs(key) {
    breadcrumb.set([
      {
        translation: "administration",
        click: () => {
          currentAdminPage.set(ADMIN_COMPONENT_KEY.DASHBOARD);
          window.location.href = platformLink("account");
        },
      },
      { translation: key },
    ]);
  }

  updateHistory(path) {
    updatePageHistory([
      {
        path: `${userPath}/account/${path}`,
        titles: [{ translation: "administration" }, { translation: path }],
      },
    ]);
  }

  static instance;

  static getInstance() {
    if (!NavigationEffectsService.instance) {
      NavigationEffectsService.instance = new NavigationEffectsService();
    }
    return NavigationEffectsService.instance;
  }
}

export const navigationEffectsService = NavigationEffectsService.getInstance();
