import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class CompliancePackageApi {
  constructor() {
    this.endpoint = "/compliance_package";
  }

  getCompliancePackage(options) {
    const { success, error } = options;

    Api.get(this.endpoint, {
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  updateCompliancePackage(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.put(this.endpoint, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  static instance;

  static getInstance() {
    if (!CompliancePackageApi.instance) {
      CompliancePackageApi.instance = new CompliancePackageApi();
    }
    return CompliancePackageApi.instance;
  }
}

export const compliancePackageApi = CompliancePackageApi.getInstance();
