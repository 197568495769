<script>
  import { onMount } from "svelte";

  import { Modal } from "components/modals";
  import { currentTeam } from "stores/client_store.js";
  import { t } from "stores/i18n.js";
  import {
    getModalContentByKey,
    discardModalsContent,
    isDirtyState,
  } from "stores/modals";
  import { toasts } from "stores/toasts.js";
  import { get } from "svelte/store";

  import TeamsApi from "apis/clients/teams_api.js";

  import { Button, TextArea, TextInput, UserSelector } from "components";

  import styles from "styleguide/AdministrationTeamsModal.json";

  export let clientUsers;
  export let showAdministrationTeamsModal;

  let storageModalKey = "administration-teams-modal";
  let errors = [];

  $: storageModalContent = $currentTeam;
  $: hasUnsavedChanges = !$currentTeam?.permalink && isDirtyState($currentTeam);

  onMount(() => {
    const content = getModalContentByKey(storageModalKey);

    if (content !== undefined && !$currentTeam.permalink) {
      currentTeam.set(content);
    }
  });

  function onUpdateUsers(data) {
    let ct = $currentTeam;
    ct.client_user_ids = data;
    currentTeam.set(ct);
  }

  function onCreate() {
    TeamsApi.create({
      params: $currentTeam,
      success: () => {
        TeamsApi.getAll();
        toasts.send({
          message: get(t)("administration_teams_modal.toasts.created"),
          type: "success",
        });
        currentTeam.set(null);
        discardModalsContent(storageModalKey);
        showAdministrationTeamsModal = false;
      },
      error: (response) => {
        errors = response.error;
        toasts.send({
          message: get(t)("administration_teams_modal.toasts.error"),
          type: "error",
        });
      },
    });
  }

  function onUpdate() {
    TeamsApi.update({
      params: $currentTeam,
      success: () => {
        TeamsApi.getAll();
        toasts.send({
          message: get(t)("administration_teams_modal.toasts.updated"),
          type: "success",
        });
        currentTeam.set(null);
        showAdministrationTeamsModal = false;
      },
      error: () => {
        toasts.send({
          message: get(t)("administration_teams_modal.toasts.error"),
          type: "error",
        });
      },
    });
  }

  function onDelete() {
    if (confirm($t("administration_teams_modal.confirm_delete"))) {
      TeamsApi.delete({
        params: $currentTeam,
        success: () => {
          TeamsApi.getAll();
          toasts.send({
            message: get(t)("administration_teams_modal.toasts.deleted"),
            type: "success",
          });
          currentTeam.set(null);
          discardModalsContent(storageModalKey);
          showAdministrationTeamsModal = false;
        },
      });
    }
  }

  function onClose() {
    currentTeam.set(null);
    showAdministrationTeamsModal = false;
  }
</script>

<Modal
  isFullHeight={true}
  isScrollingInsideModal={false}
  bind:shown={showAdministrationTeamsModal}
  bind:hasUnsavedChanges
  style={"no-padding"}
  onHide={onClose}
  {storageModalKey}
  {storageModalContent}
  title={$currentTeam?.permalink
    ? $t("administration_teams_modal.title.update")
    : $t("administration_teams_modal.title.create")}
>
  <div data-component="AdministrationTeamsModal" class={styles.wrapper}>
    <div class={styles.form}>
      <TextInput
        bind:value={$currentTeam.name}
        label={$t("administration_teams_modal.name.label")}
        footnotes={[$t("administration_teams_modal.name.help")]}
        errors={errors.name || []}
      />
      <TextArea
        bind:value={$currentTeam.description}
        label={$t("administration_teams_modal.description.label")}
        footnotes={[$t("administration_teams_modal.description.help")]}
        optional
        errors={errors.description || []}
      />
    </div>
    <UserSelector
      label={$t("administration_teams_modal.members.label")}
      users={clientUsers}
      mode="users"
      {onUpdateUsers}
      selectedUsers={$currentTeam.client_user_ids}
    />
  </div>
  <svelte:fragment slot="bottom">
    {#if $currentTeam?.permalink}
      <div class={styles.buttons}>
        <Button click={onUpdate} style={"primary"}
          >{$t("administration_teams_modal.button.update")}</Button
        >
        <Button click={onDelete} style={"error-text"}
          >{$t("administration_teams_modal.button.delete")}</Button
        >
      </div>
    {:else}
      <Button click={onCreate} style={"primary"}
        >{$t("administration_teams_modal.button.create")}</Button
      >
    {/if}
  </svelte:fragment>
</Modal>

<style scoped lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 20px;
  }

  .header {
    display: flex;
    padding: 0 24px;
    justify-content: space-between;
    align-items: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0 24px;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
</style>
