import { notifications, notificationsCount } from "stores/notification.js";
import { get } from "svelte/store";
import Api from "apis/api";

class NotificationApi {
  constructor() {
    this.endpoint = "/notifications";
  }

  getData() {
    Api.get(this.endpoint, {
      success: (response) => {
        notifications.set(response.data.notifications);
      },
    });
  }

  clearAll() {
    Api.patch(`${this.endpoint}/clear`, {
      success: () => {
        notifications.set([]);
        notificationsCount.set(0);
      },
    });
  }

  clear(id) {
    Api.patch(`${this.endpoint}/${id}/read`, {
      success: () => {
        notifications.set(
          get(notifications).filter((item) => item.permalink !== id),
        );
        const count = get(notificationsCount);
        if (count > 0) {
          notificationsCount.set(count - 1);
        }
      },
    });
  }
}

export default new NotificationApi();
