<script>
  import { onMount } from "svelte";
  import {
    SampleDetails,
    SampleFooter,
    SampleMailing,
    SamplePreviewsBlank,
    SampleResponse,
    SampleSidebarOverview,
  } from "apps/sample/components";
  import { SAMPLE_TYPE } from "apps/sample/constants";
  import { sample, entries } from "apps/sample/stores";

  import { isUserTeamUser } from "stores/user";
  import { initializeNavigation } from "shared/helpers";

  import { App, ContentSidebar, Header } from "components";

  import styles from "styleguide/Sample.json";

  export let appConfig;
  export let initialState;

  onMount(() => {
    sample.set(initialState.sample);

    initializeNavigation({
      state: initialState.sample,
      path: initialState.sample.sample_path,
      type: "review",
    });
  });
</script>

<App {...appConfig}>
  <div class={styles.wrapper}>
    <ContentSidebar>
      <div slot="content" data-component="Sample">
        <Header
          type={$sample.sample_type}
          headline={$sample.contact.company || `# ${$sample.reference}`}
          client_name={$sample.client_name}
          client_official_name={$sample.client_official_name}
        />

        <SampleDetails />

        <div class={styles.entries}>
          {#if $entries.length > 0}
            {#each $entries as entry}
              {#if entry.type === SAMPLE_TYPE.mailing}
                <SampleMailing mailing={entry} />
              {:else if entry.type === SAMPLE_TYPE.response}
                <SampleResponse response={entry} />
              {/if}
            {/each}
          {:else}
            <SamplePreviewsBlank />
          {/if}
        </div>

        {#if $isUserTeamUser}
          <SampleFooter />
        {/if}
      </div>

      <div slot="sidebar" data-component="SampleIndex">
        <SampleSidebarOverview />
      </div>
    </ContentSidebar>
  </div>
</App>

<style global lang="scss">
  .wrapper {
    margin-bottom: 180px;
  }
  .entries {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1.5rem;
  }
</style>
