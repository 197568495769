<script>
  export let loading = true;

  import styles from "styleguide/SkeletonLegacy.json";
</script>

{#if loading}
  <div data-component="SkeletonLegacy" class={styles.skeleton} />
{:else}
  <slot />
{/if}

<style scoped lang="scss">
  .skeleton {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -80%;
      /* left: 50%; */
      top: 0;
      height: 100%;
      width: 80%;
      background: linear-gradient(
        to right,
        transparent 0%,
        var(--primary-010) 50%,
        transparent 100%
      );
      animation: skeleton 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      /* animation: none; */
    }
  }

  @keyframes skeleton {
    from {
      left: -80%;
    }
    to {
      left: 100%;
    }
  }
</style>
