<script>
  import { t } from "stores/i18n";

  import { platformLink } from "lib/helpers.js";

  import { LegacyButton } from "components";

  import styles from "styleguide/QuickshareButton.json";
</script>

<div data-component="QuickshareButton" class={styles["quickshare-container"]}>
  <LegacyButton link={platformLink("quickshare")} style={"breadcrumb-button"}>
    Quickshare
  </LegacyButton>
  <span class={styles.flag}>{$t("quickshare_button.version")}</span>
</div>

<style lang="scss">
  .quickshare-container {
    position: relative;
    display: inline-block;
  }

  .flag {
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--green);
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 0px 6px;
    white-space: nowrap;
  }
  .flag::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid var(--green);
  }
</style>
