<script>
  import { t } from "stores/i18n.js";

  import { Boundary, Box, Spinner, Switch, Toast, Tooltip } from "components";

  import { getTableHeaders } from "../../helpers";
  import { quickshareService } from "../../services";
  import { usersList, usersListLoading } from "../../stores";

  import styles from "styleguide/QuickhareAdminUsersTable.json";

  const tableHeaders = getTableHeaders();

  function handleSwitch(isEnabled, id) {
    const params = {
      id,
      isEnabled,
    };
    quickshareService.updateUser({
      params,
    });
  }
</script>

<Boundary>
  <div data-component="QuickhareAdminUsersTable">
    {#if $usersListLoading}
      <Box>
        <div class={styles.spinner}>
          <Spinner scale="0.25" color="var(--primary-100)" />
        </div>
      </Box>
    {:else if $usersList?.items?.length}
      <Box>
        <div class={styles.wrapper}>
          <div class={styles.title}>
            {$t("quickshare_admin.title", {
              postProcess: "interval",
              seatsLimit: $usersList.quickshare_seats_limit,
              seatsCount: $usersList.quickshare_seats_count,
            })}
          </div>

          <table>
            <thead>
              <tr class={styles.row}>
                {#each tableHeaders as header (header)}
                  <th style={`width:${header.width}%`}>
                    {header.label}
                  </th>
                {/each}
              </tr>
            </thead>
            <tbody>
              {#each $usersList.items as item (item.id)}
                <tr class={styles.row}>
                  <td>
                    <Switch
                      toggled={item.quickshare_seat}
                      toggle={(val) => handleSwitch(val, item.id)}
                    />
                  </td>

                  <td>
                    <Tooltip message={item.text} contentWidth={"100%"}>
                      <span class={styles.info}>{item.text}</span>
                    </Tooltip>
                  </td>

                  <td>
                    <Tooltip message={item.email} contentWidth={"100%"}>
                      <span class={styles.info}>{item.email}</span>
                    </Tooltip>
                  </td>

                  <td>{item.role}</td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </Box>
    {:else}
      <Toast message={$t("statuses.nothing_found")} type="warning" />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
  }

  .title {
    @include typography-caption-semibold;

    color: var(--primary-500);
    padding: 24px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    margin: 0;
  }

  .row {
    height: 54px;

    &:not(:first-of-type) {
      td {
        border-top: 1px solid var(--primary-050);
      }
    }
  }

  th {
    border-bottom: 1px solid var(--primary-050);
  }

  td,
  th {
    text-align: left;
    padding: 10px 24px;
    vertical-align: middle;
  }

  td {
    @include overflow-protection;

    border-top: 0;
  }

  .info {
    @include overflow-protection;
  }

  .spinner {
    height: 100px;
    padding: 24px;
  }
</style>
