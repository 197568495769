<script>
  import { userInfo } from "apps/quickshare/stores";
  import { isAccountFeature } from "stores/account";

  import { Boundary } from "components";

  import {
    QuickshareSidebarStorageInfo,
    QuickshareSidebarTabs,
    QuickshareSidebarInfo,
    QuickshareSidebarOutlookInfo,
  } from "./components";

  import styles from "styleguide/QuickshareSidebar.json";

  $: setting = $userInfo?.setting || {};
  $: isNewLinksAllowed =
    setting.isSeatActivated ||
    (!setting.isSeatActivated &&
      setting.monthlyLinksCount < setting.monthlyLinksLimit);
</script>

<Boundary>
  <div data-component="QuickshareSidebar" class={styles.container}>
    {#if isNewLinksAllowed}
      <QuickshareSidebarTabs />
    {/if}
    <div>
      {#if !$userInfo?.setting.isSeatActivated}
        <QuickshareSidebarInfo />
      {/if}

      {#if $isAccountFeature("quickshare_outlook_infobox")}
        <QuickshareSidebarOutlookInfo />
      {/if}
      <QuickshareSidebarStorageInfo />
    </div>
  </div>
</Boundary>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
</style>
