<script>
  import { onMount } from "svelte";

  import {
    RespondCounterparties,
    RespondDescription,
    RespondQrcode,
    RespondSteps,
    RespondSuccess,
  } from "apps/respond";
  import {
    pin,
    samples,
    sender,
    recipient,
    auditor,
    setReviewType,
    footerLinks,
  } from "stores/responder.js";

  import { App, FrontendWrapper } from "components";
  import styles from "styleguide/Respond.json";

  export let appConfig;
  export let initialState;

  $: status = "init";

  onMount(async () => {
    samples.set(initialState.samples);
    setReviewType(initialState.review_type, initialState.category);
    footerLinks.set(initialState.footer_links);

    console.log("initialState.review_type", initialState.review_type);

    if (initialState.mailing_info) {
      pin.set(initialState.mailing_info.pin);
      sender.set({ name: initialState.mailing_info.sender });
      recipient.set({ name: initialState.mailing_info.recipient });
      auditor.set({ name: initialState.mailing_info.auditor });
    }

    status = initialState.status || ($pin ? "upload" : "init");
  });

  function setStatus(newStatus) {
    status = newStatus;
  }
</script>

<App {...appConfig} container={true}>
  <FrontendWrapper {footerLinks}>
    <div class={styles.container}>
      {#if status === "init"}
        <div>
          <RespondDescription />
        </div>
        <div>
          <RespondQrcode {setStatus} />
        </div>
      {:else if status === "upload"}
        <div>
          <RespondCounterparties />
        </div>
        <div>
          <RespondSteps {setStatus} />
        </div>
      {:else if status === "success"}
        <div>
          <RespondCounterparties />
        </div>
        <div>
          <RespondSuccess />
        </div>
      {/if}
    </div>
  </FrontendWrapper>
</App>

<style lang="scss">
  .container > div {
    padding: 0 35px;

    &:not(:last-child) {
      border-bottom: solid 1px var(--primary-050);
    }
  }
</style>
