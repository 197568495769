<script>
  import { getContext } from "svelte";
  import { ControlStatus } from "apps/pbc";
  import { t } from "stores/i18n.js";
  import { diffDaysNumberToHuman } from "lib/helpers.js";
  import { Tag } from "components";

  import styles from "styleguide/ControlTags.json";

  export let status;
  export let dueOn;
  export let thisWeek;
  export let newComments;
  export let newDocuments;
  export let documentsCount;
  export let createdByClient;

  const { language } = getContext("config");

  $: inDays = diffDaysNumberToHuman(language, dueOn);
  $: inDaysColor = diffDaysColor(status, thisWeek);
  $: documents =
    documentsCount === 1
      ? $t("tags.document.one") &&
        $t("tags.document.one").replace("%{count}", documentsCount)
      : $t("tags.document.other") &&
        $t("tags.document.other").replace("%{count}", documentsCount);

  function diffDaysColor(status, thisWeek) {
    if (thisWeek) {
      return "orange";
    } else if (status === "overdue") {
      return "red";
    } else {
      return "grey";
    }
  }
</script>

<span data-component="ControlTags" class={styles.wrapper}>
  <ControlStatus {status} />

  {#if dueOn && status !== "accepted"}
    <Tag style="semi-bold" color={inDaysColor} label={inDays} size="small" />
  {/if}

  {#if documentsCount}
    <Tag style="semi-bold" color="grey" label={documents} size="small" />
  {/if}

  {#if newComments}
    <Tag
      style="semi-bold"
      color="blue"
      label={$t("control_status.new-comments")}
      size="small"
    />
  {/if}

  {#if newDocuments}
    <Tag
      style="semi-bold"
      color="blue"
      label={$t("control_status.new-documents")}
      size="small"
    />
  {/if}

  {#if createdByClient}
    <Tag
      style="semi-bold"
      color="grey"
      label={$t("control_status.created_by_client")}
      size="small"
    />
  {/if}
</span>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
</style>
