<script>
  import { onMount } from "svelte";
  import clsx from "clsx";
  import { t } from "stores/i18n.js";
  import { createPopperActions } from "svelte-popperjs";

  import { dateTimeFormat } from "lib/helpers.js";

  import { DatePicker, TextInput, Popover } from "components";

  export let min = undefined;
  export let max = undefined;
  export let value = null;
  export let deleteText = null;
  export let onSelect = null;
  export let show = false;
  export let required = false;
  export let dirty = false;
  export let lock = false;
  export let label = undefined;
  export let errors = undefined;
  export let optional = undefined;
  export let footnotes = undefined;

  let input;
  let inputValue = "";
  let initialInputValue;

  $: {
    if (input !== document.activeElement) {
      inputValue = getDateTimeFormat(value);
    }
  }

  function getDateTimeFormat(value) {
    return value
      ? dateTimeFormat(value, "de", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "";
  }

  function getValue(result) {
    return result.split("-").reverse().join("-");
  }

  function validateResult(inputValue) {
    let result = inputValue.replaceAll(".", "-").replaceAll("/", "-");
    return result.match(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[0-2])-\d{4}$/)
      ? result
      : false;
  }

  function handlerInputKeydown() {
    setTimeout(() => {
      let result = validateResult(input.value);

      if (result) {
        value = getValue(result);
      }
    }, 100);
  }

  function handleSelect(data) {
    errors = [];
    show = false;

    if (initialInputValue === data) {
      return;
    }

    if (data) {
      if (onSelect) onSelect(data);
      value = data;
      initialInputValue = data;
    } else {
      errors = [$t("date_input.invalid_format")];
    }
  }

  onMount(() => {
    initialInputValue = getDateTimeFormat(value);
  });
</script>

<div data-component="DateInput">
  <Popover
    bind:open={show}
    placement={"bottom"}
    toggleRole={"grid"}
    showCloseButton={false}
    offsetPosition={[-146, 8]}
    style={"no-padding no-arrow"}
  >
    <TextInput
      enterIcon={"calendar"}
      style={"border medium round-border"}
      {lock}
      {label}
      {optional}
      {footnotes}
      maxlength={10}
      bind:input
      value={inputValue}
      onKeydown={handlerInputKeydown}
      placeholder={$t("date_input.placeholder")}
      errors={errors || (dirty && required ? [$t("validation.required")] : [])}
    />

    <div slot="content">
      <DatePicker
        {min}
        {max}
        {deleteText}
        bind:value
        onSelect={(data) => handleSelect(data)}
        onRemove={() => handleSelect("")}
      />
    </div>
  </Popover>
</div>
