import { t } from "stores/i18n.js";
import { get } from "svelte/store";

import { LinkType } from "../../../constants";

export const getSidebarInfoData = ({
  type,
  owner,
  downloadLimit,
  daysUntilExpire,
}) => {
  const displayDownloadLimit = downloadLimit
    ? get(t)("quickshare_frontend_landing_sidebar.limited")
    : get(t)("quickshare_frontend_landing_sidebar.unlimited");

  switch (type) {
    case LinkType.REQUEST: {
      return [
        {
          icon: "user",
          title: get(t)("quickshare_frontend_landing_sidebar.requested_by"),
          content: [
            {
              info: owner.name,
              link: "",
            },
            {
              info: owner.email,
              link: `mailto:${owner.email}`,
            },
            {
              info: owner.company,
              link: "",
            },
          ],
        },
        ...(daysUntilExpire
          ? [
              {
                icon: "clock",
                title: get(t)("quickshare_frontend_landing_sidebar.expires", {
                  postProcess: "interval",
                  count: daysUntilExpire,
                }),
                content: [
                  {
                    info: get(t)("quickshare_frontend_landing_sidebar.days", {
                      postProcess: "interval",
                      count: daysUntilExpire,
                    }),
                    link: "",
                  },
                ],
              },
            ]
          : []),
      ];
    }

    case LinkType.SHARE: {
      return [
        {
          icon: "user",
          title: get(t)("quickshare_frontend_landing_sidebar.shared_by"),
          content: [
            {
              info: owner.name,
              link: "",
            },
            {
              info: owner.email,
              link: `mailto:${owner.email}`,
            },
            {
              info: owner.company,
              link: "",
            },
          ],
        },
        {
          icon: "clock",
          title: get(t)("quickshare_frontend_landing_sidebar.expires", {
            postProcess: "interval",
            count: daysUntilExpire,
          }),
          content: [
            {
              info: get(t)("quickshare_frontend_landing_sidebar.days", {
                postProcess: "interval",
                count: daysUntilExpire,
              }),
              link: "",
            },
          ],
        },
        {
          icon: "download",
          title: get(t)("quickshare_frontend_landing_sidebar.download_limit"),
          content: [
            {
              info: displayDownloadLimit,
              link: "",
            },
          ],
        },
      ];
    }

    default: {
      return [
        {
          icon: "user",
          title: get(t)("quickshare_frontend_landing_sidebar.shared_by"),
          content: [
            {
              info: owner.name,
              link: "",
            },
            {
              info: owner.email,
              link: `mailto:${owner.email}`,
            },
            {
              info: owner.company,
              link: "",
            },
          ],
        },
        {
          icon: "clock",
          title: get(t)("quickshare_frontend_landing_sidebar.expires", {
            postProcess: "interval",
            count: daysUntilExpire,
          }),
          content: [
            {
              info: get(t)("quickshare_frontend_landing_sidebar.days", {
                postProcess: "interval",
                count: daysUntilExpire,
              }),
              link: "",
            },
          ],
        },
        {
          icon: "download",
          title: get(t)("quickshare_frontend_landing_sidebar.download_limit"),
          content: [
            {
              info: displayDownloadLimit,
              link: "",
            },
          ],
        },
        {
          icon: "twoWayShare",
          title: get(t)(
            "quickshare_frontend_landing_sidebar.two_way_file_sharing",
          ),
          content: [
            {
              info: get(t)("quickshare_frontend_landing_sidebar.enabled"),
              link: "",
            },
          ],
        },
      ];
    }
  }
};
