<script>
  import { onDestroy } from "svelte";
  import { t } from "stores/i18n.js";
  import { activeTemplate } from "stores/pbc.js";
  import AuditTemplateApi from "apis/pbc/audit_templates.js";
  import AuditTemplateFilterGroup from "./AuditTemplateFilterGroup.svelte";

  import styles from "styleguide/AuditTemplateSidebar.json";

  export let favoritesFilters;
  export let customFilters;
  export let systemFilters;
  export let activeFilter;
  export let onFilterChange;

  const unsubscribe = activeTemplate.subscribe((value) => {
    if (value === "manage" || value === "overwrite") {
      AuditTemplateApi.get(value);
    }
  });

  onDestroy(unsubscribe);
</script>

<div data-component="AuditTemplateSidebar" class={styles.wrapper}>
  {#if favoritesFilters?.length}
    <AuditTemplateFilterGroup
      filters={favoritesFilters}
      {activeFilter}
      {onFilterChange}
    />
  {/if}

  {#if customFilters?.length}
    <AuditTemplateFilterGroup
      filters={customFilters}
      titleKey={$t("audit_template.custom_templates")}
      {activeFilter}
      {onFilterChange}
    />
  {/if}

  {#if systemFilters?.length}
    <AuditTemplateFilterGroup
      filters={systemFilters}
      titleKey={$t("audit_template.system_templates")}
      {activeFilter}
      {onFilterChange}
    />
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
</style>
