<script>
  import { onMount, onDestroy } from "svelte";
  import { ControlUploadDropdown, ControlUploadItem } from "apps/pbc";
  import MutexPool from "general/mutex_pool.js";
  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";
  import {
    addedFilesControl,
    setFilesToControl,
    addFilesToControl,
    getAddedFilesControl,
    activeDownloadUnsafe,
    activeControlPermalink,
    allowedFileTypes,
  } from "stores/pbc.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { user } from "stores/user.js";

  import AttachmentUploadHandler from "apis/attachments/attachment_upload_handler.js";
  import AttachmentsApi from "apis/attachments/attachments.js";
  import ControlApi from "apis/pbc/controls.js";
  import UploadApiConstructor from "apis/upload.js";
  import { dateTimeFormat, downloadFile, isReviewed } from "lib/helpers.js";

  import { Boundary, Button, Dropzone, IconButton, Options } from "components";
  import styles from "styleguide/ControlUpload.json";

  export let parent_id;
  export let showDownloadButton = false;

  let filesByDay = [];
  let dropzone;
  let parent_type = "control";
  let uppyWrapper;
  let loading = true;
  let mutexPool = new MutexPool(2, ["upload", "virus"]);
  let UploadApi = new UploadApiConstructor();
  let filesInProgressCount = 0;

  const unsubscribeActiveControlPermalink = activeControlPermalink.subscribe(
    (value) => {
      if (value) {
        parent_id = value;
        getAttachments(parent_id, parent_type);

        UploadApi.onFileAdded = onFileAdded;
        UploadApi.setFileSending = setFileSending;
        UploadApi.setFileProgress = setFileProgress;
        UploadApi.apiHandler = AttachmentUploadHandler;
        UploadApi.mutexPool = mutexPool;
        AttachmentUploadHandler.parent_id = parent_id;
        AttachmentUploadHandler.parent_type = parent_type;
        AttachmentUploadHandler.onFileUpload = onFileUpload;
        AttachmentUploadHandler.onVirusesScanSuccess = onVirusesScanSuccess;
        AttachmentUploadHandler.mutexPool = mutexPool;
      }
    },
  );

  const unsubscribeAddedFilesControl =
    addedFilesControl.subscribe(combineFilesByName);

  function combineFilesByName(value) {
    let newFiles = [];

    let newArchiveGroup = {
      ids: [],
      files: [],
      createDay: $t("control_upload.archived"),
      archived: true,
    };

    value.forEach((item) => {
      let filename = item.filename ? item.filename : item.name;
      let group = newFiles.find((group) => group.filename === filename);

      if (item.archived) {
        newArchiveGroup.files.push({ files: [item] });
        newArchiveGroup.ids.push(item.permalink);
      } else if (group) {
        group.files.push(item);
        group.ids.push(item.permalink);

        if (new Date(group.created_at) < new Date(item.created_at)) {
          group.created_at = item.created_at;
        }
      } else {
        let newGroup = {
          filename,
          created_at: item.created_at,
          files: [item],
          ids: [item.permalink],
        };

        newFiles.push(newGroup);
      }
    });

    newFiles = newFiles.map((item) => {
      item.files = item.files.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      return item;
    });

    newFiles = newFiles.map((item) => {
      if (item.files.length > 1) {
        item.files = item.files.map((file, index, array) => {
          let filename = file.filename ? file.filename : file.name;

          if (index === 0) {
            file.versionName = `${filename} (${$t("tags.version.newest")})`;
            file.versionsName = `${filename} (${$t("tags.version.versions").replace("%{count}", array.length)})`;
          } else {
            file.versionsName = undefined;
            file.versionName = `${filename} ${$t("tags.version.latest")} (${dateTimeFormat(file.created_at)})`;
          }

          return file;
        });
        return item;
      } else {
        return item;
      }
    });

    newFiles = newFiles.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    combineFilesByDate(newFiles, newArchiveGroup);
  }

  function combineFilesByDate(value, newArchiveGroup) {
    let newFilesByDay = [];
    let today = dateTimeFormat(new Date().toISOString()).split(",")[0];

    value.forEach((item) => {
      let createDay = dateTimeFormat(item.created_at).split(",")[0];

      if (createDay === today) {
        createDay = $t("control_upload.today");
      }

      let group = newFilesByDay.find((group) => group.createDay === createDay);

      if (group) {
        group.files.push(item);
        group.ids = [...group.ids, ...item.ids];
      } else {
        let newGroup = {
          createDay,
          created_at: item.created_at,
          files: [item],
          ids: item.ids,
        };

        newFilesByDay.push(newGroup);
      }
    });

    newFilesByDay = newFilesByDay.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    if (newArchiveGroup.ids.length) {
      newFilesByDay.push(newArchiveGroup);
    }

    newFilesByDay.map((item) => {
      let checked = true;

      item.files.map((group) => {
        group.files.map((file) => {
          const reviewed = isReviewed(file, $user.role);
          if (!reviewed) checked = reviewed;
        });
      });

      item.checked = checked;
    });

    filesByDay = newFilesByDay;
  }

  function addFiles(files) {
    UploadApi.onAddFiles([...files]);
  }

  function onFileAdded(file) {
    filesInProgressCount++;
    addFilesToControl([file], parent_id);
  }

  function onVirusesScanSuccess(permalink, virus) {
    const newFilesArray = getAddedFilesControl(parent_id).map((item) => {
      if (item.permalink === permalink) {
        item.virus_scanning = false;
        item.virus = virus;
      }

      return item;
    });

    setFilesToControl(newFilesArray, parent_id);
  }

  function onDownload(file, start, success) {
    if (file.virus) {
      activeDownloadUnsafe.set({
        onDownload: () => {
          download(file.permalink, start, success);
        },
      });
    } else {
      download(file.permalink, start, success);
    }
  }

  function download(id, start, success) {
    if (start) start();

    AttachmentsApi.getById({
      params: {
        id,
        parent_id,
        parent_type,
      },
      success: (file) => {
        downloadFile(file, success);
      },
    });
  }

  function onDownloadBatch(attachment_ids) {
    ControlApi.downloadBatchAttachment({
      params: {
        id: parent_id,
        attachment_ids,
      },
    });
  }

  function onDownloadAll() {
    if ($addedFilesControl.some((file) => file.virus)) {
      let files = $addedFilesControl.map((file) => {
        file.checked = true;
        return file;
      });

      activeDownloadUnsafe.set({
        files,
        onReport,
        onDownload: onDownloadBatch,
      });
    } else {
      ControlApi.downloadAll(parent_id);
    }
  }

  function onDownloadDay(fileByDay) {
    let files = fileByDay.files.reduce((a, b) => {
      return a.concat(b.files);
    }, []);

    files = files.map((file) => {
      file.checked = true;
      return file;
    });

    if (files.some((file) => file.virus)) {
      activeDownloadUnsafe.set({
        files,
        onReport,
        onDownload: onDownloadBatch,
      });
    } else {
      onDownloadBatch(fileByDay.ids);
    }
  }

  function onReport(id) {
    AttachmentsApi.getById({
      params: {
        id,
        parent_id,
        parent_type,
      },
      success: (response) => {
        documentViewerStore.update({
          previewAttachment: {
            previewImages: [response.data.attachment.report.preview_url],
          },
        });
      },
    });
  }

  function onDeleteBatch(ids) {
    const isConfirmed = confirm($t("control_status.confirm_deletion"));

    if (isConfirmed) {
      AttachmentsApi.deleteBatch({
        params: {
          ids,
          parent_id,
          parent_type,
        },
        success: (attachments) => {
          setFilesToControl(attachments, parent_id);
        },
      });
    }
  }

  function onMarkAsRead(id, is_read) {
    AttachmentsApi.markAsRead({
      params: {
        id,
        parent_id,
        parent_type,
        is_read: !is_read,
      },
      success: () => {
        getAttachments(parent_id, parent_type);
      },
    });
  }

  function onMarkAsReadBatch(ids, is_read) {
    AttachmentsApi.markAsReadBatch({
      params: {
        ids,
        parent_id,
        parent_type,
        is_read: !is_read,
      },
      success: (attachments) => {
        setFilesToControl(attachments, parent_id);
      },
    });
  }

  function setFileSending(id) {
    const newFilesArray = getAddedFilesControl(parent_id).map((item) => {
      if (item.id === id) {
        item.sending = true;
      }

      return item;
    });

    setFilesToControl(newFilesArray, parent_id);
  }

  function setFileProgress(id, percentage) {
    const newFilesArray = getAddedFilesControl(parent_id).map((item) => {
      if (item.id === id) {
        item.percentage = percentage;
      }

      return item;
    });

    setFilesToControl(newFilesArray, parent_id);
  }

  function getAttachments(parent_id, parent_type) {
    AttachmentsApi.get({
      params: {
        parent_id,
        parent_type,
      },
      success: (files) => {
        loading = false;
        let checkedProgress = checkForInProgress(files);
        setFilesToControl(checkedProgress, parent_id);
      },
    });
  }

  function checkForInProgress(files) {
    let filesInProgress = getAddedFilesControl(parent_id);

    let upload = filesInProgress
      ? filesInProgress.filter((item) => !item.permalink && item.sending)
      : [];
    let virus = filesInProgress
      ? filesInProgress.filter((item) => item.virus_scanning)
      : [];

    let newFiles = files.map((item) => {
      if (
        virus.some((element) => element.permalink === item.permalink) &&
        !item.is_stored
      ) {
        item.virus_scanning = true;
      } else if (
        !item.is_stored &&
        item.virus_scanning === undefined &&
        item.permalink
      ) {
        item.virus_scanning = true;
        AttachmentUploadHandler.sendToVirusCheck(item.permalink);
      }

      return item;
    });

    return [...newFiles, ...upload];
  }

  function onFileUpload(file, id) {
    let newFilesArray = getAddedFilesControl(parent_id).map((item) => {
      if (item.id === id) {
        file.created_at = item.created_at;
        return file;
      } else {
        return item;
      }
    });

    filesInProgressCount--;
    if (filesInProgressCount === 0) {
      toasts.send({ title: $t("attachments.create.success"), type: "success" });
    }

    setFilesToControl(newFilesArray, parent_id);
  }

  function onArchive(id, archived) {
    onArchiveBatch([id], archived);
  }

  function onArchiveBatch(ids, archived) {
    AttachmentsApi.archiveBatch({
      params: {
        ids,
        parent_id,
        parent_type,
        archived: !archived,
      },
      success: (attachments) => {
        setFilesToControl(attachments, parent_id);
      },
    });
  }

  onMount(() => {
    UploadApi.setupUppy(uppyWrapper, $allowedFileTypes);
  });

  onDestroy(() => {
    UploadApi.closeUppy();
    unsubscribeAddedFilesControl();
    unsubscribeActiveControlPermalink();
  });
</script>

<Boundary>
  <div data-component="ControlUpload" class={styles.dropzone}>
    <div class={styles["dropzone-title"]}>
      <div class={styles.label}>{$t("control_upload.documents")}</div>
      {#if showDownloadButton}
        <Button click={onDownloadAll} style={"primary-text small"}>
          {$t("control_upload.download_all")}
        </Button>
      {/if}
    </div>

    {#if $can("create", "attachment")}
      <div class={styles["dropzone-wrapper"]}>
        <Dropzone
          bind:this={dropzone}
          onFiles={addFiles}
          uppy={true}
          bind:uppyWrapper
          allowedFileTypes={$allowedFileTypes}
        />
      </div>
    {/if}

    <div>
      {#each filesByDay as fileByDay, index (fileByDay.createDay)}
        <ControlUploadDropdown item={fileByDay} {index} files={fileByDay.files}>
          <Options style="acts-as-button left no-border">
            <div slot="title">
              <IconButton icon="much" style="blue-text small" />
            </div>
            {#if $can("mark_as_read", "attachment")}
              <Button
                click={onMarkAsReadBatch.bind(
                  this,
                  fileByDay.ids,
                  fileByDay.checked,
                )}
                style={"blue-text option"}
              >
                {$t("control_upload.check_all")}
              </Button>
            {/if}
            {#if $can("archive", "attachment")}
              <Button
                click={onArchiveBatch.bind(
                  this,
                  fileByDay.ids,
                  fileByDay.archived,
                )}
                style={"blue-text option"}
              >
                {fileByDay.archived
                  ? $t("control_upload.unarchive_all")
                  : $t("control_upload.archive_all")}
              </Button>
            {/if}
            <Button
              click={onDownloadDay.bind(this, fileByDay)}
              style={"blue-text option"}
            >
              {$t("control_upload.download_all")}
            </Button>
            {#if $can("destroy", "attachment")}
              <Button
                click={onDeleteBatch.bind(this, fileByDay.ids)}
                style={"error-text option"}
              >
                {$t("control_upload.delete_all")}
              </Button>
            {/if}
          </Options>

          <div slot="items">
            {#each fileByDay.files as items, i}
              {#each items.files as file, index (file)}
                {#if items.files.length > 1}
                  {#if index === 0}
                    <ControlUploadItem
                      {file}
                      {index}
                      {onDeleteBatch}
                      {onArchive}
                      {onDownload}
                      {onMarkAsRead}
                      {onMarkAsReadBatch}
                      {onReport}
                      onOpenSame={() => {
                        items.open = !items.open;
                      }}
                      bind:openSame={items.open}
                      ids={items.ids}
                      last={fileByDay.files.length - 1 === i}
                    />
                  {:else if items.open}
                    <ControlUploadItem
                      {file}
                      {index}
                      {onDeleteBatch}
                      {onArchive}
                      {onDownload}
                      {onMarkAsRead}
                      {onReport}
                      last={fileByDay.files.length - 1 === i}
                    />
                  {/if}
                {:else}
                  <ControlUploadItem
                    {file}
                    {index}
                    {onDeleteBatch}
                    {onArchive}
                    {onDownload}
                    {onMarkAsRead}
                    {onReport}
                    last={fileByDay.files.length - 1 === i}
                  />
                {/if}
              {/each}
            {/each}
          </div>
        </ControlUploadDropdown>
      {:else}
        {#if loading}
          <div class={styles.noyet}>{$t("control_upload.files_loading")}</div>
        {:else}
          <div class={styles.noyet}>{$t("control_upload.no_files_yet")}</div>
        {/if}
      {/each}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .label {
    font-weight: 600;
  }

  .dropzone {
    width: 100%;
  }

  .dropzone-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25em;
  }

  .dropzone-wrapper {
    margin-bottom: 20px;
  }

  .noyet {
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: var(--primary-400);
  }

  .mark {
    margin: 10px 0 0 0;
    text-align: center;
  }
</style>
