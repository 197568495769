<script>
  import { t } from "stores/i18n.js";
  import { user, isUserTeamUser } from "stores/user.js";

  import { platformLink } from "lib/helpers.js";

  import { mainNavigationService } from "shared/modules/MainNavigation/services";

  import { Popover, Avatar } from "components";

  import styles from "styleguide/MainNavMenu.json";

  const settingsLink = platformLink(`team_users/${$user.permalink}/edit`);
  const profileSwitchLink = "/a/start";

  let isMenuOpen;

  function logout(e) {
    e.preventDefault();
    mainNavigationService.logout();
  }

  function handleLinkClick(e, link) {
    // manual href handling is used to prevent platform tag
    // "style id="account-style" render duplication and theme update bug
    e.preventDefault();
    window.location.href = link;
  }
</script>

<div data-component="MainNavMenu">
  <Popover
    placement="bottom"
    showCloseButton={false}
    showArrow={false}
    isMaxWidth={true}
    offsetPosition={[0, 6]}
    preventOverflowPadding={40}
    style={"no-padding"}
    bind:open={isMenuOpen}
  >
    <Avatar
      avatar={$user.avatar}
      name={$user.name}
      isTooltip={false}
      isActive={isMenuOpen}
    />

    <div slot="content" class={styles.dropdown}>
      {#if $isUserTeamUser}
        <a
          class={styles.link}
          href={settingsLink}
          on:click={(e) => handleLinkClick(e, settingsLink)}
        >
          {$t("mainnavigation.settings")}
        </a>
      {/if}

      {#if $user.has_multiple_users}
        <a
          class={styles.link}
          href={profileSwitchLink}
          on:click={(e) => handleLinkClick(e, profileSwitchLink)}
        >
          {$t("mainnavigation.switch_profile")}
        </a>
      {/if}

      <button on:click={logout} class={styles.logout}>
        {$t("mainnavigation.logout")}
      </button>
    </div>
  </Popover>
</div>

<style lang="scss">
  .dropdown {
    @include typography-body-medium;

    display: flex;
    flex-direction: column;
    min-width: 220px;
  }

  .link {
    text-decoration: none;
    color: var(--blue-200);
    user-select: none;

    &:hover {
      background: var(--blue-010);
    }
  }

  .logout {
    @include reset-button;

    color: var(--red-200);

    &:hover {
      background: var(--red-010);
    }
  }

  .link,
  .logout {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 12px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--primary-050);
    }
  }
</style>
