<script>
  import clsx from "clsx";
  import styles from "styleguide/Counter.json";

  export let style = "";
  export let count = 0;
  export let overflowCount = 999;
  export let variant = "default";
  export let withBorder = false;
  //default - grey, success  - green, error - red, info - blue, warning - orange

  $: counter = count > overflowCount ? `${overflowCount}+` : count;
</script>

<div
  data-component="Counter"
  class={clsx(
    styles.counter,
    styles[variant],
    style.split(" ").map((x) => styles[x]),
    { [styles["with-border"]]: withBorder },
  )}
>
  {counter}
</div>

<style scoped lang="scss">
  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-width: 30px;
    padding: 0 6px;
    font-weight: 600;
    border-radius: var(--border-radius);
    &.with-border {
      border-width: 1px;
      border-style: solid;
    }
    &.default {
      background: var(--primary-025);
      color: var(--primary-400);
      border-color: var(--primary-400);
    }
    &.info {
      background: var(--blue-010);
      color: var(--blue-200);
      border-color: var(--blue-200);
    }
    &.success {
      background: var(--green-010);
      color: var(--green-500);
      border-color: var(--green-500);
    }
    &.error {
      background: var(--red-010);
      color: var(--red-200);
      border-color: var(--red-200);
    }
    &.warning {
      background: var(--yellow-025);
      color: var(--orange-400);
      border-color: var(--orange-400);
    }
  }
</style>
