<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg xmlns="http://www.w3.org/2000/svg" {width} {height} viewBox="0 0 18 18">
  <path
    {fill}
    fill-rule="evenodd"
    d="M4 8c-.553 0-1 .448-1 1 0 .553.447 1 1 1h10c.553 0 1-.447 1-1 0-.552-.447-1-1-1H4z"
  />
</svg>
