<script>
  import { onMount } from "svelte";
  import {
    QuickshareLandingPasswordForm,
    QuickshareLandingFiles,
    QuickshareLandingSteps,
    QuickshareLandingWelcomeScreen,
  } from "apps/quickshareFrontend/components";

  import { getCookie } from "lib/cookie.js";

  import { Boundary, Box } from "components";

  import { LINK_COOKIE_NAME } from "../../constants";
  import { link } from "../../stores";

  const {
    hasPassword,
    isPersonalInbox,
    isShare,
    isRequest,
    isTwoWay,
    permalink,
  } = $link;

  $: emailVerified = $link.emailVerified;

  $: isWelcomeScreen = !hasPassword && !isPersonalInbox;
  $: isPasswordCorrect = false;

  onMount(() => {
    const linkCookie = getCookie(LINK_COOKIE_NAME);
    const isShowContent = linkCookie === permalink && !hasPassword;

    if (isShowContent) {
      isWelcomeScreen = false;
      onShowContent();
    } else if (emailVerified || isPersonalInbox) {
      onShowContent();
    }
  });

  function onShowContent() {
    document.cookie = `${LINK_COOKIE_NAME}=${permalink}`;
    isWelcomeScreen = false;
    isPasswordCorrect = true;
  }
</script>

<Boundary>
  <div data-component="QuickshareLandingContent">
    {#if hasPassword && !isPasswordCorrect && !emailVerified}
      <Box>
        <QuickshareLandingPasswordForm {onShowContent} />
      </Box>
    {:else if isWelcomeScreen && !emailVerified}
      <Box>
        <QuickshareLandingWelcomeScreen onAction={onShowContent} />
      </Box>
    {:else}
      {#if isShare}
        <QuickshareLandingFiles />
      {/if}

      {#if isRequest}
        <Box>
          <QuickshareLandingSteps />
        </Box>
      {/if}

      {#if isTwoWay}
        <QuickshareLandingFiles />
        <Box style={"margin-top"}>
          <QuickshareLandingSteps />
        </Box>
      {/if}
    {/if}
  </div>
</Boundary>
