<script>
  import { userInfo } from "apps/quickshare/stores";
  import { t } from "stores/i18n";

  import { Boundary, Toast } from "components";

  import styles from "styleguide/QuickshareSidebarInfo.json";
</script>

<Boundary>
  <div data-component="QuickshareSidebarInfo">
    <Toast
      close={false}
      type={"info"}
      title={$t("quickshare_sidebar_info.title")}
    >
      <span class={styles.text}>
        <span
          >{@html $t("quickshare_sidebar_info.message", {
            postProcess: "interval",
            count: $userInfo?.setting.monthlyLinksCount,
            countLimit: $userInfo?.setting.monthlyLinksLimit,
          })}</span
        >
      </span>
    </Toast>
  </div>
</Boundary>

<style lang="scss">
  .text {
    @include typography-body-regular;
  }
</style>
