import { toastsHelper } from "shared/helpers";

import { compliancePackageApi } from "../apis";
import { compliancePackageInit } from "../constants";
import { compliancePackage } from "../stores";

class CompliancePackageService {
  constructor() {}

  getCompliancePackage({ onSuccess, onError } = {}) {
    compliancePackageApi.getCompliancePackage({
      success: (data) => {
        compliancePackage.set(data.compliance_package);
        onSuccess?.(data);
      },
      error: (err) => {
        toastsHelper.sendError("errors.unknown");
        onError?.(err);
      },
    });
  }

  updateCompliancePackage({ payload, onSuccess, onError } = {}) {
    compliancePackageApi.updateCompliancePackage({
      params: payload,
      success: (res) => {
        const newData = Object.keys(payload).reduce(
          (acc, key) => ({
            ...acc,
            [key]: res.compliance_package[key],
          }),
          {},
        );

        compliancePackage.update((data) => ({
          ...data,
          ...newData,
        }));

        toastsHelper.sendSuccess("success.update_successful");

        onSuccess?.(res);
      },
      error: (err) => {
        toastsHelper.sendError("errors.unknown");
        onError?.(err);
      },
    });
  }

  updateValue(key, value) {
    compliancePackage.update((data) => ({
      ...data,
      [key]: value,
    }));
  }

  resetData() {
    compliancePackage.set({ ...compliancePackageInit });
  }

  static instance;

  static getInstance() {
    if (!CompliancePackageService.instance) {
      CompliancePackageService.instance = new CompliancePackageService();
    }
    return CompliancePackageService.instance;
  }
}

export const compliancePackageService = CompliancePackageService.getInstance();
