<script>
  import { t } from "stores/i18n.js";
  import { user } from "stores/user.js";

  import AttachmentsApi from "apis/attachments/attachments.js";
  import CommentsApi from "apis/comments/comments.js";

  import { downloadFile } from "lib/helpers.js";
  import { dateTimeFormat } from "lib/helpers.js";
  import { sanitizeRelaxed } from "lib/sanitize.js";

  import { Button, IconButton, FileItem, Avatar } from "components";

  import styles from "styleguide/Comment.json";

  export let content;
  export let user_name;
  export let user_avatar;
  export let created_at;
  export let picture = true;
  export let can_delete;
  export let permalink;
  export let parent_id;
  export let parent_type;
  export let is_deleted;
  export let is_created_by_client;
  export let onDeleteSuccess = () => {};
  export let style = "";
  export let attachments = [];
  export let canDeleteAttachments = false;
  export let sidebar = false;
  export let mentionEvent = undefined;

  $: contentData = getContentData(content);

  $: calculatedStyles = function () {
    let s = style.split(" ");
    if (sidebar) {
      s.push("sidebar");
    }
    return s.map((x) => styles[x]).join(" ");
  };

  function getContentData(content) {
    const parser = new DOMParser();

    // replace is necessary for backward compatibility
    // with comments before these changes
    const contentData = content.replace(/mentioned-owner/g, "mentioned-user");

    const doc = parser.parseFromString(contentData, "text/html");
    const spans = doc.querySelectorAll("span.mentioned-user");

    spans.forEach((span) => {
      if (span.getAttribute("data-mentioned-user") === $user.permalink) {
        span.classList.add("mentioned-owner");
      }
    });

    return doc.body.innerHTML;
  }

  function onTextClick(event) {
    if (!mentionEvent) return;

    let dataset = event.target.dataset || {};
    let value = dataset && dataset[mentionEvent.dataset];

    if (value) {
      mentionEvent.handler(value);
    }
  }

  function downloadAttachment(index) {
    CommentsApi.downloadAttachment({
      params: {
        parent_type,
        parent_id,
        permalink,
        index,
      },
      success: (response) => {
        downloadFile(response.data);
      },
    });
  }

  function deleteAttachment(id) {
    const isConfirmed = confirm($t("questions.sure"));

    if (isConfirmed) {
      AttachmentsApi.delete({
        params: {
          id,
          parent_id: permalink,
          parent_type: "comment",
        },
        success: onDeleteSuccess,
      });
    }
  }

  function onDelete() {
    const isConfirmed = confirm($t("questions.sure"));

    if (isConfirmed) {
      CommentsApi.delete({
        params: {
          parent_type,
          parent_id,
          permalink,
        },
        success: onDeleteSuccess,
      });
    }
  }
</script>

{#if !is_deleted}
  <div data-component="Comment" class="{styles.comment} {calculatedStyles()}">
    {#if picture}
      <div class={styles.avatar}>
        <Avatar
          name={user_name}
          avatar={user_avatar}
          isHighlighted={is_created_by_client}
        />
      </div>
    {/if}
    <div class={styles.content}>
      <div class={styles.header}>
        <div class={styles.meta}>{dateTimeFormat(created_at)}</div>

        {#if can_delete}
          <div class={styles.button}>
            <Button click={onDelete} style="error-text small">
              {$t("comment.delete")}
            </Button>
          </div>
        {/if}
      </div>
      <div class={styles.name}>{user_name}</div>
      <div class={styles.message} on:click={onTextClick}>
        {@html sanitizeRelaxed(contentData)}
      </div>
      {#if attachments}
        {#each attachments as { filename, filesize, permalink }, index}
          <div class={styles["file-wrapper"]}>
            <FileItem name={filename} size={filesize} {sidebar}>
              <div class={styles.buttons}>
                <div class={styles.downloadbutton}>
                  <IconButton
                    icon="download"
                    style="primary-text small"
                    click={downloadAttachment.bind(this, index)}
                  />
                </div>
                {#if canDeleteAttachments}
                  <div class={styles.button}>
                    <Button
                      style="error-text small"
                      click={deleteAttachment.bind(this, permalink)}
                    >
                      {$t("comment.delete")}
                    </Button>
                  </div>
                {/if}
              </div>
            </FileItem>
          </div>
        {/each}
      {/if}
    </div>
  </div>
{/if}

<style global lang="scss">
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    width: 100%;
  }

  .avatar {
    margin-right: 10px;
  }

  .comment {
    display: flex;
    padding: 12px 0;
  }

  .meta {
    font-weight: 500;
    color: var(--primary-300);
  }

  .name {
    font-weight: 600;
  }

  .buttons {
    display: flex;
  }

  .downloadbutton {
    right: 12px;
  }

  .message b {
    font-weight: 700;
  }
  .message img {
    width: 100%;
  }

  .message i {
    font-style: italic;
  }

  .message ul,
  .message ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .message ul {
    list-style-type: disc;
  }

  .message ol {
    list-style-type: decimal;
  }

  .message {
    :global(.mentioned-user) {
      background-color: var(--primary-025);
    }

    :global(.mentioned-owner) {
      background-color: rgba(254, 235, 119, 0.5);
    }
  }

  .message :global(.mentioned-file) {
    cursor: pointer;
    color: var(--blue-200);
  }

  .file-wrapper {
    width: 100%;
  }
</style>
