<script>
  import { onDestroy } from "svelte";
  import { tick } from "svelte";
  import clsx from "clsx";
  import { t } from "stores/i18n.js";
  import { discardModalsContent } from "stores/modals";
  import { toasts } from "stores/toasts.js";
  import { user } from "stores/user.js";
  import TeamUsersApi from "apis/users/team_users.js";

  import { sanitizeRelaxed } from "lib/sanitize.js";

  import { ALLOWED_FILE_TYPES_DEFAULT } from "shared/constants";
  import { isFileTypeAcceptable } from "shared/helpers";

  import { Button, IconButton, FileItem, Search, TextInput } from "components";
  import styles from "styleguide/TextEditor.json";

  export let value;
  export let valueInput = sanitizeRelaxed(value);
  export let onPostComment;
  export let allowMentions;
  export let clientUsers = [];
  export let mentionFiles;
  export let mentionStyle;
  export let active = false;
  export let border = true;
  export let style = "";
  export let under;
  export let sidebar = false;
  export let parent_id;
  export let parent_type;
  export let storageModalKey;
  export let allowedFileTypes = ALLOWED_FILE_TYPES_DEFAULT;

  let dirty;
  let files;
  let fileInput;
  let content = "";
  let contentWrapper = "";
  let attachments = [];
  let mentionType = null;
  let mentionValue = "";
  let mentionSearchValue = "";
  let mentionStarted = false;
  let mentionStart = 0;
  let searchX = 0;
  let searchY = 0;
  let searchList = [];
  let debounceTimer = null;
  let isDebounceStarted = false;
  const MAX_SEARCH_STRING = 20;

  $: value = sanitizeRelaxed(valueInput);
  $: if (value) {
    active = value;
  }

  function format(command, value) {
    document.execCommand(command, false, value);
  }

  function onActive() {
    active = true;
    setTimeout(() => {
      content.focus();
    }, 1);
  }

  function setQuote() {
    let text = document.getSelection();
    document.execCommand("insertHTML", false, " <q>" + text + "</q> ");
  }

  function onAddAttachment() {
    fileInput.click();
  }

  function setAttachment(event) {
    files = event.target.files;

    if (files) {
      if (checkFilesType(files)) {
        attachments = [...attachments, ...files];
      }
    }

    fileInput.value = "";
  }

  function checkFilesType(files) {
    let _files = Array.from(files);

    for (let index = 0; index < _files.length; index++) {
      const element = _files[index];

      if (!isFileTypeAcceptable(element.name, allowedFileTypes)) {
        toasts.send({
          title: $t("errors.no_acceptable_file_type"),
          type: "error",
        });
        return false;
      }
    }

    return true;
  }

  function deleteFile(index) {
    let newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    attachments = newAttachments;
  }

  let charactersActions = {
    "@": {
      active: true,
      starterSymbol: "a",
      tag: "data-mentioned-user",
      getClients: async (value) => {
        let search = value ? value : "a";
        const promiseTeam = new Promise((resolve, reject) => {
          TeamUsersApi.search({
            params: {
              search,
              item_id: parent_id,
              item_type: parent_type,
              exclude_email_search: true,
            },
            success: (response) => {
              let users = response.data.team_users;
              resolve(
                users.map(({ text, id }) => ({
                  label: text,
                  value: id,
                  type: "team_users",
                })),
              );
            },
            error: reject,
          });
        });

        const clients = clientUsers.reduce((acc, user) => {
          if (user.email.toLowerCase().includes(search.toLowerCase())) {
            return [
              ...acc,
              {
                label: user.email,
                value: user.permalink,
                type: "client_users",
              },
            ];
          }
          return acc;
        }, []);

        return promiseTeam
          .then((resultTeam) => {
            return [...resultTeam, ...clients];
          })
          .catch((error) => {
            throw error;
          });
      },
    },
    "#": {
      active: mentionFiles,
      starterSymbol: "#",
      tag: "data-mentioned-file",
    },
  };

  async function checkTypedCharacter() {
    if (!allowMentions) return;

    let caretPosition = getCaretPosition(content);
    let text = content.textContent;
    let character = text.charAt(caretPosition - 1);
    let { active } = charactersActions[character] ?? {};

    if (active) {
      mentionType = character;
      mentionStart = caretPosition;
      mentionStarted = true;
      setSearchPosition();
    }

    if (mentionType) {
      let textToCursor = text.slice(mentionStart - 1, caretPosition);
      let delimiterCount = (textToCursor.match(`.*${mentionType}.*`, "g") || [])
        .length;

      if (delimiterCount === 1 && !isDebounceStarted) {
        isDebounceStarted = true;
        await getMentionList();
      } else if (isDebounceStarted && delimiterCount === 0) {
        mentionType = null;
        isDebounceStarted = false;
      }

      if (isDebounceStarted) {
        let textClean = textToCursor.split(mentionType).join("");

        if (textClean.length <= MAX_SEARCH_STRING) {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(async () => {
            await getMentionList(textClean);
          }, 450);
        }
      } else {
        mentionType = null;
      }
    }
  }

  function nodeWalk(node, func) {
    let result = func(node);
    for (
      node = node.firstChild;
      result !== false && node;
      node = node.nextSibling
    ) {
      result = nodeWalk(node, func);
    }
    return result;
  }

  function getCaretPosition(elem) {
    let sel = window.getSelection();
    let cum_length = [0, 0];

    if (sel.anchorNode === elem) {
      cum_length = [sel.anchorOffset, sel.extentOffset];
    } else {
      let nodes_to_find = [sel.anchorNode, sel.extentNode];
      if (!elem?.contains(sel.anchorNode) || !elem?.contains(sel.extentNode)) {
        return undefined;
      } else {
        let found = [0, 0];
        let i;
        nodeWalk(elem, function (node) {
          for (i = 0; i < 2; i++) {
            if (node === nodes_to_find[i]) {
              found[i] = true;
              if (found[i === 0 ? 1 : 0]) return false;
            }
          }

          if (node.textContent && !node.firstChild) {
            for (i = 0; i < 2; i++) {
              if (!found[i]) cum_length[i] += node.textContent.length;
            }
          }
        });
        cum_length[0] += sel.anchorOffset;
        cum_length[1] += sel.extentOffset;
      }
    }

    return cum_length[1];
  }

  async function getMentionList(text) {
    if (charactersActions[mentionType]?.getClients) {
      try {
        searchList = await charactersActions[mentionType].getClients(text);
      } catch (error) {
        console.error(error);
      }
    } else {
      searchList = mentionFiles;
    }

    mentionValue = text;
    mentionSearchValue = mentionValue
      ? mentionValue
      : charactersActions[mentionType]?.starterSymbol;
  }

  function setSearchPosition() {
    let selection = window.getSelection();
    let range = selection.getRangeAt(0);
    let rectCaret = range.getClientRects()[0];
    let rectContent = contentWrapper.getClientRects()[0];
    if (!rectCaret) return;
    let contentWidth = content.offsetWidth;
    let windowWidth = window.innerWidth;
    let caretX = Math.round(rectCaret.x);
    let caretY = Math.round(rectCaret.y);
    let contentX = Math.round(rectContent.x);
    let contentY = Math.round(rectContent.y);

    let widthDiff = contentWidth + caretX + 20 - windowWidth;

    if (widthDiff > 0) {
      caretX -= widthDiff;
    } else {
      contentX += 12;
    }

    searchX = caretX - contentX;
    searchY = caretY - contentY;
  }

  async function printMention(id, text) {
    let tag = charactersActions[mentionType].tag;
    let delimiter = mentionType + mentionValue;

    const mentionSubject =
      tag === "data-mentioned-file" ? "mentioned-file" : "mentioned-user";

    const mentionOwner = id === $user.permalink ? "mentioned-owner" : "";

    const className = `mentioned-item ${mentionSubject} ${mentionOwner}`;

    let mentionTimestamp = Date.now();
    let mentionUserType = searchList.find((user) => user.value === id)?.type;

    let mentionElement = `<span class="${className}"
                                contentEditable="${false}"
                                ${tag}="${id}"
                                data-mention-timestamp="${mentionTimestamp}"
                                data-mention-user-type="${mentionUserType}"
                                >${mentionType + text}</span>&nbsp;`;

    let splittedHtml = valueInput.split(delimiter);
    let splittedText = content.textContent.split(delimiter);

    let result = "";
    let lastTextIndex = 0;
    let totalLength = 0;

    for (let i = 0; i < splittedText.length; i++) {
      totalLength += splittedText[i].length + delimiter.length;

      if (totalLength >= mentionStart) {
        lastTextIndex = i;
        break;
      }
    }

    for (let i = 0; i < splittedHtml.length; i++) {
      if (i === lastTextIndex) {
        result += splittedHtml[i] + mentionElement;
      } else if (i < splittedHtml.length - 1) {
        result += splittedHtml[i] + delimiter;
      } else {
        result += splittedHtml[i];
      }
    }

    valueInput = result;
    mentionType = null;

    await tick();
    setCaretToLastMention();
  }

  function setCaretToLastMention() {
    let range = document.createRange();
    let selection = window.getSelection();

    let lastMentionNode = null;
    let latestTimestamp = 0;

    const allMentionElements = document.querySelectorAll(".mentioned-item");
    allMentionElements.forEach((item) => {
      const timestamp = parseInt(item.getAttribute("data-mention-timestamp"));
      if (!isNaN(timestamp) && timestamp > latestTimestamp) {
        latestTimestamp = timestamp;
        lastMentionNode = item;
      }
    });

    if (lastMentionNode) {
      let nextSibling = lastMentionNode.nextSibling;

      range.setStartAfter(nextSibling);
      range.collapse(true);

      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  function getMentions() {
    let mentions = {};
    let userElements = [...content.querySelectorAll("[data-mentioned-user]")];

    let teamUsers = [];
    let clientUsers = [];

    userElements.forEach((item) => {
      let userId = item.dataset.mentionedUser;
      let userType = item.dataset.mentionUserType;

      if (userType === "team_users") {
        teamUsers.push(userId);
      } else {
        clientUsers.push(userId);
      }
    });

    mentions.team_users = [...new Set(teamUsers)];
    mentions.client_users = [...new Set(clientUsers)];

    return mentions;
  }

  function processValueInput(input) {
    const cleanedInput = input
      .replace(/^((<div><br><\/div>)|(&nbsp;)|\s)+/, "")
      .replace(/((<div><br><\/div>)|(&nbsp;)|\s)+$/, "")
      .replace(/\s?mentioned-owner/g, "")
      .trim();

    const isInvalid = !cleanedInput || cleanedInput === "<br>";

    return { cleanedInput, isInvalid };
  }

  function onSubmit() {
    const { cleanedInput, isInvalid } = processValueInput(valueInput);

    if (isInvalid) {
      dirty = true;
      content.innerHTML = "";
      return;
    }

    dirty = false;

    if (onPostComment) {
      onPostComment(cleanedInput, attachments, getMentions());
    }
    active = false;
    value = "";
    valueInput = "";
    attachments = [];
    files = null;
    discardModalsContent(storageModalKey);
  }

  onDestroy(() => {
    value = "";
    valueInput = "";
  });
</script>

{#if active}
  <div
    data-component="TextEditor"
    class={clsx(
      styles.wrapper,
      style.split(" ").map((x) => styles[x]),
      { [styles["sidebar"]]: sidebar },
    )}
  >
    <div class={styles.inner}>
      {#if onPostComment && under}
        <div class={styles.post}>
          <Button click={onAddAttachment} style={"primary-text small"}>
            {$t("text_editor.attach_file")}
          </Button>

          <Button click={onSubmit} style={"primary small"}>
            {$t("text_editor.post")}
          </Button>
        </div>
      {/if}

      <div bind:this={contentWrapper}>
        <div
          data-editor
          role="textbox"
          tabindex="0"
          class={styles.editor}
          contenteditable="true"
          on:keyup={checkTypedCharacter}
          bind:this={content}
          bind:innerHTML={valueInput}
        />

        {#if mentionType}
          <div
            class={styles.search}
            style={`left:${searchX}px; top:${searchY}px;`}
          >
            <Search
              mention={true}
              {searchList}
              select={printMention}
              start={mentionStarted}
              style={mentionStyle}
              bind:value={mentionSearchValue}
            />
          </div>
        {/if}
      </div>
      {#if dirty}
        <div class={styles.error}>
          {$t("text_editor.needs_message")}
        </div>
      {/if}
      <div class={styles.toolbar}>
        <button class={styles.format} on:click={format.bind(this, "bold")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.724 0c2.51 0 4.54 2.114 4.54 4.666 0 .808-.22 1.593-.61 2.275l-.05.084.022.02c.947.863 1.562 2.087 1.615 3.578l.004.215c0 2.95-2.393 5.064-5.317 5.159L8.724 16H3.532c-.981 0-1.777-.796-1.777-1.777 0-.921.7-1.678 1.597-1.77V3.548c-.897-.09-1.597-.848-1.597-1.769C1.755.841 2.48.073 3.4.005L3.532 0h5.192zM6.907 9.19v3.254h1.817c1.165 0 1.966-.684 1.966-1.606 0-.978-.703-1.59-1.716-1.644l-.146-.003H6.907zm1.92-3.555c.478 0 .881-.466.881-.969 0-.581-.413-1.048-.889-1.104l-.095-.006H6.907v2.078l1.92.001z"
              transform="translate(-302 -860) translate(227 63) translate(24 621) translate(50 175) translate(1 1)"
            />
          </svg>
        </button>
        <button class={styles.format} on:click={format.bind(this, "italic")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.153 16c-.49 0-.889-.398-.889-.889 0-.455.344-.831.786-.882l.103-.006h2.69L9.268 1.776l-2.058.001c-.491 0-.89-.398-.89-.889 0-.456.344-.831.786-.883L7.21 0h6.637c.491 0 .889.398.889.89 0 .455-.343.83-.785.882l-.104.006h-2.692L6.73 14.221h2.06c.49 0 .889.399.889.89 0 .455-.343.831-.786.882L8.79 16H2.153z"
              transform="translate(-348 -860) translate(227 63) translate(24 621) translate(50 175) translate(46) translate(1 1)"
            />
          </svg>
        </button>
        <button
          class={styles.format}
          on:click={format.bind(this, "strikethrough")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 10.113c.455 0 .831.343.882.785l.006.104v3.22h1.956c.49 0 .889.399.889.89 0 .455-.343.831-.786.882l-.103.006H5.156c-.491 0-.89-.398-.89-.889 0-.455.344-.831.786-.882l.104-.006H7.11v-3.221c0-.49.399-.889.89-.889zm6.637-2.528c.491 0 .889.398.889.89 0 .455-.343.83-.785.882l-.104.006H1.363c-.49 0-.889-.398-.889-.889 0-.456.344-.831.786-.883l.103-.006h13.274zM13.215 0c.614 0 1.202.244 1.636.678.39.392.626.907.668 1.452l.007.182v.475c0 .49-.398.889-.889.889-.456 0-.831-.343-.883-.785l-.006-.104V2.31c0-.14-.056-.275-.157-.377-.079-.08-.181-.13-.292-.148l-.084-.007H8.888v4.167c0 .491-.398.89-.888.89-.456 0-.832-.344-.883-.786l-.006-.104V1.777l-4.325.001c-.143 0-.278.056-.377.155-.08.081-.133.184-.15.295l-.007.084v.473c0 .491-.398.889-.889.889-.456 0-.831-.343-.883-.785l-.006-.104v-.47C.473 1.7.716 1.112 1.15.677c.39-.39.905-.627 1.452-.67L2.786 0h10.429z"
              transform="translate(-393 -860) translate(227 63) translate(24 621) translate(50 175) translate(91) translate(1 1)"
            />
          </svg>
        </button>
        <button class={styles.format} on:click={setQuote}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              d="M4.701 0c1.446 0 2.632 1.14 2.632 2.562v9.203c0 2.284-1.862 4.133-4.18 4.231L2.96 16h-.763c-.49 0-.889-.398-.889-.888 0-.456.343-.832.786-.883l.103-.006h.763c1.388 0 2.51-1.029 2.592-2.31l.004-.148V9.169c-.268.09-.555.138-.854.138h-2.07C1.187 9.307 0 8.168 0 6.745V2.562C0 1.14 1.186 0 2.632 0zm8.667 0C14.813 0 16 1.14 16 2.562v9.203c0 2.284-1.862 4.133-4.18 4.231l-.194.004h-.763c-.491 0-.889-.398-.889-.888 0-.456.343-.832.785-.883l.104-.006h.763c1.388 0 2.51-1.029 2.591-2.31l.005-.148V9.169c-.268.09-.555.138-.854.138h-2.07c-1.445 0-2.632-1.139-2.632-2.562V2.562C8.666 1.14 9.853 0 11.298 0z"
              transform="translate(-484 -860) translate(227 63) translate(24 621) translate(50 175) translate(182) translate(1 1)"
            />
          </svg>
        </button>
        <button
          class={styles.format}
          on:click={format.bind(this, "insertunorderedlist")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              d="M1.778 12.445c.982 0 1.778.795 1.778 1.778 0 .981-.796 1.777-1.778 1.777S0 15.204 0 14.223c0-.983.795-1.778 1.778-1.778zm13.333.889c.491 0 .89.398.89.889 0 .455-.344.831-.786.882l-.104.006H6.222c-.49 0-.888-.398-.888-.888 0-.456.343-.832.785-.883l.103-.006h8.89zM1.778 6.223c.982 0 1.778.795 1.778 1.777 0 .982-.796 1.778-1.778 1.778S0 8.982 0 8s.795-1.777 1.778-1.777zm13.333.888c.491 0 .89.398.89.89 0 .455-.344.83-.786.882l-.104.006H6.222c-.49 0-.888-.398-.888-.889 0-.455.343-.831.785-.883l.103-.006h8.89zM1.778.001c.982 0 1.778.795 1.778 1.777 0 .982-.796 1.778-1.778 1.778S0 2.76 0 1.778.795 0 1.778 0zm13.333.888c.491 0 .89.398.89.89 0 .455-.344.83-.786.882l-.104.006H6.222c-.49 0-.888-.398-.888-.889 0-.456.343-.831.785-.883L6.222.89h8.89z"
              transform="translate(-530 -860) translate(227 63) translate(24 621) translate(50 175) translate(228) translate(1 1)"
            />
          </svg>
        </button>
        <button
          class={styles.format}
          on:click={format.bind(this, "insertorderedlist")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              d="M1.752 11.56c.468 0 .885.147 1.174.413.252.233.39.542.39.871.001.344-.147.64-.406.84.358.2.564.544.563.975 0 .334-.137.637-.398.878-.324.298-.8.463-1.34.463-.99 0-1.702-.526-1.734-1.28-.002-.06.02-.12.067-.164.045-.042.108-.067.173-.067h.65c.127 0 .232.091.24.208.038.506 1.214.491 1.214-.058 0-.276-.256-.443-.684-.443h-.358c-.133 0-.241-.1-.241-.222v-.478c0-.123.108-.222.24-.222h.359c.19 0 .347-.05.445-.14.072-.067.11-.155.109-.26 0-.11-.035-.198-.105-.263-.222-.206-.905-.13-.92.243-.005.12-.112.215-.242.215H.331c-.066 0-.129-.025-.174-.068-.045-.042-.07-.102-.068-.161.024-.74.723-1.28 1.663-1.28zm13.36 1.774c.49 0 .888.398.888.889 0 .455-.343.831-.785.882l-.104.006H6.222c-.49 0-.888-.398-.888-.888 0-.456.343-.832.785-.883l.103-.006h8.89zM1.71 5.807c.924 0 1.593.557 1.593 1.325 0 .51-.249.896-1.066 1.654l-.475.46H3.13c.132 0 .24.099.24.221v.503c0 .123-.108.223-.24.223H.346c-.134 0-.242-.1-.242-.223v-.424c0-.057.025-.113.068-.155l1.431-1.363c.457-.445.61-.635.61-.878 0-.302-.277-.409-.514-.409-.158 0-.287.043-.375.122-.086.08-.132.2-.131.347 0 .058-.026.115-.071.158-.046.042-.107.065-.171.065H.342C.21 7.433.1 7.333.1 7.21c0-.826.663-1.403 1.611-1.403zm13.4 1.304c.491 0 .89.398.89.89 0 .455-.344.83-.786.882l-.104.006H6.222c-.49 0-.888-.398-.888-.889 0-.455.343-.831.785-.883l.103-.006h8.89zM2.203.001c.132 0 .24.1.24.222v3.889c0 .122-.108.222-.24.222h-.64c-.134 0-.243-.1-.243-.222V1.207l-.663.397c-.073.046-.17.05-.247.01-.078-.04-.126-.114-.126-.196V.856C.284.78.325.71.393.67L1.448.037c.04-.025.085-.036.132-.036zM15.11.889c.491 0 .89.398.89.89 0 .455-.344.83-.786.882l-.104.006H6.222c-.49 0-.888-.398-.888-.889 0-.456.343-.831.785-.883L6.222.89h8.89z"
              transform="translate(-575 -860) translate(227 63) translate(24 621) translate(50 175) translate(273) translate(1 1)"
            />
          </svg>
        </button>
        {#if onPostComment && !under}
          <div class={styles.post}>
            <Button click={onAddAttachment} style={"primary-text small"}
              >{$t("text_editor.attach_file")}</Button
            >
            <Button click={onSubmit} style={"primary small"}
              >{$t("text_editor.post")}</Button
            >
          </div>
        {/if}
      </div>
    </div>

    {#if attachments && attachments.length}
      <div class={styles.attachments}>
        {#each attachments as { name, size }, index}
          <FileItem
            {name}
            {size}
            isUpload={true}
            {sidebar}
            staticButtons={true}
          >
            <IconButton
              style={"error-text small"}
              icon="trash"
              click={deleteFile.bind(this, index)}
            />
          </FileItem>
        {/each}
      </div>
    {/if}
  </div>
{:else}
  <div on:click={onActive}>
    <TextInput
      style={`fullsize ${border ? "" : "no-border"}`}
      placeholder={$t("text_editor.write_comment")}
    />
  </div>
{/if}
<input
  type="file"
  multiple
  bind:files
  bind:this={fileInput}
  class={styles.fileInput}
  on:change={setAttachment}
/>

<style global lang="scss">
  .wrapper {
    width: 100%;

    &.margin {
      margin: 12px 0;
    }
  }

  .inner {
    position: relative;

    .wrapper.toolbar-top & {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .post {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
  }

  .attachments {
    .sidebar & {
      margin: 5px -12px 0;
      padding: 10px 12px 0 22px;
      border-top: solid 1px var(--primary-050);
    }
  }

  .editor {
    max-width: 100%;
    padding: 10px 12px;
    min-height: 84px;
    background: #fff;
    position: relative;
    z-index: 1;

    .wrapper:not(.sidebar) & {
      border-radius: var(--border-radius);
      border: solid 1px var(--primary-050);

      &:focus,
      &:active {
        border: solid 1px var(--blue);
      }
    }

    img {
      width: 100%;
    }

    .editor-big & {
      min-height: 240px;
      max-height: 240px;
      overflow-y: auto;
    }
  }

  .input {
    margin-top: 12px;
  }

  .fileInput {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 12px;

    .toolbar-top & {
      margin: 0 0 10px;
    }
  }

  .toolbar > span:hover {
    text-decoration: underline;
  }

  .format {
    display: flex;
    align-items: center;
    margin-right: 15px;
    padding: 5px 10px;
    border-radius: 8px;
    border: 1px solid transparent;
    fill: var(--primary-500);
    cursor: pointer;

    &:hover {
      fill: var(--blue-400);
      border: 1px solid var(--blue-400);
    }

    .toolbar-full & {
      margin-right: 0;
    }
  }

  .search {
    position: absolute;
    margin-top: 11px;
    z-index: 10;
  }

  .editor b {
    font-weight: 700;
  }

  .editor i {
    font-style: italic;
  }

  .editor ul,
  .editor ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  .editor ul {
    list-style-type: disc;
  }

  .editor ol {
    list-style-type: decimal;
  }

  .editor {
    :global(.mentioned-user) {
      background-color: var(--primary-025);
    }

    :global(.mentioned-owner) {
      background-color: rgba(254, 235, 119, 0.5);
    }
  }

  .editor :global(.mentioned-file) {
    cursor: pointer;
    color: var(--blue-200);
  }
  .editor :global(.mentioned-item) {
    z-index: -1;
  }

  .error {
    display: flex;
    justify-content: center;
    color: var(--red);
    font-size: 12px;
    margin-top: 5px;
  }
</style>
