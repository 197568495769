<script>
  import { onMount, setContext } from "svelte";

  import { account } from "stores/account.js";
  import * as brandSpaceStore from "stores/brand_space";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { initializeI18N } from "stores/i18n.js";
  import { licenseKeys } from "stores/license_keys.js";
  import { notificationsCount } from "stores/notification.js";
  import { permissions } from "stores/permission.js";
  import { user } from "stores/user.js";

  import { MainNavigation } from "shared/modules";

  import { Boundary, DocumentViewer, NavigationBar, Theme } from "components";
  import Devtools from "../../utilities/Devtools.svelte";

  export let currentUser;
  export let language;
  export let theme;
  export let container;
  export let initialBreadcrumb;
  export let currentAccount;
  export let settings;
  export let licenses;
  export let brandSpace;

  let i18nInitialized = false;

  setContext("config", {
    language: language,
  });

  licenseKeys.set(licenses);
  if (currentUser) {
    user.set(currentUser);
    permissions.set(currentUser.permissions);
    notificationsCount.set(currentUser.notifications_count);
  }
  account.set(currentAccount);

  breadcrumb.set(initialBreadcrumb);
  brandSpaceStore.brandSpace.set(brandSpace);

  onMount(async () => {
    i18nInitialized = false;
    initializeI18N(language).then(() => {
      i18nInitialized = true;
      document.querySelector("#container--body")?.classList?.remove("hidden");
    });
  });
</script>

<Boundary>
  <Theme name={theme} {container}>
    {#if i18nInitialized}
      {#if !settings.frontend_app}
        <MainNavigation />
        <NavigationBar />
      {/if}

      <slot />
      <DocumentViewer />
      <Devtools />
    {/if}
  </Theme>
</Boundary>
