<script>
  import { onMount } from "svelte";

  import { emailWhiteList } from "stores/account.js";
  import { t } from "stores/i18n.js";
  import { user } from "stores/user.js";

  import AccountApi from "apis/admin/accounts.js";

  import { Button, TextInput, Toast } from "components";
  import styles from "styleguide/EmailWhiteList.json";

  $: userEmailDomain = $user.email.split("@")[1];
  $: isDomainInWhiteList = $emailWhiteList.includes(userEmailDomain);

  onMount(async () => {
    AccountApi.getCustomEmailWhiteList();
  });

  function updateEmailWhiteList() {
    if (!isDomainInWhiteList) {
      const isConfirmed = confirm($t("email_white_list.confirmation"));
      if (isConfirmed) {
        AccountApi.updateEmailWhiteList();
      }
    } else {
      AccountApi.updateEmailWhiteList();
    }
  }
</script>

<div data-component="EmailWhiteList" class={styles["section"]}>
  <div class={styles["section-header"]}>
    <div class={styles["section-title"]}>
      {$t("email_white_list.header")}
    </div>
    <div class={styles["section-description"]}>
      {$t("email_white_list.description")}
    </div>
    <div class={styles["section-description"]}>
      {$t("email_white_list.disclaimer_client_users")}
    </div>
  </div>
  <div>
    <div class={styles["text-input"]}>
      <TextInput
        bind:value={$emailWhiteList}
        label={$t("email_white_list.domain")}
        footnotes={[$t("email_white_list.sub_description")]}
      />
    </div>
    {#if $emailWhiteList && !isDomainInWhiteList}
      <div class={styles["toast-wrapper"]}>
        <Toast type="warning" title={$t("email_white_list.disclaimer")} />
      </div>
    {/if}
  </div>
  <div>
    <Button click={updateEmailWhiteList} style="primary">
      {$t("email_white_list.save")}
    </Button>
  </div>
</div>

<style lang="scss">
  .section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-block: 24px;

    &-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: var(--primary-500, #052861);
    }

    &-description {
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-300);
    }

    &-warning {
      padding-top: 8px;
      padding-bottom: 24px;
      line-height: 20px;
      color: var(--signal-red-text, #dd424d);
    }
  }

  .toast-wrapper {
    margin-top: 16px;
  }
  .text-input {
    font-weight: 500;
  }
</style>
