<script>
  import {
    confirmationId,
    letterhead,
    selectedItems,
    resetSelected,
    getConfirmation,
  } from "stores/confirmation/confirmation.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { user } from "stores/user.js";

  import ConfirmationItemsApi from "apis/confirmation/confirmation_items.js";
  import ConfirmationMailingsApi from "apis/confirmation/confirmation_mailings.js";

  import { Boundary, Button } from "components";
  import styles from "styleguide/ConfirmationListBar.json";

  $: shown = $selectedItems.length > 0 && $user.role !== "client";

  function send(sent_via) {
    let count = $selectedItems.length;

    ConfirmationMailingsApi.createBatch({
      params: {
        sent_via,
        confirmation_item_ids: $selectedItems,
      },
      success: (responce) => {
        const sendItems = responce.confirmation_mailings_created;
        toasts.send({
          title: `${sendItems} ${$t("confirmation_bottom_bar.success_send")}`,
          type: "success",
        });
        resetSelected();
        getConfirmation();
      },
      error: () => {
        toasts.send({
          title: `${count} ${$t("confirmation_bottom_bar.error_send")}`,
          type: "error",
        });
      },
    });
  }

  function sendViaPostal() {
    send("postal");
  }

  function sendViaEmail() {
    send("email");
  }

  function undoSending() {
    const isConfirmed = confirm($t("questions.sure"));

    if (isConfirmed) {
      ConfirmationItemsApi.batchUndoSending({
        params: {
          ids: $selectedItems,
          confirmation_id: $confirmationId,
        },
        success: () => {
          getConfirmation();
          resetSelected();
        },
      });
    }
  }

  function onDeleteSelection() {
    const isConfirmed = confirm($t("questions.sure"));

    if (isConfirmed) {
      ConfirmationItemsApi.deleteBatch({
        params: {
          ids: $selectedItems,
          confirmation_id: $confirmationId,
        },
        success: () => {
          getConfirmation();
          resetSelected();
        },
      });
    }
  }
</script>

<Boundary>
  {#if shown}
    <div data-component="ConfirmationListBar" class={styles.wrapper}>
      <span class={styles.count}
        >{$selectedItems.length} {$t("confirmation_bottom_bar.selected")}</span
      >

      <div class={styles.actions}>
        <div class={styles["actions-left"]}>
          <div class={styles.action}>
            <Button style="error-text" click={resetSelected}
              >{$t("confirmation_bottom_bar.cancel_selection")}</Button
            >
          </div>

          {#if $can("batch_create", "confirmation_mailing")}
            <div class={styles.action}>
              <Button style="primary" click={sendViaPostal}
                >{$t("confirmation_bottom_bar.send_via_postal")}</Button
              >
            </div>

            <div class={styles.action}>
              <Button style="primary" click={sendViaEmail}
                >{$t("confirmation_bottom_bar.send_via_email")}</Button
              >
            </div>

            <div class={styles.action}>
              <Button style="primary" click={undoSending}
                >{$t("confirmation_bottom_bar.undo_sending")}</Button
              >
            </div>
          {/if}
        </div>

        {#if $can("batch_destroy", "confirmation_item")}
          <Button style="error-text" click={onDeleteSelection}
            >{$t("confirmation_bottom_bar.delete_selection")}</Button
          >
        {/if}
      </div>
    </div>
  {/if}
</Boundary>

<style lang="scss">
  .wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: #fff;
    border-top: 1px solid var(--primary-050);
    z-index: 10;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .count {
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }

  .action {
    margin-right: 8px;
  }

  .actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .actions-left {
    display: flex;
    align-items: center;
  }
</style>
